<template>
  <div class="ClockHistoryViewContainer">
    <InfiniteLoadingList :query-id="4">
      <template v-slot="data">
        <nut-row class="bothCenter vmg16">
          <nut-col :span="4" class="leftCenter">
            <img style="width: 80%;" src="https://cdn.waityou.online/1980a6ee-592f-ba83-4e65-591a89fa33b3.png"/>
          </nut-col>
          <nut-col :span="20" class="colFlex">
            <span class="smallFont" style="color:#101010;">{{ data.data.createdTime }}</span>
            <span class="miniFont" style="color: #999999">已打卡</span>
          </nut-col>
        </nut-row>
        <div class="divider"/>
      </template>
    </InfiniteLoadingList>
  </div>
</template>

<script>
import InfiniteLoadingList from "@/components/InfiniteLoadingList";
export default {
  name: "ClockHistoryView",
  components: {InfiniteLoadingList}
}
</script>

<style lang="scss" scoped>
.ClockHistoryViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
}
</style>