<template>
  <div class="QrCodeContainer bothCenter">
    <div id="qrCode" ref="qrCodeDiv"></div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2';

export default {
  name: "QrCode",
  props: {
    text: {
      type: String,
      default: 'hello'
    },
    width: {
      type: Number,
      default: 160,
    }
  },
  // watch: {
  //   text() {
  //     this.$nextTick(function () {
  //       this.bindQRCode();
  //     })
  //   }
  // },
  methods: {
    bindQRCode: function () {
      console.log('qr text', this.text);
      new QRCode(this.$refs.qrCodeDiv, {
        text: this.text,
        width: this.width,
        height: this.width,
        // colorDark: "#333333", //二维码颜色
        // colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.M//容错率，L/M/H
      })
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.bindQRCode();
    })
  }
}
</script>

<style lang="scss" scoped>
.QrCodeContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
}
</style>