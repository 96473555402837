<template>
  <div class="activityContainer">
    <nut-row class="activityRow leftCenter">
      <span class="subTitle emSubTitle"><span class="title emTitle">{{ data.tag }}</span>&nbsp;{{ data.title }}</span>
    </nut-row>
    <nut-row class="activityRow">
      <span class="activityInfo">{{ data.description }}</span>
    </nut-row>
    <nut-row class="activityRow">
      <span class="postTime">{{ data.publishTime }}</span>
    </nut-row>
  </div>
</template>

<script>
export default {
  name: "IndexActivity",
  props: {
    data: {
      type: Object,
      default: ()=>{
        return {

        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.activityContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;

  .activityRow {
    display: flex;
    padding: 2% 2% 0 2%;
    width: 96%;

    .activityInfo {
      font-size: $miniFontSize;
      color: #666666;
    }

    .postTime {
      font-size: $miniFontSize;
      color: rgba(153, 153, 153, 1);
      line-height: 25px;
    }
  }

  .title {
    font-size: $smallerFontSize;
  }

  .emTitle {
    color: white;
    background-color: #eec32e;
    padding: 1px 6px;
    border-radius: 2px;
    font-size: $miniFontSize;

  }

  .subTitle {
    font-size: $baseFontSize;
  }

  .emSubTitle {
    color: black;
  }
}
</style>