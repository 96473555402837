<template>
  <div class="PoliceEventPublishViewContainer">
    <div class="publishForm">
      <CustomOssUploader @onUploadedFiles="onUploadedFiles">
        <div class="uploader bothCenter colFlex" v-if="eventImage == null">
          <img src="https://cdn.waityou.online/c340b074-7dff-00b2-b86c-5dd961413649.png"/>
          <span>上传照片</span>
        </div>
        <img v-else :src="eventImage" class="uploadedImage"/>
      </CustomOssUploader>
      <nut-row class="title leftCenter">
        <div class="dot"/>
        <span>警情类别</span>
      </nut-row>
      <nut-row>
        <nut-radiogroup v-model="eventType" direction="horizontal">
          <nut-row class="bothCenter" style="width: 90vw">
            <nut-col :span="12" class="leftCenter">
              <nut-radio label="1">非警务类警情</nut-radio>
            </nut-col>
            <nut-col :span="12" class="leftCenter">
              <nut-radio label="4">警务类警情</nut-radio>
            </nut-col>
          </nut-row>
          <nut-row class="bothCenter" style="width: 90vw;margin-top: 12px">
            <nut-col :span="12" class="leftCenter">
              <nut-radio label="2">家暴警情</nut-radio>
            </nut-col>
            <nut-col :span="12" class="leftCenter">
              <nut-radio label="3">反诈警情</nut-radio>
            </nut-col>
          </nut-row>
        </nut-radiogroup>
      </nut-row>
      <nut-row class="title leftCenter">
        <div class="dot"/>
        <span>警情内容</span>
      </nut-row>
      <nut-row>
        <nut-textarea
            style="padding:10px;border-radius: 5px"
            rows="5"
            v-model="eventDetail"
            placeholder="请输入详细警情内容，便于现场人员提前熟悉">

        </nut-textarea>
      </nut-row>
      <nut-row class="bothCenter">
        <nut-button class="submitBtn" @click="submitEvent">提交</nut-button>
      </nut-row>
    </div>
  </div>
</template>

<script>
import CustomOssUploader from "@/components/CustomOssUploader"
import {Toast} from "@nutui/nutui";
import api from "@/api";

export default {
  name: "PoliceEventPublishView",
  components: {CustomOssUploader},
  props: {
    mode: {
      type: Number,
      default: 0
    },
    chatId: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      eventType: null,
      eventImage: null,
      eventDetail: null,
    }
  },
  methods: {
    onUploadedFiles(files) {
      this.eventImage = files[0]
    },
    submitEvent() {
      if (this.eventType == null) {
        Toast.warn('请选择事件类型');
        return
      }
      if (!this.eventDetail || this.eventDetail.length < 1) {
        Toast.warn('请输入详细警情内容');
        return
      }
      // if (this.eventImage == null) {
      //   Toast.warn('请提交照片');
      //   return
      // }
      api.postEvent({
        'type': this.eventType,
        'detail': this.eventDetail,
        'image': this.eventImage,
        'chatId': this.chatId,
      }).then(() => {
        Toast.success('提交成功!');
        if(this.mode <= 0){
          this.$router.back()
        }else{
          this.$emit('close')
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.PoliceEventPublishViewContainer {
  background-image: url("https://cdn.waityou.online/83d08c72-7f30-3ae2-6340-557447c5d492.png");
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 100vh;
  background-color: #F0F1F4;
  padding: 100px 5vw 0 5vw;

  .submitBtn {
    margin-top: 30px;
    border: none;
    border-radius: 18px;
    background-color: #1677FE;
    color: white;
    width: 100%;
    font-size: $baseFontSize;
  }

  .publishForm {
    width: 100%;


    .title {
      font-size: $baseFontSize;
      color: #333333;
      margin: 10px 0;

      span {
        padding: 0 10px;
      }

      .dot {
        width: 4px;
        height: 14px;
        line-height: 20px;
        border-radius: 2px;
        background-color: rgba(22, 119, 254, 1);
      }
    }

    .uploadedImage {
      width: 90vw;
      background-color: white;
      height: 180px;
      border: #999999 1px dashed;
      border-radius: 5px;
      object-fit: cover;
    }

    .uploader {
      width: 90vw;
      background-color: white;
      height: 180px;
      border: #999999 1px dashed;
      border-radius: 5px;

      img {
        width: 26px;
        height: 22px;
      }

      span {
        padding: 10px 0;
        font-size: $miniFontSize;
        color: #999999;
      }
    }
  }
}
</style>