<template>
  <div class="OtherReportFormViewContainer">
    <div class="publishForm" @click="show=true">
      <span class="pd12"><span class="red">*</span>类型: &nbsp;{{subEventTypeName}}</span>
    </div>
    <div class="publishForm  colFlex">
      <div class="title leftCenter">
        <span class="red">*</span>
        <span>图片1</span>
      </div>
      <div class="divider"></div>
      <div class="uploadForm bothCenter colFlex">
        <CustomOssUploader :limit="1" @onUploadedFiles="onFirstImageShot">
          <div class="colFlex bothCenter" v-if="firstImage">
            <img :src="firstImage" style="height: 128px"/>
          </div>
          <div class="colFlex bothCenter" v-else>
            <img :src="defaults.uploadIcon[reportType]" style="height: 100px"/>
            <span>上传照片</span>
          </div>
        </CustomOssUploader>
      </div>
    </div>
    <div class="publishForm  colFlex">
      <div class="title leftCenter">
        <span class="red">&nbsp;</span>
        <span>图片2</span>
      </div>
      <div class="divider"></div>
      <div class="uploadForm bothCenter colFlex">
        <CustomOssUploader :limit="1" @onUploadedFiles="onSecondImageShot">
          <div class="colFlex bothCenter" v-if="secondImage">
            <img :src="secondImage" style="height: 128px"/>
          </div>
          <div class="colFlex bothCenter" v-else>
            <img :src="defaults.uploadIcon[reportType]" style="height: 100px"/>
            <span>上传照片</span>
          </div>
        </CustomOssUploader>
      </div>
    </div>
    <div class="publishForm  colFlex">
      <div class="title leftCenter">
        <span class="red">&nbsp;</span>
        <span>图片3</span>
      </div>
      <div class="divider"></div>
      <div class="uploadForm bothCenter colFlex">
        <CustomOssUploader :limit="1" @onUploadedFiles="onThirdImageShot">
          <div class="colFlex bothCenter" v-if="thirdImage">
            <img :src="thirdImage" style="height: 128px"/>
          </div>
          <div class="colFlex bothCenter" v-else>
            <img :src="defaults.uploadIcon[reportType]" style="height: 100px"/>
            <span>上传照片</span>
          </div>
        </CustomOssUploader>
      </div>
    </div>
    <div class="publishForm  colFlex">
      <div class="title leftCenter">
        <span class="red">*</span>
        <span>事件描述</span>
      </div>
      <div class="divider"></div>
      <nut-textarea placeholder="请输入事件描述"
                    style="padding: 10px"
                    v-model="content"
                    rows="5"></nut-textarea>
    </div>
  </div>

  <div class="bottomBtn bothCenter">
    <nut-button class="submitBtn" @click="report">上报</nut-button>
  </div>
  <nut-picker
      v-model:visible="show"
      :columns="subEventTypeNames"
      title="事件类型选择"
      @confirm="confirm"
  >
  </nut-picker>
</template>

<script>
import api from "@/api";
import {Toast} from "@nutui/nutui";
import CustomOssUploader from "@/components/CustomOssUploader"

export default {
  name: "OtherReportFormView",
  components: {CustomOssUploader},

  data() {
    return {
      show:false,
      reportType: 5,
      defaults: {
        uploadIcon: {
          1: 'https://cdn.waityou.online/abca14ba-d480-5f38-92a1-5a3c191ca3fa.png',
          3: 'https://cdn.waityou.online/dca42719-8ff3-4bd8-0866-38dbbfd413e8.png',
          4: 'https://cdn.waityou.online/dca42719-8ff3-4bd8-0866-38dbbfd413e8.png',
          5: 'https://cdn.waityou.online/dca42719-8ff3-4bd8-0866-38dbbfd413e8.png',
          6: 'https://cdn.waityou.online/dca42719-8ff3-4bd8-0866-38dbbfd413e8.png',
        }
      },
      content: null,
      subEventTypeName: '请选择',
      firstImage: null,
      firstImageTime: null,
      secondImage: null,
      secondImageTime: null,
      thirdImage: null,
      thirdImageTime: null,
      subEventTypeNames:[
        {text:'110接处警',value:'110接处警',},
        {text:'上门反诈',value:'上门反诈',},
        {text:'看管送押',value:'看管送押',},
        {text:'护校安园',value:'护校安园',},
        {text:'大堂坐岗',value:'大堂坐岗',},
        {text:'出店经营',value:'出店经营',},
        {text:'飞线充电',value:'飞线充电',},
        {text:'游商摊贩',value:'游商摊贩',},
        {text:'非机动车违规停放整理',value:'非机动车违规停放整理',},
        {text:'遛狗劝导',value:'遛狗劝导',},
        {text:'餐饮煤气瓶检查',value:'餐饮煤气瓶检查',},
        {text:'机动车违停',value:'机动车违停',},
        {text:'重点区域整治',value:'重点区域整治',},
        {text:'隐患排查',value:'隐患排查',},
        {text:'沿街商铺住人',value:'沿街商铺住人',},
        {text:'灭火器检查',value:'灭火器检查',},
        {text:'消防栓',value:'消防栓',},
        {text:'营业执照',value:'营业执照',},
        {text:'小餐饮员工健康证',value:'小餐饮员工健康证',},
        {text:'协助交通事故处置',value:'协助交通事故处置',},
        {text:'违法建筑巡查',value:'违法建筑巡查',},
        {text:'协助拆违',value:'协助拆违',},
        {text:'平安宣传',value:'平安宣传',},
        {text:'其他群众救助',value:'其他群众救助',},

      ],
    }
  },
  methods: {
    confirm(e) {
      this.subEventTypeName = e.selectedOptions[0].text;
    },
    onFirstImageShot(files) {
      this.firstImage = files[0]
      this.firstImageTime = new Date().getTime();
    },
    onSecondImageShot(files) {
      this.secondImage = files[0]
      this.secondImageTime = new Date().getTime();
    },
    onThirdImageShot(files) {
      this.thirdImage = files[0]
      this.thirdImageTime = new Date().getTime();
    },
    report() {
      if (!this.firstImage) {
        Toast.warn("请至少上传1张图片")
        return
      }
      if (!this.content || this.content.length < 1) {
        Toast.warn("请输入事件描述")
        return
      }
      if(this.subEventTypeName == '请选择'){
        Toast.warn("请选择事件类型")
        return
      }
      api.report({
        eventTypeId: this.reportType,
        subEventTypeName: this.subEventTypeName,
        content: this.content,
        firstImage: this.firstImage,
        firstImageTime: this.firstImageTime,
        secondImageTime: this.secondImageTime,
        secondImage: this.secondImage,
        thirdImage: this.thirdImage,
        thirdImageTime: this.thirdImageTime,
      }).then(() => {
        Toast.success('上报成功');
        this.$router.back()
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.bottomBtn {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px 0;
  background-color: white;

  .submitBtn {
    border: none;
    border-radius: 18px;
    background-color: #1677FE;
    color: white;
    width: 90%;
    font-size: $baseFontSize;
  }
}

.OtherReportFormViewContainer {
  background-color: #F0F1F4;
  min-height: 100vh;
  padding: 20px 20px 100px 20px;
  display: flex;
  flex-direction: column;

  .publishForm {
    width: 100%;
    display: flex;
    background-color: white;
    border: 1px dashed rgba(187, 187, 187, 1);
    border-radius: 4px;
    margin: 5px 0;

    .divider {
      width: 100%;
      height: 1px;
      background-color: #E8E8E8;
    }

    .title {
      display: flex;
      padding: 10px;

      .red {
        color: red;
      }

      span {
        font-size: $baseFontSize;
      }
    }

    .uploadForm {
      display: flex;
      width: 100%;
      height: 128px;

      img {
        height: 128px;
      }

      span {
        padding: 10px 0;
        font-size: $miniFontSize;
        color: #999999;
      }
    }
  }

}
</style>