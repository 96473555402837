<template>
  <div class="ReportedListViewContainer">
    <nut-tabs v-model="tab"
              @change="onTabChange"
              type="smile" title-scroll title-gutter="10" background="transparent">
      <nut-tabpane title="全部" style="background:transparent;width:0;height: 0;padding: 0;margin:0"/>
<!--      <nut-tabpane title="单车清理" style="background:transparent;width:0;height: 0;padding: 0;margin:0"/>-->
      <nut-tabpane title="护岗到位/结束" style="background:transparent;width:0;height: 0;padding: 0;margin:0"/>
      <nut-tabpane title="无证劝离" style="background:transparent;width:0;height: 0;padding: 0;margin:0"/>
      <nut-tabpane title="出店经营" style="background:transparent;width:0;height: 0;padding: 0;margin:0"/>
      <nut-tabpane title="其他" style="background:transparent;width:0;height: 0;padding: 0;margin:0"/>
      <nut-tabpane title="路面巡查" style="background:transparent;width:0;height: 0;padding: 0;margin:0"/>
    </nut-tabs>

    <div class="eventContainer">
      <InfiniteLoadingList :query-id="8" :init-query="initQuery">
        <template v-slot="data">
          <ReportedEventItem :data="data.data"/>
        </template>
      </InfiniteLoadingList>
    </div>
  </div>
</template>

<script>
import ReportedEventItem from "@/components/query/ReportedEventItem";
import InfiniteLoadingList from "@/components/InfiniteLoadingList"

export default {
  name: "ReportedListView",
  components: {ReportedEventItem, InfiniteLoadingList},
  data() {
    return {
      tab: this.$route.query.tab ? this.$route.query.tab : 0,
      hasMore: false,
      initQuery: {},
    }
  },
  methods: {
    onTabChange() {
      this.initQuery = {
        typeId: this.tab > 0 ? this.tab : null,
      }
    },
    loadMore(done) {
      done.done();
    }
  },
}
</script>

<style lang="scss" scoped>
.ReportedListViewContainer {
  background-color: #F2F7FF;
  min-height: 100vh;

  .eventContainer {
    padding: 8px 16px;

    .eventList {
      background-color: white;
      border-radius: 5px;

    }
  }
}
</style>