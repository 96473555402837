<template>
  <div class="PeopleCheckHistoryViewContainer">
    <InfiniteLoadingList :query-id="10">
      <template v-slot="data">
        <PeopleCheckHistoryItem class="historyItem" :data="data.data"/>
      </template>
    </InfiniteLoadingList>
  </div>
</template>

<script>
import InfiniteLoadingList from "@/components/InfiniteLoadingList"
import PeopleCheckHistoryItem from "@/components/check/PeopleCheckHistoryItem";
export default {
  name: "PeopleCheckHistoryView",
  components: {PeopleCheckHistoryItem,InfiniteLoadingList},
  data() {
    return {
      list: []
    }
  },
  methods: {
    init() {
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.PeopleCheckHistoryViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F5F5F5;
  height: 90vh;
  padding:16px;

  .historyItem{
    margin-bottom: 16px;
  }
}
</style>