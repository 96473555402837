<template>
  <div class="CardMessageContainer">
    <LeftMessage  v-if="myId != data.creatorId" :data="data">
      <div class="policeEventCard">
        <nut-row class="bothCenter">
          <nut-col :span="12" class="leftCenter">
            <img class="cardAvatar" src="https://cdn.waityou.online/483f0a5f-e172-e402-347b-5c2e4c2321fc.png"/>
            <span class="eventType">{{ cardData.title }}</span>
          </nut-col>
          <nut-col :span="12" class="rightCenter">
            <span class="eventNo">{{ cardData.subTitle }}</span>
          </nut-col>
        </nut-row>
        <nut-row class="colFlex">
          <span style="padding: 5px 0">{{ cardData.first }}</span>
        </nut-row>
        <nut-row>
          <img style="width: 100%;height: 150px;object-fit: cover" :src="cardData.image"/>
        </nut-row>
        <nut-row class="colFlex">
          <span style="padding-top: 5px">{{ cardData.remark }}</span>
        </nut-row>
      </div>
    </LeftMessage>
    <RightMessage v-if="myId == data.creatorId" :data="data">
      <div class="policeEventCard">
        <nut-row class="bothCenter">
          <nut-col :span="12" class="leftCenter">
            <img class="cardAvatar" src="https://cdn.waityou.online/483f0a5f-e172-e402-347b-5c2e4c2321fc.png"/>
            <span class="eventType">{{ cardData.title }}</span>
          </nut-col>
          <nut-col :span="12" class="rightCenter">
            <span class="eventNo">{{ cardData.subTitle }}</span>
          </nut-col>
        </nut-row>
        <nut-row class="colFlex">
          <span style="padding: 5px 0">{{ cardData.first }}</span>
        </nut-row>
        <nut-row>
          <img style="width: 100%" :src="cardData.image"/>
        </nut-row>
        <nut-row class="colFlex">
          <span style="padding-top: 5px">{{ cardData.remark }}</span>
        </nut-row>
      </div>
    </RightMessage>
  </div>
</template>

<script>
import LeftMessage from "@/components/im/LeftMessage";
import RightMessage from "@/components/im/RightMessage";
export default {
  name: "CardMessage",
  components: {RightMessage, LeftMessage},
  props: {
    data: {
      type: Object,
      default: ()=>{
        return {

        }
      }
    },
    myId:{
      type:Number,
      default:-1,
    }
  },
  data() {
    return {
      cardData: {}
    }
  },
  mounted() {
    this.cardData = JSON.parse(this.data.cardData)
    // console.log('cardData',this.cardData)
  }
}
</script>

<style lang="scss" scoped>
.CardMessageContainer {
  display: flex;
  flex-direction: column;
  .policeEventCard{
    background-color: white;
    padding: 12px;
    border-radius: 6px;
    width: 70%;
    margin: 10px 0;
    color:#101010;
    font-size: $miniFontSize;

    .eventType{
      padding-left: 5px;
    }
    .eventNo{
      font-size: $miniFontSize;
      color:#999999;
    }
    .cardAvatar{
      width: 30px;
      height: 30px;
    }
  }
}
</style>