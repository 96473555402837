<template>
  <div class="CheckCalendarViewContainer">
    <nut-row className="tab bothCenter">
      <nut-col :span="6" class="bothCenter">
        <div :class="tab == 0?'tabItem active':'tabItem'" @click="tab=0">
          <span>日</span>
        </div>
      </nut-col>
      <nut-col :span="6" class="bothCenter">
        <div :class="tab == 1?'tabItem active':'tabItem'" @click="tab=1">
          <span>周</span>
        </div>
      </nut-col>
      <nut-col :span="6" class="bothCenter">
        <div :class="tab == 2?'tabItem active':'tabItem'" @click="tab=2">
          <span>月</span>
        </div>
      </nut-col>
      <nut-col :span="6" class="bothCenter">
        <div :class="tab == 3?'tabItem active':'tabItem'" @click="tab=3">
          <span>年</span>
        </div>
      </nut-col>
    </nut-row>
    <IceCalendar @changeDay="onChangeDay" v-if="tab == 0"/>
    <IceWeekCalendar @dateRangeChange="onDateRangeChange" v-if="tab==1"/>
    <IceMonthCalendar @changeMonth="onMonthChange" v-if="tab==2"/>
    <IceYearCalendar @changeYear="onYearChange" v-if="tab==3"/>
    <div class="pd16 wbg">
      <nut-row class="bothCenter miniFont">
        <nut-col :span="12" class="leftCenter">
          <span>检查商铺数</span>
        </nut-col>
        <nut-col :span="12" class="rightCenter">
          <span style="color: #999999">{{ selectedDay == today ? '今日数据' : selectedDay }}</span>
        </nut-col>
      </nut-row>
      <div class="vmg8">
        <span style="font-size: 32px;margin-right: 5px">{{ statistic.total }}</span>
        <span class="smallFont">商铺</span>
      </div>
      <nut-row class="leftCenter miniFont">
        <nut-col :span="8" class="colFlex colLeftCenter">
          <span style="color: #666666">时间(小时)</span>
          <span class="biggerFont" style="color: #333333">{{ statistic.hours }}</span>

        </nut-col>
        <nut-col :span="8" class="colFlex colLeftCenter">
          <span style="color: #666666">距离(km)</span>
          <span class="biggerFont" style="color: #333333">{{ statistic.distance }}</span>
        </nut-col>
        <nut-col :span="8" class="colFlex colLeftCenter" v-if="tab>0">
          <span style="color: #666666">累计(天)</span>
          <span class="biggerFont" style="color: #333333">{{ statistic.sumDays }}</span>
        </nut-col>
        <nut-col :span="8" class="colFlex colLeftCenter" v-if="tab>0">
          <span style="color: #666666">连续(天)</span>
          <span class="biggerFont" style="color: #333333">{{ statistic.continuousDays }}</span>
        </nut-col>
      </nut-row>
    </div>

    <div style="height: 250px;" class="pd16">
      <BarChart :data="chartData" v-if="chartData?.y?.length > 0"/>
    </div>
  </div>
</template>

<script>
import IceCalendar from "@/components/IceCalendar";
import api from "@/api";
import BarChart from "@/components/BarChart";
import IceYearCalendar from "@/components/IceYearCalendar";
import IceMonthCalendar from "@/components/IceMonthCalendar";
import IceWeekCalendar from "@/components/IceWeekCalendar";

export default {
  name: "CheckCalendarView",
  components: {IceWeekCalendar, IceMonthCalendar, IceYearCalendar, BarChart, IceCalendar},
  data() {
    return {
      tab: 1,
      today: null,
      selectedDay: null,
      statistic: {},
      chartData: {},
    }
  },
  methods: {
    onDateRangeChange(query) {
      api.queryShopStatisticDataByDateRange(query)
          .then(ret => {
            this.statistic = ret;
            this.chartData = {
              x: ret.shopTypes.map(t => t.sortName),
              y: ret.counts
            }
          })
    },
    onMonthChange(month) {
      api.queryShopStatisticDataByMonth({
        month: month
      }).then(ret => {
        this.statistic = ret;
        this.chartData = {
          x: ret.shopTypes.map(t => t.sortName),
          y: ret.counts
        }
      })
    },
    onYearChange(year) {
      api.queryShopStatisticDataByYear({
        year: year
      }).then(ret => {
        this.statistic = ret;
        this.chartData = {
          x: ret.shopTypes.map(t => t.sortName),
          y: ret.counts
        }
      })
    },
    onChangeDay(today, selectedDay) {
      this.today = today;
      this.selectedDay = selectedDay;

      api.queryShopStatisticDataByDay({
        day: selectedDay
      }).then(ret => {
        this.statistic = ret;
        this.chartData = {
          x: ret.shopTypes.map(t => t.sortName),
          y: ret.counts
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.CheckCalendarViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;


  .blue {
    color: #1677FE;
  }

  .tab {
    background-color: #EEEEEF;
    border-radius: 4px;
    padding: 2px;
    display: flex;
    margin: 0 16px;

    .active {
      background-color: white;
    }

    .tabItem {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 32px;
      border-radius: 4px;


    }
  }
}
</style>