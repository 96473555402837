<template>
  <div class="ChatMessageSearchViewContainer">
    <nut-searchbar v-model="searchValue" placeholder="请输入关键词" style="position: fixed;top: 0;width: 100vw">
      <template v-slot:leftout>
        <img src="https://cdn.waityou.online/585d4dd9-571b-338e-92bb-5b7fc77ea1fd.svg"
             style="width: 16px;margin-right: 8px" @click="$router.go(-1)"/>
      </template>
      <template v-slot:leftin>
        <nut-icon size="14" name="search2"></nut-icon>
      </template>
      <template v-slot:rightout>
        <span style="color: #1b77fb;">搜索</span>
      </template>
    </nut-searchbar>
    <div class="pd16 box " style="margin-top: 50px">
      <InfiniteLoadingList :query-id="11" :init-query="{searchText:searchValue,chatId:chatId}" method="POST">
        <template v-slot="data">
          <ImageMessage :id="'message-'+data.data.id" v-if="data.data.messageType == 'IMAGE'" :data="data.data"/>
          <VideoMessage :id="'message-'+data.data.id" v-if="data.data.messageType == 'VIDEO'" :data="data.data"/>
          <TextMessage :id="'message-'+data.data.id" v-if="data.data.messageType == 'TEXT'" :data="data.data"/>
          <CardMessage :id="'message-'+data.data.id"
                       @click="gotoPoliceEvent(data.data.cardRefId)"
                       v-if="data.data.messageType == 'CARD'" :data="data.data"/>
          <VoiceMessage :id="'message-'+data.data.id" v-if="data.data.messageType == 'VOICE'"
                        :playingMessageId="playingVoiceMessageId"
                        @voicePlay="onVoicePlay"
                        :data="data.data"/>
        </template>
      </InfiniteLoadingList>
    </div>
  </div>
</template>

<script>
import InfiniteLoadingList from "@/components/InfiniteLoadingList.vue";
import TextMessage from "@/components/im/TextMessage.vue";
import CardMessage from "@/components/im/CardMessage.vue";
import ImageMessage from "@/components/im/ImageMessage.vue";
import VideoMessage from "@/components/im/VideoMessage.vue";
import VoiceMessage from "@/components/im/VoiceMessage.vue";

export default {
  name: "ChatMessageSearchView",
  components: {
    VoiceMessage,
    VideoMessage,
    ImageMessage,
    CardMessage,
    TextMessage,
    InfiniteLoadingList},
  data() {
    return {
      searchValue:null,
      chatId:this.$route.params.chatId,
      playingVoiceMessageId:null,
    }
  },
  methods: {
    onVoicePlay(messageId) {
      //关闭其他正在播放的音频
      this.playingVoiceMessageId = messageId;
    },
    gotoPoliceEvent(eid) {
      this.$router.push('/police/event/detail/' + eid);
    },
    init() {
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.ChatMessageSearchViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F0F1F4;
  min-height: 100vh;
}
</style>