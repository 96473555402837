<template>
  <div class="eventContainer">
    <img class="headBg" src="https://cdn.waityou.online/9b434a75-9e69-bd3d-9e19-590ff4e04454.png"/>
    <div class="headRect1"></div>
    <div class="headRect2"></div>
    <div class="detailContainer">
      <nut-row>
        <nut-col :span="16" style="padding: 15px;">
          <nut-row class="leftCenter">
            <span class="miniLogo">主体/商铺名称</span>
          </nut-row>
          <nut-row class="leftCenter">
            <span class="eventTitle">{{ shop.shopName }}</span>
          </nut-row>
          <nut-row class="tags">
            <nut-tag class="danger" v-if="problemCnt>0">问题项{{ problemCnt }}</nut-tag>
            <nut-tag class="warning" v-if="hiddenDangerCnt>0">隐患</nut-tag>
            <nut-tag class="success">{{ shop.communityName }}</nut-tag>
          </nut-row>
        </nut-col>
        <nut-col :span="8"
                 v-if="!report.reportReceived"
                 style="display:flex;padding: 15px;justify-content: flex-end;align-items: center">
          <QrCode :text="url" width="80"/>
        </nut-col>
      </nut-row>

      <template v-for="(m,mi) in matters" :key="mi">
        <ShopDivider/>
        <ShopCheckItem :data="m"/>
      </template>
      <nut-row className="colFlex opd12">
        <nut-row class="leftCenter mb8">
          <div class="dot"></div>
          <span class="baseFont boldFont">&nbsp;检查人员签名</span>
        </nut-row>
        <nut-row class="bothCenter">
          <nut-col :span="12" class="leftCenter">
            <img :src="report.checkerSign" style="width: 100px"/>
          </nut-col>
          <nut-col :span="12" class="rightCenter">
            <span class="miniFont">{{ report.createdTime }}</span>
          </nut-col>
        </nut-row>
      </nut-row>
      <ShopDivider/>
      <nut-row className="colFlex opd12">
        <nut-row class="leftCenter mb8">
          <div class="dot"></div>
          <span class="baseFont boldFont">&nbsp;主体/商铺人员签名</span>
        </nut-row>
        <nut-row class="bothCenter" v-if="report.ownerSign">
          <nut-col :span="12" class="leftCenter">
            <img :src="report.ownerSign" style="width: 100px"/>
          </nut-col>
          <nut-col :span="12" class="rightCenter">
            <span class="miniFont">{{ report.ownerSignTimeStr }}</span>
          </nut-col>
        </nut-row>
        <div v-else class="leftCenter vmg16">
          <span style="font-size: 25px;color: #999999">
          还未签收
        </span>
        </div>
      </nut-row>
      <div class="bothCenter" style="margin-top:16px;width: 100%;min-height: 8px;
background-image: url('https://cdn.waityou.online/e48edd9e-ce81-2c4e-0e4e-7d99becf34b0.png');
background-size: 6%;">
      </div>
    </div>
    <div class="bottomBtn bothCenter">
      <nut-button class="receiveBtn" v-if="!report.reportReceived" @click="receiveVisible=true">签收</nut-button>
      <nut-button class="receivedBtn" v-else>已签收</nut-button>
    </div>
  </div>
  <nut-dialog
      title="签名"
      @close="receiveVisible=false"
      v-model:visible="receiveVisible"
      @ok="sign2img"
  >
    <CheckerSignView
        :mode="1"
        @full="onFullSign"
        v-if="receiveVisible"
        ref="signPad"
    />
  </nut-dialog>

  <nut-popup v-model:visible="fullSignVisible" style="width: 100vw;height: 100vh;background: white">
    <FullScreenSign v-if="fullSignVisible" @ok="afterFullSign" @cancel="fullSignVisible=false"/>
  </nut-popup>
</template>

<script>
import CheckerSignView from "@/components/sign/CheckerSignView";
import ShopDivider from "@/components/shop/ShopDivider";
import ShopCheckItem from "@/components/shop/ShopCheckItem";
import QrCode from "@/components/QrCode";

import api from "@/api"
import {Toast} from "@nutui/nutui";
import FullScreenSign from "@/components/sign/FullScreenSign.vue";

export default {
  name: "ShopEventDetailView",
  components: {FullScreenSign, ShopCheckItem, ShopDivider, QrCode, CheckerSignView},
  data() {
    return {
      fullSignVisible: false,
      receiveVisible: false,
      hiddenDangerCnt: 0,
      problemCnt: 0,
      report: {},
      shop: {},
      myInfo: {},
      matters: [],
      reportId: this.$route.params.reportId,
      url: null,
    }
  },
  methods: {
    onFullSign() {
      this.receiveVisible = false;
      this.fullSignVisible = true;
    },
    sign2img() {
      Toast.loading('请稍等')
      api.submitReceiveReport({
        reportId: this.reportId,
        signImage: this.$refs.signPad.saveSignature(),
        wxToken: localStorage.getItem('access_token')
      }).then(() => {
        this.init()
      }).finally(() => {
        Toast.hide()
      })
    },
    afterFullSign(url) {
      console.log('afterFullSign', url)
      Toast.loading('请稍等')
      api.submitReceiveReport({
        reportId: this.reportId,
        signImage: url,
        wxToken: localStorage.getItem('access_token')
      }).then(() => {
        this.init()
      }).finally(() => {
        Toast.hide()
        this.fullSignVisible = false;
      })
    },
    init() {
      this.url = window.location.protocol + '//' + window.location.host + '/#/noLogin?redirect=/open/receive/' + this.reportId;
      this.problemCnt = 0;
      this.hiddenDangerCnt = 0;
      api.getOpenShopCheckReport(this.reportId)
          .then(ret => {
            this.report = ret;
            this.shop = ret.shop;
            this.matters = ret.matters;
            for (let m of ret.matters) {
              this.hiddenDangerCnt += m.hiddenDangers.length;
              for (let p of m.items) {
                this.problemCnt += p.noProblem ? 0 : 1;
              }
            }
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.bottomBtn {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 8px 0;

  .receiveBtn {
    width: 90%;
    height: 44px;
    border-radius: 22px;
    background-color: rgba(22, 119, 254, 1);
    color: rgba(255, 255, 255, 1);
    border: none;
  }

  .receivedBtn {
    width: 90%;
    height: 44px;
    border-radius: 22px;
    background-color: rgba(246, 246, 246, 1);
    color: rgba(153, 153, 153, 1);
    border: none;
  }
}

.dot {
  width: 4px;
  height: 14px;
  line-height: 20px;
  border-radius: 2px;
  background-color: rgba(22, 119, 254, 1);
  text-align: center;
}

.paperBoarder {
  width: 16px;
  height: 8px;
  background-color: #999999;
  border-radius: 8px 8px 0 0;
  display: inline-block;
}

.eventContainer {
  position: relative;
  width: 100%;
  height: 100vh;
  font-size: $baseFontSize;
  background-color: #F4F4F4;


  .tags {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 2px;
    padding: 5px 0;

    .danger {
      background-color: rgba(245, 63, 63, 0.1);
      color: rgba(245, 63, 63, 1);
      margin-right: 10px;
    }

    .warning {
      background-color: rgba(255, 125, 1, 0.1);
      color: rgba(255, 125, 1, 1);
      margin-right: 10px;
    }

    .success {
      background-color: rgba(0, 180, 43, 0.1);
      color: rgba(0, 180, 43, 1);
      margin-right: 10px;
    }

    .info {
      background-color: rgba(22, 119, 254, 0.1);
      color: rgba(22, 119, 254, 1);
      margin-right: 10px;
    }
  }

  .detailContainer {
    position: absolute;
    left: 6%;
    top: 68px;
    width: 88%;
    min-height: 60vh;
    max-height: 85vh;
    overflow-y: scroll;
    line-height: 20px;
    background-color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    //padding-bottom: 20px;
  }

  .headRect2 {
    position: absolute;
    left: 6%;
    top: 56px;
    width: 88%;
    height: 12px;
    line-height: 20px;
    background: linear-gradient(180deg, rgba(22, 119, 254, 0.5) 1%, rgba(255, 255, 255, 0.5) 100%);
    text-align: center;
  }

  .headRect1 {
    position: absolute;
    left: 5%;
    top: 50px;
    width: 90%;
    height: 12px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(7, 98, 225, 1);
    text-align: center;
  }

  .headBg {
    position: absolute;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 135px;
  }

  .miniLogo {
    font-size: $smallerFontSize;
    color: gray;
    padding: 5px 0;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .dot {
    background-color: #1388ff;
    color: #1388ff;
    font-size: $smallerFontSize;
  }

  .eventTitle {
    font-weight: normal;
    font-size: $baseFontSize;
    padding: 5px 0;
  }

  .darkRed {
    color: darkred;
  }

  .eventImage {
    display: flex;
    padding: 10px 0;

    img {
      width: 100%;
      border-radius: 5px;
    }
  }
}
</style>