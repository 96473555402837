<template>
  <div class="ShopCheckHistoryLineContainer">
    <nut-row class="bothCenter">
      <nut-col :span="3" class="bothCenter">
        <div class="dot"/>
      </nut-col>
      <nut-col :span="21" class="leftCenter">
        <span>{{ data.createdTime }}   {{ data.weekday }}</span>
      </nut-col>
    </nut-row>
    <nut-row class="bothCenter">
      <nut-col :span="3" class="bothCenter">
        <div v-if="hasNext" class="line"/>
      </nut-col>
      <nut-col :span="21" class="checkerInfoContainer leftCenter">
        <div class="checkerInfo smallFont">
          <nut-row class="bothCenter">
            <nut-col :span="16" class="leftCenter">
              <span class="title">检查组织：</span>
              <span style="color: #333333" class="boldFont">{{ data.signUserOrgName }}</span>
            </nut-col>
            <nut-col :span="8" class="rightCenter">
              <span class="info" v-if="data.problemCnt < 1">问题项:0</span>
              <span class="danger" v-else>问题项:{{data.problemCnt}}</span>
            </nut-col>
          </nut-row>
          <nut-row class="leftCenter">
            <span class="title">检查成员：</span>
            <img :src="data.signUserAvatar"/>
            <span class="miniFont">{{ data.signUserName }}</span>
          </nut-row>
          <nut-row class="leftCenter miniFont">
            <router-link :to="'/shop/check/report/'+data.id" style="text-decoration: none;">
              <span class="link">检查详情</span>
            </router-link>
            <nut-icon class="link" name="right" size="12px"></nut-icon>
          </nut-row>
        </div>
      </nut-col>
    </nut-row>
  </div>
</template>

<script>
export default {
  name: "ShopCheckHistoryLine",
  props: {
    hasNext: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object,
      default: ()=>{
        return {

        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ShopCheckHistoryLineContainer {
  font-size: $baseFontSize;

  .line{
    height: 120px;
    width: 1px;
    background-color: #F2F2F2;
  }

  .checkerInfoContainer {
    display: flex;
    padding: 10px;

    span{
      padding: 5px 0;
    }
    .link {
      color: #1677FE;
    }

    img {
      width: 20px;
      height: 20px;
      padding-right: 5px;
    }

    .checkerInfo {
      width: 100%;
      background-color: #F8F8F8;
      margin-right: 10px;
      padding:5px 10px;
      font-size: $smallerFontSize;

      .title {
        color: #666666;
      }

      .info {
        color: #1677FE;
        font-size: $miniFontSize;
        padding: 2px;
        border-radius: 2px;
        background-color: rgba(22, 119, 254, 0.1);
      }
      .danger {
        background-color: rgba(245, 63, 63, 0.1);
        color: rgba(245, 63, 63, 1);
        font-size: $miniFontSize;
        padding: 2px;
        border-radius: 2px;
      }
    }
  }

  .dot {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    line-height: 20px;
    background-color: rgba(22, 119, 254, 1);
    text-align: center;
  }
}
</style>