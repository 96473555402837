import api from "@/api";

export default {
    uploadBlob(blobData, callback) {
        let OSS = require('ali-oss');
        api.getOssKeySecret().then(cfg => {
            let ossClient = new OSS({
                region: cfg.region,
                accessKeyId: cfg.accessKeyId,
                accessKeySecret: cfg.accessKeySecret,
                stsToken: cfg.securityToken,
                bucket: cfg.bucket,
                endpoint: cfg.endpoint,
                secure: true
            });
            let ossDomain = cfg.domain;
            let fileName = (cfg.folder ? cfg.folder + '/' : 'user-upload/') + this.guid();
            ossClient.put(fileName, blobData);
            callback(ossDomain + '/' + fileName)
        })
    },
    guid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },
}