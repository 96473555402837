<template>
  <div class="ImViewContainer">
    <nut-row class="wbg box vpd12 bothCenter">
      <nut-col :span="8" class="leftCenter">
        <img src="https://cdn.waityou.online/585d4dd9-571b-338e-92bb-5b7fc77ea1fd.svg"
        style="width: 16px;margin-left: 16px" @click="$router.go(-1)"/>
      </nut-col>
      <nut-col :span="8" class="bothCenter">
        <span>{{ chatInfo?.chatName }}</span>
      </nut-col>
      <nut-col :span="8" class="rightCenter">
        <img src="https://cdn.waityou.online/a3271d61-a322-bb90-ab7c-8f19abe49479.svg"
             style="width: 16px;margin-right: 16px" @click="gotoSearchView"/>
      </nut-col>
    </nut-row>
    <div class="messageList" ref="messageList">
      <template v-for="(m,i) in $store.state.imMessage" :key="i">
        <ImageMessage :id="'message-'+m.id" v-if="m.messageType == 'IMAGE'" :data="m" :my-id="userInfo.id"/>
        <VideoMessage :id="'message-'+m.id" v-if="m.messageType == 'VIDEO'" :data="m" :my-id="userInfo.id"/>
        <TextMessage :id="'message-'+m.id" v-if="m.messageType == 'TEXT'" :data="m" :my-id="userInfo.id"/>
        <SystemNoticeMessage :id="'message-'+m.id" v-if="m.messageType == 'NOTICE'" :data="m"/>
        <RollbackNoticeMessage :id="'message-'+m.id" v-if="m.messageType == 'ROLLBACK'" :data="m"/>
        <CardMessage :id="'message-'+m.id"
                     @click="gotoPoliceEvent(m.cardRefId)"
                     v-if="m.messageType == 'CARD'" :data="m" :my-id="userInfo.id"/>
        <VoiceMessage :id="'message-'+m.id" v-if="m.messageType == 'VOICE'"
                      :playingMessageId="playingVoiceMessageId"
                      @voicePlay="onVoicePlay"
                      :data="m" :my-id="userInfo.id"/>
      </template>
    </div>
    <EventBar @eventClick="onEventClick" :chat-id="chatId"/>
    <view class="editorContainer bothCenter">
      <nut-col :span="3" class="bothCenter">
        <svg-icon icon-class="keyboard" class-name="mini" v-if="inputMode==1" @click="inputMode=0"/>
        <svg-icon icon-class="voice" class-name="mini" v-if="inputMode==0" @click="inputMode=1"/>
      </nut-col>
      <nut-col :span="18" class="rightCenter">
        <nut-textarea v-model="editContent"
                      style="padding: 5px"
                      v-if="inputMode==0" rows="2"
                      autosize placeholder="请输入聊天内容"
                      @input="onEditChange"/>
        <!--        <input class="textInput"-->
        <!--               v-if="inputMode==0"-->
        <!--               v-model="editContent" placeholder="请输入聊天内容" @input="onEditChange"/>-->
        <nut-button v-if="inputMode==1 && !recording" class="voiceBtn"
                    @click="startRecording">点击录制语音
        </nut-button>
        <nut-button v-if="inputMode==1 && recording" class="voiceBtnRecording"
                    @click="stopRecording">点击结束录制
        </nut-button>
      </nut-col>
      <nut-col :span="3" class="bothCenter">
        <svg-icon icon-class="more" class-name="mini" v-if="editMode==0" @click="morePanelVisbile=true"/>
        <svg-icon icon-class="send" class-name="mini" v-if="editMode==1" @click="sendTextMessage"/>
      </nut-col>
    </view>
  </div>
  <div class="recordingNotice bothCenter colFlex" v-if="recording" @click="cancelRecord">
    <img src="https://cdn.waityou.online/404464ce-3ce4-88b0-667c-6d6609da73a5.png" style="width: 60px;"/>
    <span style="color: #666666" class="baseFont">正在录音，点击取消</span>
  </div>

  <nut-popup position="bottom" v-model:visible="eventPublishVisible" :style="{height: '85%'}"
             close-icon="https://cdn.waityou.online/b019982c-1249-317d-7a68-15c33de78e55.png"
             closeable>
    <PoliceEventPublishView
        :chat-id="chatId"
        :mode="1" @close="eventPublishVisible=false" v-if="eventPublishVisible"/>
  </nut-popup>
  <nut-popup position="bottom" round v-model:visible="morePanelVisbile">
    <nut-row class="bothCenter" v-if="morePanelVisbile">
      <nut-col :span="6" class="colFlex bothCenter vmg16">
        <CustomOssUploader @onUploadedFiles="sendImageMessage">
          <div class="colFlex bothCenter">
            <img src="https://cdn.waityou.online/1324fc9b-6330-c7c7-d1e9-0991a2d4ae37.svg" style="width: 30px"/>
            <span class="miniFont vmg4">照片</span>
          </div>
        </CustomOssUploader>
      </nut-col>
      <nut-col :span="6" class="colFlex bothCenter vmg16">
        <CustomOssUploader @onUploadedFiles="sendVideoMessage" accept="video/*">
          <div class="colFlex bothCenter">
            <img src="https://cdn.waityou.online/a0fddee4-6241-7833-9ae8-8fa61d298a02.svg" style="width: 30px"/>
            <span class="miniFont vmg4">视频</span>
          </div>
        </CustomOssUploader>
      </nut-col>
      <nut-col :span="6" class="colFlex bothCenter vmg16" @click="publishPoliceEvent">
        <img src="https://cdn.waityou.online/cb53aa4c-0abf-faf5-9fda-86dbda33764b.svg" style="width: 30px"/>
        <span class="miniFont vmg4">发布警情</span>
      </nut-col>
      <nut-col :span="6" class="colFlex bothCenter">
        <span>&nbsp;</span>
      </nut-col>
    </nut-row>
  </nut-popup>

</template>

<script>
import ImageMessage from "@/components/im/ImageMessage"
import TextMessage from "@/components/im/TextMessage"
import CardMessage from "@/components/im/CardMessage"
import VoiceMessage from "@/components/im/VoiceMessage"
import api from "@/api";
import {v4 as uuidv4} from 'uuid';
import {Toast} from "@nutui/nutui";
import uploader from "@/utils/uploader";
import CustomOssUploader from "@/components/CustomOssUploader"
import PoliceEventPublishView from "@/views/police/PoliceEventPublishView";
import EventBar from "@/components/im/EventBar";
import SystemNoticeMessage from "@/components/im/SystemNoticeMessage";
import MicRecorder from 'mic-recorder-to-mp3';
import RollbackNoticeMessage from "@/components/im/RollbackNoticeMessage";
import VideoMessage from "@/components/im/VideoMessage.vue";

export default {
  name: "ImView",
  components: {
    VideoMessage,
    RollbackNoticeMessage,
    SystemNoticeMessage,
    EventBar,
    PoliceEventPublishView,
    ImageMessage, VoiceMessage, CardMessage, TextMessage, CustomOssUploader
  },
  data() {
    return {
      chatId: this.$route.params.chatId,
      chatInfo: null,
      editContent: '',
      inputMode: 0,
      editMode: 0,
      userInfo: {},
      queryTask: null,
      lastScrollEventHandleTime: 0,
      mediaRecorder: null,
      chunks: [],
      morePanelVisbile: false,
      eventPublishVisible: false,
      recording: false,
      recordStartTime: 0,
      playingVoiceMessageId: 0,
      cancelRecordFlag: false,
      currentEventId: null,
      loadEarlierBlock: false,
    }
  },
  methods: {
    publishPoliceEvent() {
      this.morePanelVisbile = false;
      this.eventPublishVisible = true;
    },
    onEventClick(eventId) {
      this.currentEventId = eventId;
    },
    gotoPoliceEvent(eid) {
      this.$store.commit('resetEarlierBlock', true)
      this.$router.push('/police/event/detail/' + eid);
    },
    onVoicePlay(messageId) {
      //关闭其他正在播放的音频
      this.playingVoiceMessageId = messageId;
    },
    startRecording() {
      console.log('录音按下!')
      this.mediaRecorder = new MicRecorder({
        bitRate: 128,
      });
      this.mediaRecorder.start().then(() => {
        this.recording = true;
        this.recordStartTime = new Date().getTime();
      });
    },
    cancelRecord() {
      this.cancelRecordFlag = true;
      this.mediaRecorder.stop();
      this.recording = false;
    },
    stopRecording() {
      // eslint-disable-next-line no-unused-vars
      this.mediaRecorder.stop().getMp3().then(([buffer, blob]) => {
        console.log('Blob 对象大小:', blob.size, 'bytes');
        uploader.uploadBlob(blob, url => {
          console.log('上传音频url', url)
          this.sendVoiceMessage(url, new Date().getTime() - this.recordStartTime)
        })
      });
      this.recording = false;
    },
    loadConversation(scroll) {
      this.$store.commit('updateImTime')
      api.listChatMessage({
        'chatId': this.chatId,
        'page': 0,
        'pageSize': 10,
      }).then(ret => {
        document.title = this.chatInfo.chatName;
        let add = 0;
        for (let m of ret.list) {
          if (this.$store.state.messageUUIds.includes(m.messageUUID)) {
            continue
          }
          add++;
          this.$store.commit('pushMessage', m);
        }
        for (let m of ret.list) {
          if (m.messageType == 'ROLLBACK_UPDATE') {
            this.$store.commit('rollback', m.textContent)
          }
        }
        if (add > 0 && scroll) {
          setTimeout(() => {
            this.toLastestMessage()
          }, 200)
        }
      });
      this.loadEarlier();
    },
    gotoSearchView(){
      this.$router.push('/im/message/search/'+this.chatInfo.id)
    },
    loadEarlier() {
      if (this.$refs.messageList.scrollTop > 100) {
        return
      }
      if (this.$store.state.imMessage.length < 1) {
        return;
      }
      if (this.$store.state.loadEarlierBlock) {
        this.$store.commit('resetEarlierBlock', false)
        this.$refs.messageList.scrollTo({
          top: this.$store.state.imScrollHeight,
          // behavior: 'smooth'
        })
        return;
      }
      let minMessage = this.$store.state.imMessage[0];
      api.getEarlierMessage({
        chatId: minMessage.chatId,
        maxMessageId: minMessage.id,
      }).then(list => {
        if (!list || list.length < 1) {
          console.log('没有更早的消息了')
          return
        }
        for (let m of list) {
          if (this.$store.state.messageUUIds.includes(m.messageUUID)) {
            continue
          }
          this.$store.commit('unshiftMessage', m)
        }
        setTimeout(() => {
          let anchorElement = document.getElementById('message-' + minMessage.id);
          if (anchorElement) {
            anchorElement.scrollIntoView();
          }
        }, 200)
      })
    },
    sendImageMessage(images) {
      this.sendMessage({
        'messageType': 'IMAGE',
        'imageUrl': images[0],
      })
      this.morePanelVisbile = false;
    },
    sendVideoMessage(videos) {
      this.sendMessage({
        'messageType': 'VIDEO',
        'imageUrl': videos[0],
      })
      this.morePanelVisbile = false;
    },
    sendVoiceMessage(voiceUrl, durationMs) {
      this.sendMessage({
        'messageType': 'VOICE',
        'voiceUrl': voiceUrl,
        'voiceSeconds': Math.ceil(durationMs / 1000)
      })
    },
    sendTextMessage() {
      if (this.editContent.length > 0) {
        let m = {
          'messageType': 'TEXT',
          'textContent': this.editContent,
        }
        this.sendMessage(m);
        this.editContent = '';
        this.editMode = 0;
      }
    },
    sendMessage(m) {
      m.chatId = this.chatId;
      m.messageUUID = uuidv4();
      m.creatorName = this.userInfo.nickName;
      m.creatorId = this.userInfo.id;
      m.creatorAvtar = this.userInfo.avatar;
      m.refEventId = this.currentEventId;

      this.$store.commit('addMessageUUID', m.messageUUID)
      api.postChatMessage(m).then(ret => {
        Toast.hide()
        // console.log('成功发布消息', ret);
        this.$store.commit('pushMessage', ret);
        setTimeout(() => {
          this.toLastestMessage()
        }, 200)
      })
    },
    toLastestMessage() {
      this.$refs.messageList.scrollTo({
        top: this.$refs.messageList.scrollHeight,
        behavior: 'smooth'
      })
    },
    onEditChange() {
      if (this.editContent.length > 0) {
        this.editMode = 1;
      } else {
        this.editMode = 0;
      }
    },
    startLoadTask() {
      this.queryTask = setInterval(this.loadConversation, 1000)
    },
  },
  mounted() {
    api.getChatInfo(this.chatId)
        .then(ret => {
          this.chatInfo = ret;
          document.title = ret.chatName;
        })
    api.getUserInfo()
        .then(ret => {
          this.userInfo = ret;
          this.loadConversation(true)
          this.startLoadTask()
        })
  },
  beforeUnmount() {
    this.$store.commit('setImScrollHeight',this.$refs.messageList.scrollTop)
  },
  unmounted() {
    clearInterval(this.queryTask);
  }
}
</script>

<style lang="scss" scoped>
.recordingNotice {
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 72px);
  z-index: 100;
}

.ImViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F0F1F4;
  height: 100vh;

  .voiceBtn {
    width: 100%;
  }

  .voiceBtnRecording {
    width: 100%;
    background-color: #1388ff;
    color: white;
    border: none;
  }

  .messageList {
    display: flex;
    flex-direction: column;
    padding: 11px;
    height: calc(85vh - 30px);
    overflow-y: scroll;
    position: relative;
  }


  .editorContainer {
    border-top: #DDDDDD 1px solid;
    display: flex;
    //min-height: 10vh;
    padding: 5px 0;
    box-sizing: border-box;

    .textInput {
      height: 5vh;
      width: 100%;
      background-color: white;
      border: none;
      font-size: $smallerFontSize;
      padding: 0 5px;
    }

    .textInput:focus {
      outline: none;
      border: 1px solid #528FFF;
    }

    .textInput::-webkit-input-placeholder {
      font-size: $smallerFontSize;
    }
  }
}
</style>