<template>
  <div class="VoiceMessageContainer vmg8">
    <LeftMessage v-if="myId != data.creatorId" :data="data">
      <nut-animate type='flicker' :loop='true' v-if="playing">
        <div class="voice" @click="stopPlayVoice">
          <div class="voiceEntity">
            <nut-icon name="voice"></nut-icon>
            <span>{{ data.voiceSeconds }}s</span>
          </div>
        </div>
      </nut-animate>
      <div v-else class="voice" @click="playVoice">
        <div class="voiceEntity">
          <nut-icon name="voice"></nut-icon>
          <span>{{ data.voiceSeconds }}s</span>
        </div>
      </div>
    </LeftMessage>
    <RightMessage v-if="myId == data.creatorId" :data="data">
      <div class="voice">
        <nut-animate type='flicker' :loop='true' v-if="playing">
          <div class="voiceEntity" @click="stopPlayVoice">
            <span>{{ data.voiceSeconds }}s</span>
            <nut-icon name="voice" style="transform: rotate(180deg);"></nut-icon>
          </div>
        </nut-animate>
        <div v-else class="voiceEntity" @click="playVoice">
          <span>{{ data.voiceSeconds }}s</span>
          <nut-icon name="voice" style="transform: rotate(180deg);"></nut-icon>
        </div>
      </div>
    </RightMessage>
  </div>
</template>

<script>

import LeftMessage from "@/components/im/LeftMessage";
import RightMessage from "@/components/im/RightMessage";

export default {
  name: "VoiceMessage",
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    myId: {
      type: Number,
      default: -1,
    },
    playingMessageId: {
      type: Number,
      default: 0,
    }
  },
  watch: {
    playingMessageId() {
      if (this.playingMessageId != this.data.id) {
        if (this.player) {
          this.stopPlayVoice();
        }
      }
    }
  },
  data() {
    return {
      playing: false,
      player: null,
    }
  },
  methods: {
    stopPlayVoice() {
      this.playing = false;
      this.player.pause();
    },
    playVoice() {
      this.$emit('voicePlay', this.data.id);
      this.player = new Audio(this.data.voiceUrl);
      let thiz = this;
      this.player.addEventListener('ended', function () {
        // 在播放完成时执行的操作
        console.log('播放完成');
        thiz.stopPlayVoice();
      });
      this.player.play();
      this.playing = true;
    }
  },
  components: {LeftMessage, RightMessage}
}
</script>

<style lang="scss" scoped>
.VoiceMessageContainer {
  display: flex;
  flex-direction: column;

  .voice {
    margin-top: 10px;
    align-items: center;
    display: flex;

    .voiceEntity {
      font-size: $miniFontSize;
      background-color: #528FFF;
      color: white;
      border-radius: 5px;
      padding: 5px 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {

      }
    }
  }
}
</style>