<template>
  <div class="ShopCheckDetailItemContainer">
    <div class="ShopCheckDetailItem">
      <nut-row class="idxContainer leftCenter">
        <div class="idx bothCenter">
          <span class="miniFont">{{ index + 1 }}</span>
        </div>
        <span class="baseFont">{{ detail.itemName }}</span>
      </nut-row>
      <nut-row class="bothCenter smallFont" v-if="detail?.data?.noProblem">
        <nut-col :span="12" class="rightCenter" @click="detail.data.noProblem=true;">
          <span class="good smallFont">无问题</span>
        </nut-col>
        <nut-col :span="12" class="leftCenter" @click="detail.data.noProblem=false;">
          <span class="unSelect smallFont">待整改</span>
        </nut-col>
      </nut-row>
      <template v-else>
        <nut-row class="bothCenter smallFont">
          <nut-col :span="12" class="rightCenter" @click="detail.data.noProblem=true;">
            <span class="unSelect smallFont">无问题</span>
          </nut-col>
          <nut-col :span="12" class="leftCenter" @click="detail.data.noProblem=false;">
            <span class="notGood smallFont">待整改</span>
          </nut-col>
        </nut-row>
        <div class="textAreaContainer">
          <nut-textarea class="problemDescription" placeholder="请输入异常问题描述"
                        v-model="problemRemark"></nut-textarea>
        </div>
        <div class="uploaderContainer">
          <OssUploader :multiple="true"
                       :files="detail?.data?.problemImages"
                       :limit="9" @onUploadedFiles="onUploadedFiles"></OssUploader>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import OssUploader from "@/components/OssUploader"

export default {
  name: "ShopCheckDetailItem",
  components: {OssUploader},

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    index: {
      type: Number,
      default: 0,
    }
  },
  methods: {
    getCheckData() {
      // this.detail.data.problemImages = this.fileList;
      // this.detail.data.problemRemark = this.problemRemark;
      return {
        noProblem: this.detail.data.noProblem,
        problemImages: this.fileList,
        problemRemark: this.problemRemark,
        itemName:this.detail.itemName,
      };
    },
    init() {
      this.detail = this.data;
      this.problemRemark = this.detail.data.problemRemark;
    },
    onUploadedFiles(fs) {
      this.fileList = [];
      for(let f of Object.keys(fs)){
        this.fileList.push(fs[f])
      }
    }
  },
  data() {
    return {
      problemRemark: null,
      detail: {},
      fileList: []
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
textarea::placeholder {
  color: #999999 !important;
}

.ShopCheckDetailItemContainer {
  padding: 10px 10px 0 10px;

  .uploaderContainer {
    display: flex;
    padding: 10px;
  }

  .textAreaContainer {
    display: flex;
    padding: 10px;

    .problemDescription {
      border: 1px dashed rgba(187, 187, 187, 1);
    }
  }


  .ShopCheckDetailItem {
    background-color: white;
    border-radius: 5px;

    .good {
      width: 74px;
      border-radius: 2px;
      background-color: rgba(22, 119, 254, 0.1);
      color: rgba(22, 119, 254, 1);
      text-align: center;
      margin: 10px;
      padding: 3px 0;
    }

    .notGood {
      width: 74px;
      border-radius: 2px;
      background-color: rgba(245, 63, 63, 0.1);
      color: rgba(245, 63, 63, 1);
      text-align: center;
      margin: 10px;
      padding: 3px 0;
    }

    .unSelect {
      width: 74px;
      border-radius: 2px;
      background-color: rgba(242, 242, 242, 1);
      color: rgba(153, 153, 153, 1);
      text-align: center;
      margin: 10px;
      padding: 3px 0;
    }

    .idxContainer {
      padding: 10px 0;

      .idx {
        font-style: italic;
        width: 16px;
        height: 16px;
        line-height: 17px;
        border-radius: 4px;
        background-color: rgba(22, 119, 254, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
        margin: 0 10px;
      }
    }
  }
}
</style>