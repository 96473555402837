import axios from "../utils/http";
import path from "./path"

const stringFormat = (formatted, args) => {
    for (let i = 0; i < args.length; i++) {
        let regexp = new RegExp('\\{' + i + '\\}', 'gi')
        formatted = formatted.replace(regexp, args[i])
    }
    return formatted
}

const api = {
    get(url, query) {
        if (query) {
            return axios.get(path.baseUrl + url, {params: query})
        }
        return axios.get(path.baseUrl + url)
    },
    getUserInfo() {
        return axios.get(path.baseUrl + path.userInfo)
    },
    login(data) {
        return axios.post(path.baseUrl + path.login, data)
    },
    logout(env) {
        return axios.get(path.baseUrl + stringFormat(path.logout, [env]))
    },
    getOssKeySecret() {
        return axios.get(path.baseUrl + path.getOssKeySecret);
    },
    getWechatRedirectUrl(url) {
        return axios.get(path.baseUrl + stringFormat(path.getWechatRedirectUrl, [url]))
    },
    wechatCodeLogin(code) {
        return axios.get(path.baseUrl + stringFormat(path.wechatCodeLogin, [code]))
    },
    getAuthInfo() {
        return axios.get(path.baseUrl + path.getAuthInfo)
    },
    submitAuthInfo(data) {
        return axios.post(path.baseUrl + path.submitAuthInfo, data)
    },
    createPost(data) {
        return axios.post(path.baseUrl + path.createPost, data)
    },
    getBanners() {
        return axios.get(path.baseUrl + path.getBanners)
    },
    getPlatformMessage(query) {
        return axios.get(path.baseUrl + path.getPlatformMessage, {params: query})
    },
    getPostList(query) {
        return axios.get(path.baseUrl + path.getPostList, {params: query})
    },
    getGetComments(query) {
        return axios.get(path.baseUrl + path.getGetComments, {params: query})
    },
    markRead(mid) {
        return axios.get(path.baseUrl + stringFormat(path.markRead, [mid]))
    },
    getPostDetail(pid) {
        return axios.get(path.baseUrl + stringFormat(path.getPostDetail, [pid]))
    },
    getCommentCreatorInfo(commentId) {
        return axios.get(path.baseUrl + stringFormat(path.getCommentCreatorInfo, [commentId]))
    },
    readAllMessage() {
        return axios.get(path.baseUrl + path.readAllMessage)
    },
    postComment(data) {
        return axios.post(path.baseUrl + path.postComment, data)
    },
    getHotList() {
        return axios.get(path.baseUrl + path.getHotList)
    },
    thumbPost(posterId) {
        return axios.get(path.baseUrl + stringFormat(path.thumbPost, [posterId]))
    },
    thumbComment(commentId) {
        return axios.get(path.baseUrl + stringFormat(path.thumbComment, [commentId]))
    },
    searchPostList(data) {
        return axios.post(path.baseUrl + path.searchPostList, data)
    },
    updateInfo(data) {
        return axios.post(path.baseUrl + path.updateInfo, data)
    },
    feedback(data) {
        return axios.post(path.baseUrl + path.feedback, data)
    },
    listChatMessage(query) {
        return axios.get(path.baseUrl + path.listChatMessage, {params: query})
    },
    postChatMessage(data) {
        return axios.post(path.baseUrl + path.postChatMessage, data)
    },
    getShopTypes() {
        return axios.get(path.baseUrl + path.getShopTypes)
    },
    getCommunities() {
        return axios.get(path.baseUrl + path.getCommunities)
    },
    queryShopList(data) {
        return axios.post(path.baseUrl + path.queryShopList, data)
    },
    getStreetList(query) {
        return axios.get(path.baseUrl + path.getStreetList, {params: query})
    },
    getShopDetail(shopId) {
        return axios.get(path.baseUrl + stringFormat(path.getShopDetail, [shopId]))
    },
    getShopCheckMatters(query) {
        return axios.get(path.baseUrl + path.getShopCheckMatters, {params: query})
    },
    postEvent(data) {
        return axios.post(path.baseUrl + path.postEvent, data)
    },
    queryPoliceEventList(query) {
        return axios.get(path.baseUrl + path.queryPoliceEventList, {params: query})
    },
    getInfiniteLoadingList(i, method, query) {
        if ('GET' == method) {
            return axios.get(path.baseUrl + path.infiniteLoadings[i], {params: query})
        }
        return axios.post(path.baseUrl + path.infiniteLoadings[i], query)
    },
    updateShop(shop) {
        return axios.post(path.baseUrl + path.updateShop, shop)
    },
    report(data) {
        return axios.post(path.baseUrl + path.report, data)
    },
    queryReportExist(data) {
        return axios.post(path.baseUrl + path.queryReportExist, data)
    },
    updateShopImage(data) {
        return axios.post(path.baseUrl + path.updateShopImage, data)
    },
    getShopCheckers(shopId) {
        return axios.get(path.baseUrl + stringFormat(path.getShopCheckers, [shopId]))
    },
    addShopChecker(query) {
        return axios.get(path.baseUrl + path.addShopChecker, {params: query})
    },
    getShopMatterDetail(query) {
        return axios.get(path.baseUrl + path.getShopMatterDetail, {params: query})
    },
    updateShopMatterCheck(data) {
        return axios.post(path.baseUrl + path.updateShopMatterCheck, data)
    },
    submitCheckReport(data) {
        return axios.post(path.baseUrl + path.submitCheckReport, data)
    },
    getShopCheckReport(reportId) {
        return axios.get(path.baseUrl + stringFormat(path.getShopCheckReport, [reportId]))
    },
    removeChecker(query) {
        return axios.get(path.baseUrl + path.removeChecker, {params: query})
    },
    signature2image(data) {
        return axios.post(path.baseUrl + path.signature2image, data)
    },
    getOpenApiToken(code) {
        return axios.get(path.baseUrl + stringFormat(path.getOpenApiToken, [code]))
    },
    getOpenShopCheckReport(reportId) {
        return axios.get(path.baseUrl + stringFormat(path.getOpenShopCheckReport, [reportId]))
    },
    submitReceiveReport(data) {
        return axios.post(path.baseUrl + path.submitReceiveReport, data)
    },
    updateMySign(url) {
        return axios.get(path.baseUrl + stringFormat(path.updateMySign, [url]))
    },
    deleteMySign() {
        return axios.get(path.baseUrl + path.deleteMySign)
    },
    getClockInfo() {
        return axios.get(path.baseUrl + path.getClockInfo)
    },
    updateClockInAddress(data) {
        return axios.post(path.baseUrl + path.updateClockInAddress, data)
    },
    getDataSummary() {
        return axios.get(path.baseUrl + path.getDataSummary)
    },
    getEarlierMessage(query) {
        return axios.get(path.baseUrl + path.getEarlierMessage, {params: query})
    },
    getEventDetail(eventId) {
        return axios.get(path.baseUrl + stringFormat(path.getEventDetail, [eventId]))
    },
    getActiveEvents(query) {
        return axios.get(path.baseUrl + path.getActiveEvents, {params: query})
    },
    closePoliceEvent(eventId) {
        return axios.get(path.baseUrl + stringFormat(path.closePoliceEvent, [eventId]))
    },
    updatePass(data) {
        return axios.post(path.baseUrl + path.updatePass, data)
    },
    bindWechat(token) {
        return axios.get(path.baseUrl + stringFormat(path.wxBindToken, [token]))
    },
    getUnReadMessageCount() {
        return axios.get(path.baseUrl + path.getUnReadMessageCount)
    },
    getMyStatisticData() {
        return axios.get(path.baseUrl + path.getMyStatisticData)
    },
    getStatisticRank() {
        return axios.get(path.baseUrl + path.getStatisticRank)
    },
    getMonthCalendar(query) {
        return axios.get(path.baseUrl + path.getMonthCalendar, {params: query})
    },
    queryShopStatisticDataByDay(query) {
        return axios.get(path.baseUrl + path.queryShopStatisticDataByDay, {params: query})
    },
    updateShopCheckReport(reportId) {
        return axios.get(path.baseUrl + stringFormat(path.updateShopCheckReport, [reportId]))
    },
    getReportDetail(eventId) {
        return axios.get(path.baseUrl + stringFormat(path.getReportDetail, [eventId]))
    },
    getWeather() {
        return axios.get(path.baseUrl + path.getWeather)
    },
    getSearchToken(data) {
        return axios.post(path.baseUrl + path.getSearchToken, data)
    },
    getSearchHistory() {
        return axios.get(path.baseUrl + path.getSearchHistory)
    },
    getSearch(searchToken) {
        return axios.get(path.baseUrl + stringFormat(path.getSearch, [searchToken]))
    },
    setShopState(shopId, state) {
        return axios.get(path.baseUrl + stringFormat(path.setShopState, [shopId, state]))
    },
    updateShopLocation(data) {
        return axios.post(path.baseUrl + path.updateShopLocation, data)
    },
    cleanShopSearchHistory() {
        return axios.get(path.baseUrl + path.cleanShopSearchHistory)
    },
    queryShopStatisticDataByYear(query) {
        return axios.get(path.baseUrl + path.queryShopStatisticDataByYear, {params: query})
    },
    queryShopStatisticDataByMonth(query) {
        return axios.get(path.baseUrl + path.queryShopStatisticDataByMonth, {params: query})
    },
    queryShopStatisticDataByDateRange(query) {
        return axios.get(path.baseUrl + path.queryShopStatisticDataByDateRange, {params: query})
    },
    uploadErrorMessage(data) {
        return axios.post(path.baseUrl + path.uploadErrorMessage, data)
    },
    covertGPS(query) {
        return axios.get(path.baseUrl + path.covertGPS, {params: query})
    },
    unbindWechat() {
        return axios.get(path.baseUrl + path.unBindWechat)
    },
    getChatList() {
        return axios.get(path.baseUrl + path.getChatList)
    },
    getReadPriorities() {
        return axios.get(path.baseUrl + path.getReadPriorities)
    },
    getTodayCheck() {
        return axios.get(path.baseUrl + path.getTodayCheck)
    },
    submitDutyCheckResult(data) {
        return axios.post(path.baseUrl + path.submitDutyCheckResult, data)
    },
    getDutyCheckHistory(historyId) {
        return axios.get(path.baseUrl + path.getDutyCheckHistory, {params: {'id': historyId}})
    },
    getChatInfo(chatId) {
        return axios.get(path.baseUrl + path.getChatInfo, {params: {'chatId': chatId}})
    },
    addShopClerk(form) {
        return axios.post(path.baseUrl + path.addShopClerk, form)
    },
    getShopClerk(shopId) {
        return axios.get(path.baseUrl + stringFormat(path.getShopClerk, [shopId]))
    },
    toHistoryShopClerk(uid) {
        return axios.get(path.baseUrl + stringFormat(path.toHistoryShopClerk, [uid]))
    },
    getPoliceEventHandleRank() {
        return axios.get(path.baseUrl + path.getPoliceEventHandleRank)
    },
    rollbackMessage(id) {
        return axios.get(path.baseUrl + stringFormat(path.rollbackMessage, [id]))
    },
    updateShopClerk(data) {
        return axios.post(path.baseUrl + path.updateShopClerk, data)
    }
}

export default api;