<template>
  <div class="EventBarContainer">
    <div class="eventList leftCenter">
      <EventItem class="eventItem"
                 :active="e.id===dataIndex"
                 @click="onEventClick(e.id)"
                 v-for="(e,ei) in list"
                 :data="e"
                 :key="ei" e-name="警情" :e-index="e.eventSeqNo"/>
    </div>
  </div>
</template>

<script>
import EventItem from "@/components/im/EventItem"
import api from "@/api";

export default {
  name: "EventBar",
  components: {EventItem},
  props: {
    chatId: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      dataIndex: 0,
      list: [],
      queryTask: null,
    }
  },
  methods: {
    onEventClick(eid) {
      if (this.dataIndex != eid) {
        this.dataIndex = eid;
        this.$emit('eventClick', eid)
      } else {
        this.dataIndex = 0;
        this.$emit('eventClick', null)
      }
    },
    init() {
      api.getActiveEvents({
        chatId: this.chatId
      }).then(ret => {
        this.list = ret;
      })
    }
  },
  mounted() {
    this.init()
    this.queryTask = setInterval(this.init, 5000)
  },
  unmounted() {
    clearInterval(this.queryTask)
  }
}
</script>

<style lang="scss" scoped>
.EventBarContainer {
  display: flex;
  flex-direction: row;
  background-color: transparent;

  .eventList {
    padding: 11px;
    display: flex;
    height: 5vh;
    flex-direction: row;
    overflow-x: scroll;

    .eventItem {
      flex: 0 0 auto;
    }
  }

  .eventList::-webkit-scrollbar {
    display: none; /* 隐藏滚动条 */
  }

}
</style>