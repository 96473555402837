<template>
  <div class="shopListContainer">
    <div class="searchForm">
      <nut-row class="bothCenter">
        <nut-col :span="21" class="leftCenter">
          <div class="searchFormContainer">
            <nut-icon name="search2" size="14px" class="searchIcon"></nut-icon>
            <input class="inputForm smallFont" v-model="search" placeholder="搜索店铺相关信息"/>
          </div>
        </nut-col>
        <nut-col :span="3" class="rightCenter">
          <span class="smallFont" v-if="!search || search.content < 1" @click="$router.replace('/shop/list')">取消</span>
          <span class="smallFont" v-else @click="onSearch">搜索</span>
        </nut-col>
      </nut-row>
    </div>
    <nut-row class="recentSearch">
      <nut-col :span="12">
        <span>最近搜索</span>
      </nut-col>
      <nut-col :span="12" class="rightCenter">
        <img class="clean" @click="cleanShopSearchHistory" src="https://cdn.waityou.online/9682f2f5-1314-21aa-7a0f-ebb207784849.png"/>
      </nut-col>
    </nut-row>
    <nut-grid :column-num="4" :border="false">
      <nut-grid-item v-for="s in history" :key="s.id">
        <div class="wd100 searchHistoryBtn bothCenter smallFont mb16" @click="useHistory(s.id)">
          {{s.content}}
        </div>
      </nut-grid-item>
    </nut-grid>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "ShopSearchView",
  data() {
    return {
      search: null,
      history:[],
    }
  },
  methods: {
    onSearch() {
      api.getSearchToken({
        search: this.search,
      }).then(token => {
        this.$router.replace('/shop/list?token=' + token)
      })
    },
    useHistory(hid){
      this.$router.replace('/shop/list?token=' + hid)
    },
    cleanShopSearchHistory(){
      api.cleanShopSearchHistory()
          .then(()=>{
            this.history = [];
          })
    },
  },
  mounted() {
    const collapseItems = document.querySelectorAll('.nut-grid-item__content')
    collapseItems.forEach(item => {
      item.style.padding = '8px'
    })
    api.getSearchHistory()
        .then(list=>{
          this.history = list;
        })
  }
}
</script>

<style lang="scss" scoped>
.shopListContainer {
  height: 100vh;
  background-color: white;
  padding: 20px;

  input:focus {
    outline: none;
  }

  .searchHistoryBtn {
    border-radius: 2px;
    background-color: rgba(248, 248, 248, 1);
    color: rgba(51, 51, 51, 1);
    border: none;
    height: 30px;
  }

  .recentSearch {
    display: flex;
    padding: 10px 0;
    font-size: $baseFontSize;

    .clean {
      width: $biggerFontSize;
    }
  }

  .searchForm {
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;


    .searchFormContainer {
      line-height: 20px;
      border-radius: 18px;
      background-color: #F0F1F4;
      width: 100%;
      padding: 5px 5px;

      .searchIcon {
        color: #B2B2B2;
        padding: 0 5px;
      }

      display: flex;
      //justify-content: center;
      align-items: center;

      input {
        background-color: #F0F1F4;
        width: 80%;
        border: none;
      }

      input::placeholder {
        color: #B2B2B2
      }
    }
  }
}
</style>