<template>
  <div class="ChatListViewContainer">
    <InfiniteLoadingList :query-id="9">
      <template v-slot="data">
        <ChatGroup @click="goto(data.data.id)" :data="data.data"/>
      </template>
    </InfiniteLoadingList>

  </div>
</template>

<script>
import ChatGroup from "@/components/im/ChatGroup"
import api from "@/api";
import InfiniteLoadingList from "@/components/InfiniteLoadingList";
import {mapMutations} from "vuex";
export default {
  name: "ChatListView",
  components: {InfiniteLoadingList, ChatGroup},
  data() {
    return {
      chats: []
    }
  },
  methods: {
    ...mapMutations(['setCachePage','delCachePage']),
    init() {
      this.delCachePage('ImView')
      setTimeout(()=>{
        this.setCachePage('ImView')
      },200)
      api.getChatList()
          .then(list => {
            this.chats = list;
          })

    },
    goto(chatId) {
      this.$router.push('/im/' + chatId)
    }
  },
  mounted() {
    this.init()
    this.$store.commit('clearImMessage')
  }
}
</script>

<style lang="scss" scoped>
.ChatListViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
}
</style>