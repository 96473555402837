<template>
  <div class="AddCheckerViewContainer colFlex">
    <nut-row class="bothCenter">
      <nut-col :span="2" class="leftCenter">
        <span>&nbsp;</span>
      </nut-col>
      <nut-col :span="20" class="bothCenter">
        <span class="title">添加人员</span>
      </nut-col>
      <nut-col :span="2" class="rightCenter">
        <nut-icon name="close" color="#939393" @click="$emit('close')"></nut-icon>
      </nut-col>
    </nut-row>
    <CheckerItem v-for="(c,ci) in current"
                 @delete="deleteChecker(c)"
                 :key="ci" :data="c"
                 :my-self="myInfo.id == c.id"/>
  </div>

  <div class="submitBtnContainer bothCenter">
    <nut-button class="submitBtn" @click="addCheckerVisible=true">添加人员</nut-button>
  </div>
  <nut-picker
      v-model:visible="addCheckerVisible"
      :columns="peoples"
      title="选择人员"
      @confirm="onConfirm"
  >
  </nut-picker>
</template>

<script>
import CheckerItem from "@/components/shop/CheckerItem";
import api from "@/api";

export default {
  name: "AddCheckerView",
  components: {CheckerItem},
  props: {
    current: {
      type: Array,
      default: () => {
      }
    },
    more: {
      type: Array,
      default: () => {
      }
    },
  },
  watch: {
    more() {
      this.init()
    }
  },
  data() {
    return {
      addCheckerVisible: false,
      peoples: [],
      myInfo:{}
    }
  },
  methods: {
    deleteChecker(c){
      this.$emit('deleteChecker',c)
    },
    onConfirm(s){
      this.$emit('addChecker',s.selectedValue[1])
      this.addCheckerVisible=false;
    },
    init() {
      this.peoples = []
      for (let orgName of Object.keys(this.more)) {
        let op = {
          text: orgName,
          value: orgName,
        }
        let children = []
        for (let u of this.more[orgName]) {
          children.push({
            text: u.nickName,
            value: u.id,
          })
        }
        op.children = children;
        this.peoples.push(op)
      }
    }
  },
  mounted() {
    api.getUserInfo()
        .then(ret=>{
          this.myInfo = ret;
        })
  }
}
</script>

<style lang="scss" scoped>
.submitBtnContainer {
  background-color: white;
  position: fixed;
  width: 100%;
  bottom: 0;

  .submitBtn {
    background-color: rgba(22, 119, 254, 1);
    color: rgba(255, 255, 255, 1);
    border: none;
    width: 94%;
    margin: 10px;
  }
}

.AddCheckerViewContainer {
  background-color: #F5F5F5;
  display: flex;
  min-height: 300px;
  padding: 12px;
  padding-bottom: 100px;

  .title {
    font-style: $baseFontSize;
    margin: 10px 0;
  }
}
</style>