<template>
  <div class="shopListContainer">
    <div class="searchForm">
      <div class="searchFormContainer">
        <nut-icon name="search2" size="14px" class="searchIcon"></nut-icon>
        <input class="smallFont" :placeholder="searchContent?searchContent:'搜索店铺相关信息'"
               @focus="goToSearchView"/>
      </div>
    </div>
    <ShopCheckSelector
        :withCheckHistory="withCheckHistory"
        @yearCheckChange="yearCheckChange"
        @filterByShopTypes="filterByShopTypes"
        @filterByCommunities="filterByCommunities"/>
    <div class="streetList">
      <StreetItem v-for="s in streets" :key="s.id" :data="s"
                  @click="onStreetClick(s.id)"
                  :actived="streetId === s.id"/>
    </div>
    <InfiniteLoadingList :query-id="1" method="POST" :init-query="listQuery">
      <template v-slot="data">
        <div class="opd12 vpd2" @click="onShopListItemClick(data.data)">
          <ShopListItem :data="data.data" :myNavi="myNavi"/>
        </div>
      </template>
    </InfiniteLoadingList>
  </div>
</template>

<script>
import StreetItem from "@/components/shop/StreetItem";
import ShopListItem from "@/components/shop/ShopListItem";
import ShopCheckSelector from "@/components/shop/ShopCheckSelector";
import api from "@/api";
import InfiniteLoadingList from "@/components/InfiniteLoadingList"
import {Toast} from "@nutui/nutui";

export default {
  name: "ShopListView",
  components: {ShopCheckSelector, ShopListItem, StreetItem, InfiniteLoadingList},
  data() {
    return {
      searchToken: this.$route.query.token,
      shopTypeFilter: [],
      communityFilter: [],
      streetId: null,
      searchContent: null,
      shops: [],
      streets: [],
      noCheckThisYear: false,
      listQuery: {},
      withCheckHistory: this.$route.query.wch != null,
      myNavi: {},
    }
  },
  methods: {
    onShopListItemClick(shop) {
      if (shop.report == null) {
        this.$router.push('/shop/detail/' + shop.id)
      }
    },
    onStreetClick(sid) {
      this.streetId = sid;
      this.queryShopList();
    },
    goToSearchView() {
      this.$router.push('/shop/search')
    },
    yearCheckChange(checked) {
      this.noCheckThisYear = checked;
      this.queryShopList();
    },
    filterByShopTypes(ids) {
      this.shopTypeFilter = ids;
      this.queryShopList();
    },
    filterByCommunities(ids) {
      this.communityFilter = ids;
      this.queryShopList();
    },
    queryShopList() {
      this.listQuery = {
        'streetId': this.streetId,
        'communityIds': this.communityFilter,
        'shopTypeIds': this.shopTypeFilter,
        'search': this.searchContent,
        'noCheckThisYear': this.noCheckThisYear,
        'withCheckHistory': this.withCheckHistory
      }
    },
    locateMe() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
              // 成功获取定位信息
              let latitude = position.coords.latitude; // 纬度
              let longitude = position.coords.longitude; // 经度
              let accuracy = position.coords.accuracy; // 定位精度
              api.covertGPS({
                'longitude': longitude,
                'latitude': latitude,
                'accuracy': accuracy
              }).then(ret => {
                this.myNavi = ret;
              })
            },
            (error) => {
              // 获取定位信息失败
              switch (error.code) {
                case error.PERMISSION_DENIED:
                  Toast.fail('您拒绝了程序的定位要求')
                  // this.myNavi = {
                  //   longitude: 120.133345269098,
                  //   latitude: 30.307150336372
                  // }
                  break;
                default:
                  Toast.fail('定位时遇到未知错误，您的定位信息可能不准确')
                  break;
              }
            }
        );
      } else {
        Toast.hide()
        Toast.fail('您的浏览器不支持定位无法打卡')
      }
    }
  },
  activated() {
    if (this.searchToken != this.$route.query.token) {
      if(!this.$route.query.token){
        this.searchContent = null
      }
      this.searchToken = this.$route.query.token;
      if (this.searchToken) {
        api.getSearch(this.searchToken)
            .then(content => {
              this.searchContent = content;
              this.queryShopList();
            })
      } else {
        this.queryShopList()
      }
    }
  },
  deactivate() {
  },
  mounted() {
    console.log('ACTIVATE 2222')
    api.getStreetList({
      withAll: true,
    }).then(list => {
      this.streets = list;
      this.streetId = list[0].id;
    })
    if (this.searchToken) {
      api.getSearch(this.searchToken)
          .then(content => {
            this.searchContent = content;
            this.queryShopList();
          })
    } else {
      this.queryShopList()
    }
    this.locateMe()
  }
}
</script>

<style lang="scss" scoped>
.shopListContainer {
  width: 100%;
  min-height: 100vh;
  background-color: #F0F1F4;

  .shopList {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }

  .streetList {
    //width: 100%;
    display: flex;
    //flex-direction: row;
    overflow-x: scroll;
  }

  .streetList::-webkit-scrollbar {
    display: none;
  }


  .searchForm {
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;

    .searchFormContainer {
      line-height: 20px;
      border-radius: 18px;
      background-color: #F0F1F4;
      width: 80%;
      padding: 5px 5px;

      .searchIcon {
        color: #B2B2B2;
        padding: 0 5px;
      }

      display: flex;
      //justify-content: center;
      align-items: center;

      input {
        background-color: #F0F1F4;
        width: 85%;
        border: none;
      }

      input::placeholder {
        color: #B2B2B2
      }
    }
  }
}
</style>