<template>
  <div class="PersonalRankContainer">
    <nut-row className="bothCenter pd16 miniFont" style="background-color: rgba(102,102,102,0.05);color: #666666">
      <nut-col :span="3" class="leftCenter">
        <span>排名</span>
      </nut-col>
      <nut-col :span="7" class="leftCenter">
        <span>姓名</span>
      </nut-col>
      <nut-col :span="7" class="bothCenter">
        <span>处置数量</span>
      </nut-col>
      <nut-col :span="7" class="rightCenter">
        <span>组织</span>
      </nut-col>
    </nut-row>
    <template v-for="rnk in rank" :key="rnk.rankIndex">
      <nut-row className="bothCenter pd16 smallFont bg1" v-if="rnk.rankIndex == 1">
        <nut-col :span="3" class="leftCenter">
          <img src="https://cdn.waityou.online/e6cd4ecf-4049-4540-10c7-79b82dd9a592.png" class="rank"/>
        </nut-col>
        <nut-col :span="7" class="leftCenter">
          <img :src="rnk.avatar" class="avatar"/>
          <span style="margin-left: 5px">{{ rnk.name }}</span>
        </nut-col>
        <nut-col :span="7" class="bothCenter">
          <span>{{ rnk.cnt }}</span>
        </nut-col>
        <nut-col :span="7" class="rightCenter">
          <span>{{ rnk.org }}</span>
        </nut-col>
      </nut-row>
      <nut-row className="bothCenter pd16 smallFont bg2" v-if="rnk.rankIndex == 2">
        <nut-col :span="3" class="leftCenter">
          <img src="https://cdn.waityou.online/e1429cd3-3ef2-3892-999f-5d3826ed91ab.png" class="rank"/>
        </nut-col>
        <nut-col :span="7" class="leftCenter">
          <img :src="rnk.avatar" class="avatar"/>
          <span style="margin-left: 5px">{{ rnk.name }}</span>
        </nut-col>
        <nut-col :span="7" class="bothCenter">
          <span>{{ rnk.cnt }}</span>
        </nut-col>
        <nut-col :span="7" class="rightCenter">
          <span>{{ rnk.org }}</span>
        </nut-col>
      </nut-row>
      <nut-row className="bothCenter pd16 smallFont bg1" v-if="rnk.rankIndex == 3">
        <nut-col :span="3" class="leftCenter">
          <img src="https://cdn.waityou.online/a6e4fafb-5bf9-94c0-a441-b9219ea0f27e.png" class="rank"/>
        </nut-col>
        <nut-col :span="7" class="leftCenter">
          <img :src="rnk.avatar" class="avatar"/>
          <span style="margin-left: 5px">{{ rnk.name }}</span>
        </nut-col>
        <nut-col :span="7" class="bothCenter">
          <span>{{ rnk.cnt }}</span>
        </nut-col>
        <nut-col :span="7" class="rightCenter">
          <span>{{ rnk.org }}</span>
        </nut-col>
      </nut-row>
      <nut-row className="bothCenter pd16 smallFont " v-if="rnk.rankIndex > 3">
        <nut-col :span="3" class="leftCenter">
          <span class="baseFont" style="color: #B2B2B2;padding-left: 5px">{{rnk.rankIndex}}</span>
        </nut-col>
        <nut-col :span="7" class="leftCenter">
          <img :src="rnk.avatar" class="avatar"/>
          <span style="margin-left: 5px">{{ rnk.name }}</span>
        </nut-col>
        <nut-col :span="7" class="bothCenter">
          <span>{{ rnk.cnt }}</span>
        </nut-col>
        <nut-col :span="7" class="rightCenter">
          <span>{{ rnk.org }}</span>
        </nut-col>
      </nut-row>
    </template>
  </div>
</template>

<script>
export default {
  name: "PersonalRank",
  props: {
    rank: {
      type: Array,
      default: ()=>{
        return []
      }
    },
  },
  methods: {
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.PersonalRankContainer {
  display: flex;
  flex-direction: column;
  background-color: white;


  .bg1 {
    background-color: rgba(254, 175, 91, 0.1);
  }

  .bg2 {
    background-color: rgba(143, 200, 253, 0.1);
  }

  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 5px;
  }

  .rank {
    width: 20px;
    height: 28px;
  }

}
</style>