<template>
  <FullScreenSign @ok="onCreateSign" @cancel="$router.go(-1)"/>
</template>

<script>
import FullScreenSign from "@/components/sign/FullScreenSign";
import api from "@/api";

export default {
  name: "AddSignView",
  components: {FullScreenSign},
  methods: {
    onCreateSign(url) {
      api.updateMySign(url)
          .then(() => {
            this.$router.back()
          })
    }
  },
}
</script>

<style lang="scss" scoped>
.AddSignViewContainer {

}
</style>