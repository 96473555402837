<template>
  <div class="MySignViewContainer">
    <div class="bothCenter colFlex" v-if="userInfo?.signImage">
      <img :src="userInfo.signImage" style="width: 100%"/>

      <nut-row className="bothCenter pd12 bottomBtn">
        <nut-col :span="12" class="bothCenter">
          <nut-button class="deleteSign" @click="deleteMySign">删除签名</nut-button>
        </nut-col>
        <nut-col :span="12" class="bothCenter">
          <nut-button class="reSign" @click="$router.push('/my/sign/add')">重新签名</nut-button>
        </nut-col>
      </nut-row>
    </div>
    <div class="bothCenter colFlex noSign" v-else>
      <img src="https://cdn.waityou.online/02cb143a-e906-d16f-ca20-e398bcabf1b3.png" style="width: 60%">
      <span class="biggerText" style="color:#333333">暂无签名</span>
      <span class="miniText" style="color:#666666">请添加您的签名</span>
      <nut-button class="addSignBtn" @click="$router.push('/my/sign/add')">添加签名</nut-button>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "MySignView",
  data() {
    return {
      userInfo:null,
    }
  },
  methods: {
    init() {
      api.getUserInfo()
          .then(ret=>{
            this.userInfo = ret;
          })
    },
    deleteMySign(){
      api.deleteMySign()
          .then(()=>{
            this.init()
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.bottomBtn{
  position: absolute;
  bottom: 0;
  background-color: white;
  display: flex;
  padding: 12px 0;
  width: 100%;
  .deleteSign{
    height: 44px;
    border-radius: 22px;
    background-color: rgba(245, 63, 63, 1);
    color: rgba(255, 255, 255, 1);
    border: none;
    width: 90%;
  }
  .reSign{
    height: 44px;
    border-radius: 22px;
    background-color: rgba(22, 119, 254, 1);
    color: rgba(255, 255, 255, 1);
    border: none;
    width: 90%;
  }
}
.MySignViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F6F6F6;
  padding: 16px;
  min-height: 100vh;

  .noSign{
    margin-top: 30%;
    .addSignBtn{
      margin-top: 30px;
      width: 170px;
      height: 44px;
      border-radius: 22px;
      background-color: rgba(22, 119, 254, 1);
      color: white;
      border: none;
    }
  }

}
</style>