<template>
  <div class="TextMessageContainer">
    <LeftMessage v-if="myId != data.creatorId" :data="data">
      <div class="textMessage">
        <div class="textMessageEntity">
          <span class="smallFont">{{ data.textContent }}</span>
        </div>
      </div>
    </LeftMessage>
    <RightMessage v-if="myId == data.creatorId" :data="data">
      <div class="textMessage">
        <div class="textMessageEntity">
          <span class="smallFont">{{ data.textContent }}</span>
        </div>
      </div>
    </RightMessage>
  </div>
</template>

<script>
import LeftMessage from "@/components/im/LeftMessage";
import RightMessage from "@/components/im/RightMessage";
export default {
  name: "TextMessage",
  props: {
    data: {
      type: Object,
      default: ()=>{
        return {

        }
      }
    },
    myId:{
      type:Number,
      default:-1,
    }
  },
  components: {RightMessage, LeftMessage}
}
</script>

<style lang="scss" scoped>
.TextMessageContainer {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  .textMessage{
    margin-top: 10px;
    align-items: center;
    display: flex;
    max-width: 80%;
    margin: 10px 0;

    .textMessageEntity{
      font-size: $miniFontSize;
      background-color: #528FFF;
      color:white;
      border-radius: 5px;
      padding: 5px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      span{

      }
    }
  }
}
</style>