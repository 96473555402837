<template>
  <div class="eventContainer">
    <nut-row>
      <nut-col :span="12" class="leftCenter">
        <div class="dot">&nbsp;</div>
        <span class="eventTitle baseFont">&nbsp;事件源</span>
      </nut-col>
      <nut-col :span="12" class="rightCenter">
        <span class="darkRed baseFont">{{ event.eventTypeName }}</span>
      </nut-col>
    </nut-row>
    <nut-row class="eventImage">
      <photo-provider>
        <photo-consumer :src="event.previewImage" style="text-align: left">
          <img :src="`${event.previewImage}?x-oss-process=image/resize,p_10`"/>
        </photo-consumer>
      </photo-provider>
    </nut-row>
    <nut-row class="miniLogo bothCenter">
      <nut-col :span="12" class="leftCenter">
        <img src="https://cdn.waityou.online/c09dc4ef-b856-5c53-8164-ad030c786fd0.png"/>
        <span class="smallFont">{{event.reporterOrgName}}</span>
      </nut-col>
      <nut-col :span="12" class="rightCenter">
        <span class="smallFont">{{ event.createdTime }}</span>
      </nut-col>
    </nut-row>
    <nut-row style="margin: 10px 0" class="leftCenter">
      <div class="dot">&nbsp;</div>
      <span class="eventTitle baseFont">&nbsp;警情描述</span>
    </nut-row>
    <nut-row class="leftCenter">
      <p style="margin: 0">{{ event.content }}</p>
    </nut-row>
    <nut-row style="margin: 10px 0" class="leftCenter">
      <div class="dot">&nbsp;</div>
      <span class="eventTitle baseFont">&nbsp;警情动态</span>
    </nut-row>
    <InfiniteLoadingList :query-id="5" :init-query="{'eventId':eventId}">
      <template v-slot="data">
        <PoliceEventItem
            :prevSender="data.index > 0?data.list[data.index-1].sender:null"
            :data="data.data"
            :user="data.data.sender"/>
      </template>
    </InfiniteLoadingList>
    <div style="height: 60px">
    </div>
    <nut-row class="bothCenter" v-if="!event.active">
      <span class="finishTag">警情处置完毕</span>
    </nut-row>
    <div style="height: 60px">
      &nbsp;
    </div>
  </div>
  <div style="position: fixed;bottom: 0;width: 100%;background-color: white;"
       v-if="event.active"
       class="bothCenter vpd6">
    <nut-button class="doneBtn" @click="closeConfirm">处置完毕</nut-button>
  </div>
</template>

<script>
import PoliceEventItem from "@/components/police/PoliceEventItem";
import api from "@/api";
import InfiniteLoadingList from "@/components/InfiniteLoadingList";
import {Dialog} from "@nutui/nutui";

export default {
  name: "PoliceEventDetailView",
  components: {InfiniteLoadingList, PoliceEventItem},
  data() {
    return {
      eventId: this.$route.params.eventId,
      event: {},
    }
  },
  methods: {
    closeConfirm() {
      Dialog({
        title: '确认关闭警情吗',
        content: '请确认警情处置完毕',
        onOk: this.confirmClose
      })
    },
    confirmClose() {
      api.closePoliceEvent(this.eventId)
          .then(() => this.init())
    },
    init() {
      api.getEventDetail(this.eventId)
          .then(ret => {
            this.event = ret;
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.doneBtn {
  background-color: #097df5;
  height: 40px;
  color: white;
  border: none;
  width: 80%;
}

.dot {
  background-color: #1388ff;
  color: #1388ff;
  width: 4px;
  height: 14px;
  border-radius: 2px;
}

.eventContainer {
  display: flex;
  flex-direction: column;
  width: 94%;
  padding: 3%;
  font-size: $baseFontSize;

  .finishTag {
    font-size: $baseFontSize;
    color: lightgray;
    margin: 10px 0;
  }


  .miniLogo {
    font-size: $smallerFontSize;
    color: gray;

    img {
      width: 20px;
      height: 20px;
    }
  }


  .eventTitle {
    font-weight: normal;
  }

  .darkRed {
    color: darkred;
  }

  .eventImage {
    display: flex;
    padding: 10px 0;

    img {
      width: 100%;
      max-height: 150px;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}
</style>