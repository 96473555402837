<template>
  <div class="myHeader">
    <nut-row>
      <nut-col :span="6" class="leftCenter">
        <img class="avatar" :src="$store.state.user.avatar"/>
      </nut-col>
      <nut-col :span="14" style="padding: 10px 0 0 6px;">
        <nut-row>
          <span class="nickName">{{ $store.state.user.nickName }}</span>
        </nut-row>
        <nut-row>
          <span class="orgName">{{ $store.state.user.regionName }}-{{ $store.state.user.orgName }}</span>
        </nut-row>
      </nut-col>
      <nut-col :span="4" style="display:flex;padding-top: 20px;justify-content: flex-end">
        <router-link to="/setting" style="text-decoration: none">
          <nut-row class="balance-container">
            <svg-icon icon-class="right" class-name="mini"/>
          </nut-row>
        </router-link>
      </nut-col>
    </nut-row>
    <div class="funList">
      <nut-row class="funItem" @click="$router.push('/rank')">
        <nut-col :span="4" class="leftCenter">
          <img src="https://cdn.waityou.online/4965c559-604b-2296-c7ba-b75f3cb849da.png"/>
        </nut-col>
        <nut-col :span="16" class="leftCenter">
          <span>巡检数据</span>
        </nut-col>
        <nut-col :span="4" class="rightCenter">
          <nut-icon name="right" size="12" color="#9298A2"></nut-icon>
        </nut-col>
      </nut-row>
      <div class="divider"/>
      <nut-row class="funItem" @click="$router.push('/my/sign')">
        <nut-col :span="4" class="leftCenter">
          <img src="https://cdn.waityou.online/4897b04c-d783-966e-2e7c-30ab4b299c1f.png"/>
        </nut-col>
        <nut-col :span="16" class="leftCenter">
          <span>电子签名</span>
        </nut-col>
        <nut-col :span="4" class="rightCenter">
          <nut-icon name="right" size="12" color="#9298A2"></nut-icon>
        </nut-col>
      </nut-row>
      <div class="divider"/>
      <nut-row class="funItem" @click="$router.push('/shop/list?wch=true')">
        <nut-col :span="4" class="leftCenter">
          <img src="https://cdn.waityou.online/118d675d-d3ae-7086-a1fd-58726a348dce.png"/>
        </nut-col>
        <nut-col :span="16" class="leftCenter">
          <span>检查记录</span>
        </nut-col>
        <nut-col :span="4" class="rightCenter">
          <nut-icon name="right" size="12" color="#9298A2"></nut-icon>
        </nut-col>
      </nut-row>
      <div class="divider"/>
      <nut-row class="funItem" @click="$router.push('/messages')">
        <nut-col :span="4" class="leftCenter">
          <img src="https://cdn.waityou.online/3c3589b3-1285-6928-faef-45ff41d24adf.png"/>
        </nut-col>
        <nut-col :span="12" class="leftCenter">
          <span>消息</span>
        </nut-col>
        <nut-col :span="8" class="rightCenter">
          <span style="color:#BBBBBB;margin-right: 10px" v-if="unread>0">未读{{ unread }}</span>
          <nut-icon name="right" size="12" color="#9298A2"></nut-icon>
        </nut-col>
      </nut-row>
      <div class="divider"/>
      <nut-row class="funItem" @click="$router.push('/aboutus')">
        <nut-col :span="4" class="leftCenter">
          <img src="https://cdn.waityou.online/052a5bc0-14cf-a5d2-4f0e-3084a68eb199.png"/>
        </nut-col>
        <nut-col :span="16" class="leftCenter">
          <span>关于</span>
        </nut-col>
        <nut-col :span="4" class="rightCenter">
          <nut-icon name="right" size="12" color="#9298A2"></nut-icon>
        </nut-col>
      </nut-row>
    </div>
  </div>
  <TabBar :tab="1"/>
</template>

<script>
import TabBar from "@/components/TabBar";
import SvgIcon from "@/components/SvgIcon";
import api from "@/api"
import {Toast} from '@nutui/nutui';

export default {
  name: "MyView",
  components: {SvgIcon, TabBar},
  data() {
    return {
      avatar: 'https://cdn.waityou.online/1c89cdb8-080d-d70b-7760-7bc9f5d47547.jpeg',
      regionName: '...',
      orgName: '...',
      userName: '...',
      realName: '...',
      phone: '...',
      level: '',
      nextLevel: '',
      percent: 0,
      todayIncrease: 0,
      roleId: 0,
      balance: 0,
      unread: 0,
      oldMode: false,
    }
  },
  methods: {
    noAvailable() {
      Toast.warn('敬请期待');
    },
    logout() {
      api.logout('wechat').then(() => {
        this.$router.push({
          path: decodeURIComponent('/logout')
        });
      })
    },
    getUserInfo() {
      api.getUserInfo().then(ret => {
        this.$store.commit('updateUser',ret)
        this.avatar = ret.avatar;
        this.userName = ret.nickName;
        this.realName = ret.nickName;
        this.orgName = ret.orgName;
        this.regionName = ret.regionName;
        this.level = ret.level;
        this.nextLevel = ret.nextLevel;
        this.percent = ret.levelPercent;
        this.todayIncrease = ret.todayIncrease;
      })
    }
  },
  mounted() {
    this.getUserInfo();
    api.getUnReadMessageCount()
        .then(n => {
          this.unread = n;
        })
  }
}
</script>

<style lang="scss" scoped>
.myHeader {
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
  background-image: url("https://cdn.waityou.online/b6b2ee34-59fc-3365-f018-61228baa7630.png");
  padding: 50px 20px 0 20px;
  background-color: #F6F6F6;
  min-height: 100vh;

  .funList {
    margin-top: 30px;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    color: black;
    padding: 10px;
    font-size: $smallerFontSize;

    .funItem {
      padding: 15px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 24px;
      height: 24px;
    }

    .divider {
      background-color: #F4F0F0;
      height: 1px;
      width: 83%;
      margin-left: 16%;
    }
  }

  color: white;

  .nickName {

  }

  .orgName {
    font-weight: lighter;
    font-size: 14px;
  }

}

body {
  background-color: white;
}

.friendLink {
  width: 80px;
  height: 80px;
}

.balance-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .balance {
    text-decoration: none;
    text-underline: none;
    color: rgba(128, 128, 128, 0.47);
  }

}

.expHelp {
  color: white;
  font-size: $baseFontSize;
}


.serviceGridItem {
  text-align: center;
  font-size: $baseFontSize;
  color: black;
}

.bothCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  width: 60px;
  height: 60px;
  text-align: center;
  align-items: center;
  align-self: center;
  justify-items: center;
  border-radius: 10%;
}
</style>