<template>
  <div class="DataQueryViewContainer">
    <nut-row class="leftCenter">
      <div class="dot"/>
      <span class="title">商铺治理统计</span>
    </nut-row>
    <nut-row class="bothCenter" :gutter="10">
      <nut-col :span="12">
        <div class="numberBox bothCenter colFlex">
          <span class="number blue">{{ shop.RUNNING }}</span>
          <span class="name blue">在册商户</span>
        </div>
      </nut-col>
      <nut-col :span="12">
        <div class="numberBox bothCenter colFlex">
          <span class="number">{{ shop.CLOSED }}</span>
          <span class="name">注销商户</span>
        </div>
      </nut-col>
    </nut-row>

    <nut-row class="tab bothCenter">
      <nut-col :span="6" class="bothCenter">
        <div :class="tab == 0?'tabItem active':'tabItem'" @click="tab=0;currentReport=report.today;currentPolice=police.today;">
          <span>今日</span>
        </div>
      </nut-col>
      <nut-col :span="6" class="bothCenter">
        <div :class="tab == 1?'tabItem active':'tabItem'" @click="tab=1;currentReport=report.month;currentPolice=police.month;">
          <span>本月</span>
        </div>
      </nut-col>
      <nut-col :span="6" class="bothCenter">
        <div :class="tab == 2?'tabItem active':'tabItem'" @click="tab=2;currentReport=report.year;currentPolice=police.year;">
          <span>本年</span>
        </div>
      </nut-col>
      <nut-col :span="6" class="bothCenter">
        <div :class="tab == 3?'tabItem active':'tabItem'" @click="tab=3;currentReport=report.history;currentPolice=police.history;">
          <span>历史</span>
        </div>
      </nut-col>
    </nut-row>
    <nut-row class="leftCenter">
      <div class="dot"/>
      <span class="title">应急联动统计</span>
    </nut-row>
    <nut-row class="dataRow bothCenter" style="color:#1E427A" @click="$router.push('/police/event/list')">
      <nut-col :span="16" class="leftCenter">
        <span class="dataTitle">110联动</span>
      </nut-col>
      <nut-col :span="8" class="bothCenter">
        <span class="dataValue">{{ currentPolice['非警务类警情'] }}</span>
      </nut-col>
    </nut-row>
    <nut-row class="dataRow bothCenter" style="color:#F53F3F" @click="$router.push('/police/event/list?tab=1')">
      <nut-col :span="16" class="leftCenter">
        <span class="dataTitle">家暴警情</span>
      </nut-col>
      <nut-col :span="8" class="bothCenter">
        <span class="dataValue">{{ currentPolice['家暴警情']?currentPolice['家暴警情']:'0' }}</span>
      </nut-col>
    </nut-row>
    <nut-row class="dataRow bothCenter" style="color:#E48D1F" @click="$router.push('/police/event/list?tab=2')">
      <nut-col :span="16" class="leftCenter">
        <span class="dataTitle">反诈联动</span>
      </nut-col>
      <nut-col :span="8" class="bothCenter">
        <span class="dataValue">{{ currentPolice['反诈警情'] }}</span>
      </nut-col>
    </nut-row>
    <nut-row class="leftCenter">
      <div class="dot"/>
      <span class="title">巡查上报统计</span>
    </nut-row>
    <div class="dataRow bothCenter padding20">
      <nut-col :span="8" class="leftCenter colFlex"
               @click="$router.push('/report/list?tab=3')"
               style="color:#15C265">
        <span class="dataValue">{{ currentReport['无证劝离'] }}</span>
        <span class="subTitle">无证劝离</span>
      </nut-col>
      <nut-col :span="8" class="bothCenter colFlex"
               @click="$router.push('/report/list?tab=6')"
               style="color:#1E427A">
        <span class="dataValue">{{ currentReport['路面巡查'] }}</span>
        <span class="subTitle">路面巡查</span>
      </nut-col>
      <nut-col :span="8" class="bothCenter colFlex"
               @click="$router.push('/report/list?tab=4')"
               style="color:#E48D1F">
        <span class="dataValue">{{ currentReport['出店经营'] }}</span>
        <span class="subTitle">出店经营</span>
      </nut-col>
    </div>
    <div class="dataRow bothCenter padding20">
<!--      <nut-col :span="8" class="leftCenter colFlex"-->
<!--               @click="$router.push('/report/list?tab=1')"-->
<!--               style="color:#15C265">-->
<!--        <span class="dataValue">{{ currentReport['单车清理'] }}</span>-->
<!--        <span class="subTitle">单车清理</span>-->
<!--      </nut-col>-->
      <nut-col :span="12" class="bothCenter colFlex"
               @click="$router.push('/report/list?tab=2')"
               style="color:#1E427A">
        <span class="dataValue">{{ currentReport['护岗'] }}</span>
        <span class="subTitle">护岗护校</span>
      </nut-col>
      <nut-col :span="12" class="bothCenter colFlex"
               @click="$router.push('/report/list?tab=5')"
               style="color:#E48D1F">
        <span class="dataValue">{{ currentReport['其他'] }}</span>
        <span class="subTitle">其他</span>
      </nut-col>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "DataQueryView",
  data() {
    return {
      colors:['#1E427A','#F53F3F','#E48D1F'],
      tab: 0,
      shop:{},
      police:{},
      report:{},
      currentReport:{},
      currentPolice:{},
    }
  },
  methods: {
    init() {
      api.getDataSummary()
          .then(ret=>{
            this.shop = ret.shop;
            this.police = ret.police;
            this.report = ret.report;

            this.currentReport = this.report.today;
            this.currentPolice = this.police.today;
          })
    }
  },
  mounted() {
    this.init();
    this.$store.commit('clearCachePage','BatteryPoliceEventListView')
    setTimeout(()=>{
      this.$store.commit('addCachePage','BatteryPoliceEventListView')
    },100)
  }
}
</script>

<style lang="scss" scoped>
.DataQueryViewContainer {
  padding: 16px;

  .padding20{
    padding: 20px;
    border: #eae9e9 1px solid;
  }
  .dataRow {
    margin: 10px 0;
    background-color: #F2F2F2;
    height: 62px;
    border-radius: 4px;

    .dataTitle {
      font-size: $biggerFontSize;
      padding: 0 10px;
    }

    .dataValue {
      font-size: 28px;
      font-weight: bold;
    }
    .subTitle{
      font-size: $baseFontSize;
    }
  }

  .title {
    font-size: $baseFontSize;
    padding: 0 5px;
    margin: 10px 0;
  }

  .dot {
    width: 4px;
    height: 14px;
    line-height: 20px;
    border-radius: 2px;
    background-color: rgba(22, 119, 254, 1);
    text-align: center;
  }

  .tab {
    background-color: #EEEEEF;
    border-radius: 4px;
    padding: 2px;
    display: flex;
    margin-top: 10px;

    .active {
      background-color: white;
    }

    .tabItem {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 32px;
      border-radius: 4px;


    }
  }

  .numberBox {
    border-radius: 5px;
    border: #DDDDDD 1px solid;
    height: 30vw;

    .blue {
      color: #0076FD;
    }


    .number {
      font-size: 28px;
    }

    .name {
      font-size: $baseFontSize;
    }
  }
}
</style>