<template>
  <div class="IceDatePickerContainer colFlex bothCenter">
    <div v-for="year in dateList" :key="year.title">
      <span class="year bothCenter">{{ year.title }}年</span>
      <div v-for="month in year.list" :key="month.id"
           @click="chooseMonth(month)"
           :class="selected == month.id?'bothCenter active':'bothCenter'">
        <span class="month">{{ month.name }}</span>
        <span class="monthSuffix">月</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IceDatePicker",
  props: {
    year: {
      type: Number,
      default: 0
    },
    month: {
      type: Number,
      default: 0
    },
  },
  watch: {
    year() {
      this.init()
    }
  },
  data() {
    return {
      dateList: [],
      selected: 0,
    }
  },
  methods: {
    chooseMonth(month) {
      this.selected = month.id;
      this.$emit('month', month);
    },
    init(){
      this.selected = this.year * 100 + this.month;
      let curYear = new Date().getFullYear();
      let dl = []
      for (let year of [curYear, curYear - 1, curYear - 2]) {
        let months = []
        let m = 11;
        if (year == curYear) {
          m = new Date().getMonth();
        }
        for (; m >= 0; m--) {
          months.push({
            'name': (m + 1),
            'id': year * 100 + (m+1),
            'year': year,
            'month': m + 1,
          })
        }
        dl.push(
            {
              'title': year,
              'list': months
            }
        )
      }
      this.dateList = dl;
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.IceDatePickerContainer {
  font-size: $smallerFontSize;

  .active {
    color: #1677FE;
    font-weight: bold;
  }

  .year {
  }

  .month {
    padding: 10px 0;
    font-size: $biggerFontSize;
  }

  .monthSuffix {
    font-size: $miniFontSize;
  }
}
</style>