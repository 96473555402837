<template>
  <nut-row class="loading">
    <h3>登录中...</h3>
    <nut-icon name="loading" size="large"></nut-icon>
  </nut-row>
</template>


<script>
import {Toast} from '@nutui/nutui';
import api from "@/api";
import {mapMutations} from "vuex";

export default {
  name: "LoginView",
  data() {
    return {
      accountLoginType: false,
      account: '',
      password: '',
      captcha: '',
      fastLoginType: false,
      isWxEnv: false,
      captchaToken: '376233414898836402b069bdefault',
      wxBindToken: null,
      fastToken: this.$route.query.fastToken,
    }
  },
  watch: {
    accountLoginType(b) {
      if (!b) {
        return
      }
      this.refreshCaptcha();
    }
  },
  methods: {
    ...mapMutations(['updateUser', 'updateEnv']),
    wxLogin() {
      Toast.loading('自动登录中...', {
        duration: 5000
      });
      let url = this.$route.query.redirect;
      let code = this.$route.query.code;
      if (code) {
        api.getOpenApiToken(code).then(ret => {
          Toast.hide();
          localStorage.setItem('access_token', ret);
          // 取到原页面路径
          let url = this.$route.query.redirect;
          // 跳转回原页面
          this.$router.push({
            path: decodeURIComponent(url ? url : '/')
          });
        });
      } else {
        api.getWechatRedirectUrl(url).then(ret => {
          window.location.href = ret;
        })
      }
    },
  },
  mounted() {
    document.title = '小河ZHI街登录';
    var userAgent = window.navigator.userAgent.toLowerCase();
    console.log(userAgent);
    if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
      this.isWxEnv = true;
    } else {
      //非自动化环境
      this.isWxEnv = false;
      this.accountLoginType = true;
    }
    if (this.isWxEnv && !this.accountLoginType) {
      this.wxLogin();
    } else if (this.fastToken != null) {
      localStorage.setItem('access_token', this.fastToken);
      // 取到原页面路径
      let url = this.$route.query.redirect;
      // 跳转回原页面
      this.$router.replace(url)
    } else {
      Toast.fail("请使用微信扫码")
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30%;
}

.loginBtn {
  font-size: $baseFontSize;
}

.login-input {
  border: 1px solid lightgray;
  width: 80%;
  margin-left: 10%;
  border-radius: 20px;
  padding: 5px 10px;
  margin-bottom: 10px;
  font-size: $baseFontSize;
}

.login-input-captcha {
  margin-left: auto;
}

.nut-row {
  overflow: hidden;

  &:not(:last-child) .nut-col {
    margin-bottom: 15px;
  }

  margin-bottom: 15px;
}

.flex-content {
  line-height: 40px;
  text-align: center;
  border: 0;
  font-size: $baseFontSize;

  p {
    padding: 0;
    margin: 0;
    line-height: 25px;
    font-size: $baseFontSize;
    color: #005FF5;
  }
}
</style>