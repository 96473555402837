<template>
  <div class="ReportEventDetailViewContainer">
    <EventReportDetailView :event="event" v-if="event && event.eventTypeId != 5"/>
    <OtherEventReportDetailView :event="event" v-else-if="event && event.eventTypeId == 5"/>
  </div>
</template>

<script>
import api from "@/api";
import EventReportDetailView from "./EventReportDetailView"
import OtherEventReportDetailView from "@/views/event/OtherEventReportDetailView";
export default {
  name: "ReportEventDetailView",
  components: {OtherEventReportDetailView, EventReportDetailView},
  data() {
    return {
      eventId: this.$route.params.eventId,
      event:null,
    }
  },
  methods: {
    init() {
      api.getReportDetail(this.eventId)
          .then(event => {
            this.event = event;
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.ReportEventDetailViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
}
</style>