<template>
  <div class="shopDetailContainer">
    <div :style="{width:'100vw',height:'75vw',backgroundImage:`url(${shop.shopImage})`,
          backgroundRepeat:'no-repeat',backgroundPosition:'center',backgroundSize:'cover',
          display:'relative'}">
      <CustomOssUploader :key="shopImageVersion"
                         style="position:absolute;top: 10px;right: 10px;"
                         @onUploadedFiles="onUploadShopImage">
        <img style="width: 40px;height: 40px;"
             src="https://cdn.waityou.online/d8cab5fd-9a1e-2bf6-c628-3740cfe50542.svg"/>
      </CustomOssUploader>
    </div>
    <div style="margin-top: -30px">
      <div class="shopDetailInfo">
        <nut-row class="bothCenter">
          <nut-col :span="18" class="shopTag leftCenter">
            <ShopTypeTag :name="shop.shopTypeName"/>
            <span>{{ shop.communityName }}</span>
          </nut-col>
          <nut-col :span="6" class="rightCenter" @click="$router.push('/shop/info/'+shop.id)">
            <span class="shopDetailBtn miniFont">商铺详情</span>
            <img src="https://cdn.waityou.online/d48a73a8-29e6-5691-2906-b37ebdcca3ce.png" style="width: 12px"/>
          </nut-col>
        </nut-row>
        <nut-row>
          <span class="lighterText miniFont">{{ shop.shopName }}·{{ shop.address }}</span>
        </nut-row>
        <nut-row class="bothCenter">
          <nut-col :span="16" class="leftCenter">
            <span class="lighterText miniFont">{{ shop.contactName }}·{{ shop.contactInfo }}</span>
          </nut-col>
          <nut-col :span="8" class="rightCenter">
            <router-link :to="'/shop/check/history/'+shopId" style="text-decoration: none">
              <span class="checkHistory miniFont">检查历史</span>
            </router-link>
          </nut-col>
        </nut-row>
        <nut-row class="checkerInfo bothCenter">
          <nut-col :span="16" class="leftCenter">
            <img src="https://cdn.waityou.online/59fad929-bb7a-4cb2-42c9-bebf986011a0.png" style="width: 14px"/>
            <span class="smallFont">检查人员</span>
            <template v-for="(checker,i) in current" :key="i">
              <img :src="checker.avatar" style="width: 20px"/>
              <span class="smallFont">{{ checker.nickName }}</span>
            </template>
          </nut-col>
          <nut-col :span="8" class="rightCenter">
            <nut-button class="addCheckRecord miniFont" @click="addCheckerVisible=true">添加</nut-button>
          </nut-col>
        </nut-row>
      </div>
      <nut-grid column-num="2" class="boxContainers">
        <nut-grid-item
            v-for="(m,i) in matters" :key="m.id" :class="i%2===0?'boxContainerL':'boxContainerR'">
          <ShopCheckBox :data="m"
                        @click="$router.push('/shop/check/detail/'+m.id+'/'+shopId)"/>
        </nut-grid-item>
      </nut-grid>
    </div>
  </div>
  <div class="submitBtnContainer bothCenter">
    <nut-button class="submitBtn" @click="submitCheckReport">提交</nut-button>
  </div>
  <nut-popup v-model:visible="addCheckerVisible" @close="addCheckerVisible=false" position="bottom" round>
    <AddCheckerView :current="current" :more="moreCheckers"
                    @addChecker="addChecker"
                    @deleteChecker="deleteChecker"
                    @close="addCheckerVisible=false"/>
  </nut-popup>
<!--  <nut-dialog-->
<!--      title="签名"-->
<!--      @close="signVisible=false"-->
<!--      v-model:visible="signVisible"-->
<!--      @ok="sign2img"-->
<!--  >-->
<!--    <CheckerSignView-->
<!--        v-if="signVisible"-->
<!--        @useMySign="afterSign"-->
<!--        ref="signPad"-->
<!--        />-->
<!--  </nut-dialog>-->

  <nut-popup v-model:visible="signVisible" style="width: 100vw;height: 100vh;background: white">
    <FullScreenSign v-if="signVisible" @ok="afterSign" @cancel="signVisible=false"/>
  </nut-popup>

</template>

<script>
import ShopCheckBox from "@/components/shop/ShopCheckBox";
import AddCheckerView from "@/components/shop/AddCheckerView";
import CheckerSignView from "@/components/sign/CheckerSignView";
import api from "@/api";
import CustomOssUploader from "@/components/CustomOssUploader"
import {Toast} from "@nutui/nutui";
import ShopTypeTag from "@/components/ShopTypeTag"
import FullScreenSign from "@/components/sign/FullScreenSign";
// import VConsole from 'vconsole';
export default {
  name: "ShopDetailView",
  // eslint-disable-next-line vue/no-unused-components
  components: {FullScreenSign, CheckerSignView, AddCheckerView, ShopCheckBox, CustomOssUploader,ShopTypeTag},
  data() {
    return {
      shopId: this.$route.params.shopId,
      signVisible: false,
      addCheckerVisible: false,
      shop: {},
      matters: [],
      shopImageVersion: 0,
      current: [],
      moreCheckers: [],
    }
  },
  methods: {
    submitCheckReport(){
      this.signVisible=true;
      Toast.warn('请旋转屏幕并签名',{
        duration:1000,
        icon:'https://cdn.waityou.online/99582272-5da7-c63e-32d0-07dfdcd20a45.svg',
      })
    },
    sign2img(){
      Toast.loading('请稍等')
      api.signature2image({
        'data': this.$refs.signPad.saveSignature()
      }).then(url => {
        this.afterSign(url)
      })
    },
    afterSign(signImage) {
      console.log('afterSign')
      Toast.loading('请稍等')
      api.submitCheckReport({
        shopId:this.shopId,
        checkerSign:signImage,
      }).then(ret => {
        this.$router.replace('/shop/check/result/' + ret.id)
      }).finally(()=>{
        Toast.hide()
      })
    },
    addChecker(userId) {
      api.addShopChecker({
        shopId: this.shopId,
        userId: userId,
      }).then(() => {
        this.init()
      })
    },
    deleteChecker(checker){
      api.removeChecker({
        shopId:this.shopId,
        userId:checker.id
      }).then(() => {
        this.init()
      })
    },
    onUploadShopImage(imgs) {
      api.updateShopImage({
        id: this.shopId,
        shopImage: imgs[0]
      }).then(() => {
        this.init()
        this.shopImageVersion++;
      })
    },
    getShopDetail() {
      api.getShopDetail(this.shopId)
          .then(ret => {
            this.shop = ret.shop;
          })
    },
    init() {
      this.getShopDetail();
      api.getShopCheckMatters({
        shopId: this.shopId,
      }).then(ret => {
        this.matters = ret;
      });
      api.getShopCheckers(this.shopId)
          .then(ret => {
            this.current = ret.current;
            this.moreCheckers = ret.more;
          })
    }
  },
  mounted() {
    this.init()
    // eslint-disable-next-line no-unused-vars
    // const vConsole = new VConsole();
  }
}
</script>
<style>
.nut-grid-item__content--center {
  border-radius: 10px;
}

.nut-grid-item__content {
  padding: 0 8px !important;
}
</style>
<style lang="scss" scoped>
.submitBtnContainer {
  background-color: white;
  position: fixed;
  width: 100%;
  bottom: 0;

  .submitBtn {
    background-color: rgba(22, 119, 254, 1);
    color: rgba(255, 255, 255, 1);
    border: none;
    width: 94%;
    margin: 10px;
  }
}

.shopDetailContainer {
  width: 100%;
  min-height: 100vh;
  background-color: #F6F6F6;
  padding-bottom: 100px;


  .boxContainers {
    padding: 5px 10px;

    .boxContainerL {
      padding: 4px 4px 4px 0;
    }

    .boxContainerR {
      padding: 4px 0 4px 4px;
    }
  }


  .lighterText {
    color: #999999;
  }

  .checkHistory {
    color: #1677FE;
  }

  .shopDetailInfo {
    background-color: #F2F7FF;
    //background-color: grey;
    padding: 10px;
    margin: 0 10px;
    border-radius: 15px 15px 10px 10px;

    .checkerInfo {
      margin: 15px 0;
      color: #666666;

      .addCheckRecord {
        background-color: rgba(22, 119, 254, 1);
        color: rgba(255, 255, 255, 1);
        font-weight: normal;
        height: 24px;
        border: none;
      }

      span {
        padding: 0 5px;
      }
    }

    .shopDetailBtn {
      color: #666666;
    }

    .shopTag {
      font-weight: bold;

      span {
        padding-left: 5px;
      }

      img {
        height: 18px;
      }

    }
  }
}
</style>