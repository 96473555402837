<template>
  <div class="ShopCheckFinishViewContainer bothCenter colFlex">
    <img src="https://cdn.waityou.online/16f6e5e3-02a5-cc46-fc62-8af9f38aa17c.png"/>
    <span class="title">检查完成，还差最后一步啦！</span>
    <span class="subTitle">请主体/商铺人员扫码签收</span>
    <QrCode :text="url"/>
    <nut-row class="bothCenter" style="margin-top: 40px">
      <nut-col :span="12" class="bothCenter">
        <nut-button class="btn1" @click="$router.push('/shop/check/report/'+this.reportId)">查看详情</nut-button>
      </nut-col>
      <nut-col :span="12" class="bothCenter">
        <nut-button class="btn2" @click="$router.push('/')">返回首页</nut-button>
      </nut-col>
    </nut-row>
  </div>
</template>

<script>
import QrCode from "@/components/QrCode";

export default {
  name: "ShopCheckFinishView",
  components: {QrCode},
  data() {
    return {
      reportId: this.$route.params.reportId,
      url: '',
    }
  },
  mounted() {
    this.url = window.location.protocol + '//' + window.location.host +  '/#/nonLogin?redirect=/open/receive/' + this.reportId;
  }
}
</script>

<style lang="scss" scoped>
.ShopCheckFinishViewContainer {
  height: 100vh;

  .btn1 {
    border-radius: 22px;
    background-color: #1677FE;
    color: white;
    border: none;
    width: 70%;
    font-size: 15px;
  }

  .btn2 {
    width: 70%;
    border-radius: 22px;
    color: #1677FE;
    border: #1677FE 1px solid;
  }

  img {
    width: 120px;
  }

  .title {
    margin-top: 40px;
    font-size: $biggerFontSize;
  }

  .subTitle {
    font-size: $smallerFontSize;
    color: #666666;
    font-weight: lighter;
    margin: 10px 0;
  }
}
</style>