<template>
  <div class="ClockCalendarContainer">
    <div class="colorHead"></div>
    <div class="header bothCenter">
      <nut-col :span="12" class="leftCenter">
        <span class="title">执勤打卡</span>
      </nut-col>
      <nut-col :span="12" class="rightCenter">
        <img class="clockIcon" src="https://cdn.waityou.online/1980a6ee-592f-ba83-4e65-591a89fa33b3.png"/>
      </nut-col>
    </div>
    <nut-row class="bothCenter">
      <nut-col :span="3" class="bothCenter">
        <span>日</span>
      </nut-col>
      <nut-col :span="3" class="bothCenter">
        <span>一</span>
      </nut-col>
      <nut-col :span="3" class="bothCenter">
        <span>二</span>
      </nut-col>
      <nut-col :span="3" class="bothCenter">
        <span>三</span>
      </nut-col>
      <nut-col :span="3" class="bothCenter">
        <span>四</span>
      </nut-col>
      <nut-col :span="3" class="bothCenter">
        <span>五</span>
      </nut-col>
      <nut-col :span="3" class="bothCenter">
        <span>六</span>
      </nut-col>
    </nut-row>
    <div style="margin-top: 10px"></div>
    <nut-row class="bothCenter miniFont" v-for="(week,wi) in date" :key="wi">
      <nut-col :span="3" v-for="(day,di) in week" :key="day" class="bothCenter">
        <div :class="today == day?'today bothCenter':'weekday flexCol'">
          <span>{{ day.substring(6) }}</span>
          <div v-if="today != day && clock[wi][di]" class="blueDot"/>
        </div>
      </nut-col>
    </nut-row>
    <div class="bothCenter" style="padding:8px 16px">
      <nut-col :span="16" class="leftCenter">
        <span>本月已打卡&nbsp;</span>
        <span style="color:#0076FD">{{ clockCnt }}</span>
        <span>&nbsp;天</span>
      </nut-col>
      <nut-col :span="8" class="rightCenter">
        <div class="blueDot"/>
        <span>已打卡</span>
      </nut-col>
    </div>
    <nut-row style="padding:8px 16px">
      <span style="color:#0076FD" @click="$router.push('/clock/history')">查看记录</span>
    </nut-row>
  </div>
</template>

<script>
import api from "@/api"
import {Toast} from "@nutui/nutui";

export default {
  name: "ClockCalendar",
  data() {
    return {
      date: [],
      today: null,
      clock: [],
      clockCnt: 0,
      address: true,
    }
  },
  methods: {
    init() {
      api.getClockInfo()
          .then(ret => {
            this.today = ret.today;
            this.date = ret.month;
            this.clock = ret.clock;
            this.address = ret.address;
            this.clockCnt = 0;
            for (let w of this.clock) {
              for (let d of w) {
                this.clockCnt += d ? 1 : 0;
              }
            }
            if (!this.address) {
              this.fixLocation()
            }
          })
    },

    fixLocation() {
      Toast.loading('定位中请稍后...')
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
              Toast.hide()
              // 成功获取定位信息
              let latitude = position.coords.latitude; // 纬度
              let longitude = position.coords.longitude; // 经度
              let accuracy = position.coords.accuracy; // 定位精度
              this.fixSignInAddress(true, longitude, latitude, accuracy)
            },
            (error) => {
              Toast.hide()
              // 获取定位信息失败
              switch (error.code) {
                case error.PERMISSION_DENIED:
                  Toast.fail('您拒绝了程序的定位要求')
                  break;
                default:
                  Toast.fail('定位时遇到未知错误，无法获取您的定位信息')
                  break;
              }
            }
        );
      } else {
        Toast.hide()
        Toast.fail('您的浏览器不支持定位无法打卡')
      }
    },
    fixSignInAddress(result, longitude, latitude, accuracy) {
      if (!result) {
        return
      }
      api.updateClockInAddress({
        'longitude': longitude,
        'latitude': latitude,
        'accuracy': accuracy,
      }).then(() => {
        Toast.success('定位上报成功')
      })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.ClockCalendarContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 80%;
  padding-bottom: 10px;
  border-radius: 6px;
  font-size: $miniFontSize;

  .blueDot {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin: 0 5px;
    line-height: 20px;
    background-color: rgba(0, 118, 253, 1);
  }

  .weekday {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .today {
    color: white;
    background-color: rgba(0, 118, 253, 1);
    width: 18px;
    height: 18px;
    border-radius: 20px;
  }

  .colorHead {
    border-radius: 6px 6px 0 0;
    background: linear-gradient(180deg, rgba(165, 200, 240, 1) 16%, rgba(255, 255, 255, 0) 100%);
    height: 20px;
    width: 100%;
  }

  .header {
    margin-top: -10px;
    padding: 0 16px;

    .clockIcon {
      width: 40px;
      height: 40px;
    }

    .title {
      font-size: $baseFontSize;
    }
  }
}
</style>