<template>
  <div class="indexContainer">
    <nut-row class="indexHead leftCenter">
      <span class="logoTitle baseFont">小河<span class="biggerFont" style="color: #0045ff">ZHI</span>街</span>
      <span style="font-weight: bold;font-size: 20px">&nbsp;·&nbsp;</span>
      <svg-icon icon-class="cloudSun" v-if="weather == 0"/>
      <img src="https://cdn.waityou.online/ea513cc4-cfb1-4c13-d6c5-95b3ea53755d.svg"
           v-if="weather == 1" style="width: 16px">
      <span class="temperature smallFont">{{ temperature }}°</span>
    </nut-row>
    <nut-row>
      <nut-swiper :pagination-visible="true"
                  pagination-color="#426543"
                  :is-prevent-default="false"
                  style="height: 136px;border-radius: 10px"
                  auto-play="3000">
        <nut-swiper-item v-for="banner in $store.state.banners" :key="banner.id" @click="onBannerClick(banner)">
          <img :src="banner.image" alt=""/>
        </nut-swiper-item>
      </nut-swiper>
    </nut-row>

    <div class="wbg wd100 bothCenter menuItem vpd12 mt16" style="border-radius: 16px 16px 0 0">
      <div style="width: 25%" class="colFlex bothCenter miniFont">
        <router-link to="/shop/list">
          <img src="https://cdn.waityou.online/896f4dc1-ace0-11da-f662-d968cba9796f.png" class="iconImage"/>
        </router-link>
        <span>商铺治理</span>
      </div>

      <div style="width: 25%" class="colFlex bothCenter miniFont">
        <router-link to="/chat/list">
          <img src="https://cdn.waityou.online/8787633e-6792-b647-19e4-4cb23fbcb632.png" class="iconImage"/>
        </router-link>
        <span>警情联动</span>
      </div>
      <div style="width: 25%" class="colFlex bothCenter miniFont" @click="clockVisible=true">
        <router-link to="/">
          <img src="https://cdn.waityou.online/7834c980-1601-3b29-f2a6-bf9b13090690.png" class="iconImage"/>
        </router-link>
        <span>执勤打卡</span>
      </div>
      <div style="width: 25%" class="colFlex bothCenter miniFont">
        <router-link to="/report/type/list">
          <img src="https://cdn.waityou.online/74cbcd08-7d93-80d8-e696-1049b1b79a9f.png" class="iconImage"/>
        </router-link>
        <span>事件上报</span>
      </div>
    </div>
    <div class="wbg wd100 bothCenter menuItem" style="border-radius: 0 0 16px 16px;padding-bottom: 12px; ">
      <div style="width: 25%" class="colFlex bothCenter miniFont">
        <router-link to="/police/event/list">
          <img src="https://cdn.waityou.online/190c2f00-431f-6d6c-3c6f-f2ce6c86de66.png" class="iconImage"/>
        </router-link>
        <span>警情列表</span>
      </div>
      <div style="width: 25%" class="colFlex bothCenter miniFont">
        <router-link to="/data/query">
          <img src="https://cdn.waityou.online/62ee1aca-5769-0781-8582-eab31d09b420.png" class="iconImage"/>
        </router-link>
        <span>数据查询</span>
      </div>
      <div style="width: 25%" class="colFlex bothCenter miniFont">
        <template v-if="readPriorities.includes('DUTY_CHECK')">
          <router-link to="/duty/check">
            <img src="https://cdn.waityou.online/a3ab9ae1-d57c-6477-53ac-05af4e4a7178.png" class="iconImage"/>
          </router-link>
          <span>人员点验</span>
        </template>
        <template v-else>
          <img src="https://cdn.waityou.online/a3ab9ae1-d57c-6477-53ac-05af4e4a7178.png"
               @click="alertInfo('未授权')"
               class="iconImage"/>
          <span>人员点验</span>
        </template>
      </div>
      <div style="width: 25%" class="colFlex bothCenter miniFont">
        <router-link to="/police/event/rank">
          <img src="https://cdn.waityou.online/62ee1aca-5769-0781-8582-eab31d09b420.png" class="iconImage"/>
        </router-link>
        <span>量化榜单</span>
      </div>
    </div>

    <nut-row class="bothCenter">
      <nut-col :span="21" class="leftCenter">
        <nut-tabs v-model="indexTab"
                  style="width: 100%"
                  @change="onEventListTabChange"
                  type="smile" background="transparent">
          <nut-tabpane v-for="(t,ti) in tabs" :key="ti" :title="t.name"
                       class="smallFont" style="width:0;height: 0;padding: 0;margin:0"/>
        </nut-tabs>
      </nut-col>
      <nut-col :span="3" class="bothCenter">
        <nut-popover v-model:visible="moreTab" location="left">
          <template #reference>
            <img src="https://cdn.waityou.online/16f101d5-9ba3-811a-04d1-72a5f6d66abb.png"
                 style="width: 18px;"/>
          </template>
          <template #content>
            <div class="pd12 colFlex">
              <div style="width: 50px">
                <span>只看我</span>
                <nut-switch v-model="onlyMeFilter"
                            @change="onEventListTabChange"
                            active-text="开" inactive-text="关"/>
              </div>
              <div style="width: 50px" v-if="indexTab == 3">
                <span>仅异常</span>
                <nut-switch v-model="onlyProblemFilter"
                            @change="onEventListTabChange"
                            active-text="开" inactive-text="关"/>
              </div>
            </div>
          </template>
        </nut-popover>
      </nut-col>
    </nut-row>

    <InfiniteLoadingList :query-id="2" :init-query="eventListInitQuery">
      <template v-slot="data">
        <div class="opd12" v-if="data.key > 0">
          <div class="divider"/>
        </div>
        <IndexEmActivity v-if="data.data.type == 0" :data="data.data"
                         @click="$router.push('/police/event/detail/'+data.data.eventId)"/>
        <IndexInfoActivity v-if="data.data.type ==1" :data="data.data"
                           @click="$router.push('/shop/check/report/'+data.data.eventId)"/>
        <IndexWarnActivity v-if="data.data.type == 2" :data="data.data"
                           @click="$router.push('/report/event/detail/'+data.data.eventId)"/>
      </template>
    </InfiniteLoadingList>
    <div style="height: 12vh"/>
  </div>
  <nut-overlay v-model:visible="clockVisible" class="bothCenter">
    <ClockCalendar v-if="clockVisible"/>
  </nut-overlay>

  <TabBar :tab="0"/>
</template>

<script>
import TabBar from "@/components/TabBar";
import api from "@/api";
import {Toast} from "@nutui/nutui";
import SvgIcon from "@/components/SvgIcon";
import IndexEmActivity from "@/components/index/IndexEmActivity";
import IndexInfoActivity from "@/components/index/IndexInfoActivity";
import IndexWarnActivity from "@/components/index/IndexWarnActivity";
import ClockCalendar from "@/components/index/ClockCalendar";
import InfiniteLoadingList from "@/components/InfiniteLoadingList";

export default {
  name: "IndexView",
  components: {
    InfiniteLoadingList,
    ClockCalendar,
    IndexEmActivity,
    IndexInfoActivity,
    IndexWarnActivity,
    SvgIcon,
    TabBar
  },
  data() {
    return {
      onlyMeFilter: false,
      onlyProblemFilter: false,
      moreTab: false,
      temperature: '-',
      weather:0,
      tabs: [
        {
          name: '全部',
          queryTypeId: null,
        },
        {
          name: '警情动态',
          queryTypeId: 0,
        },
        {
          name: '上报动态',
          queryTypeId: 1,
        },
        {
          name: '巡查动态',
          queryTypeId: 2,
        },
      ],
      eventListInitQuery: {
        type: null,
      },
      clockVisible: false,
      indexTab: 0,
      readPriorities: [],
      scrollHeight: 0,
    }
  },
  // watch: {
  //   $route() {
  //     window.location.reload();
  //   },
  // },
  methods: {
    alertInfo(m){
      Toast.fail(m)
    },
    onBannerClick(b) {
      if (b.data) {
        Toast.loading('请稍等...')
        window.location.href = b.data;
      }
    },
    onEventListTabChange() {
      this.eventListInitQuery = {
        type: this.tabs[this.indexTab].queryTypeId,
        onlyMe: this.onlyMeFilter,
        onlyProblem: this.onlyProblemFilter,
      };
    },
    onClickClock() {
      this.clockVisible = true;
    },
    noAvailable() {
      Toast.warn('敬请期待');
    },
    goTop() {
      // document.documentElement.scrollTop = 0;
      window.scrollTo(0, 0)
    },
    onScrollChange(height) {
      this.scrollHeight = height;
    },
    renderPage() {
      document.title = '小河ZHI街';
      api.getBanners().then(ret => {
        this.$store.commit('updateBanners',ret)
      });
      api.getReadPriorities()
          .then(list => {
            this.readPriorities = list;
          })
      api.getWeather()
          .then(ret => {
            this.temperature = ret.temperature;
            if(ret.weather.includes('雨')){
              this.weather = 1;
            }
          })
    }
  },
  mounted() {
    this.renderPage();
    this.$store.commit('clearCachePage','ShopListView')
    setTimeout(()=>{
      this.$store.commit('addCachePage','ShopListView')
    },200)
  }
}
</script>
<style>
.nut-tabs__titles-item.active {
  color: #101010 !important;
  padding: 0 !important;
}

.nut-tabs__titles-item {
  color: #666666 !important;
  padding: 0 !important;
}
</style>
<style scoped lang="scss">
.indexContainer {
  padding: 10px;
  display: flex;
  background: linear-gradient(to bottom, rgba(127, 186, 250, 0.1), rgba(127, 186, 250, 0.02));
  //background-color: #005FF5;
  min-height: 100vh;
  flex-direction: column;

  .menuItem {
    img {
      width: 36px;
    }
  }

  .indexHead {
    margin-bottom: 10px;
  }

  .logoTitle {
    color: #1388ff;
    font-weight: bold;
    font-style: italic;
  }

  .temperature {
    font-weight: lighter;
    margin-left: 2px;
  }
}

.emClassItem {
  .nut-grid-item__content {
    padding: 5px 10px;
  }
}


.emClassRoom {
  margin-top: 10px;
  display: flex;
  font-size: $baseFontSize;

  .classRoomTitle {
    width: 100%;
    padding: 10px;
    background-color: white;
  }
}


.techState {
  display: flex;
  font-size: $baseFontSize;
  align-items: center;
  justify-content: center;

  img {
    width: 90%;
    padding: 5%;
    border-radius: 25px;
  }

  span {
    padding: 10px;
  }

  .techState-left {
    display: flex;
  }

  .techState-right {
    display: flex;
    font-size: $smallerFontSize;
    color: rgba(128, 128, 128, 0.47);

    span {
      padding: 0;
    }

    justify-content: flex-end;
    align-items: center;
  }
}

.indexGrid {
  border-radius: 10px;

  .indexGridBox {
  }
}

.iconImage {
  width: 36px;
  height: 36px;
}

.indexGridItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.indexGridBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;

  img {
    width: 30px;
    height: 30px;
  }

  span {
    margin-top: 5px;
    font-size: $miniFontSize;
  }
}

.linkClass {
  text-decoration: none;
}

.nut-swiper-item {
  line-height: 112px;

  img {
    width: 100%;
    height: 100%;
  }
}

.headGridItem {
  font-size: 12px;
  text-align: center;
  color: gray;
}

.bothCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>