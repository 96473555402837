<template>
  <nut-row class="LeftMessageContainer">
    <nut-col :span="4" class="avatar">
      <img :src="data.creatorAvtar"/>
    </nut-col>
    <nut-col :span="20" class="leftContent">
      <nut-row class="leftCenter">
        <span class="messageOwnerName">{{ data.creatorName }}</span>
        <span class="minFont" style="color: black">&nbsp;{{data.createdTime.substring(5,19)}}</span>
      </nut-row>
      <slot/>
      <nut-row v-if="data.refEventId" class="leftCenter">
        <span class="miniFont" style="color:#999999;">关联警情：{{data.refEventNo}}</span>
      </nut-row>
    </nut-col>
  </nut-row>
</template>

<script>
export default {
  name: "LeftMessage",
  props: {
    data: {
      type: Object,
      default: ()=>{
        return {

        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.LeftMessageContainer {
  display: flex;
  .leftContent{
    display: flex;
    flex-direction: column;

    .messageOwnerName{
      color:#666666;
      font-size: $miniFontSize;
    }
  }
  .avatar{
    img{
      width: 48px;
      height: 48px;
      border-radius: 5px;
    }
  }
}
</style>