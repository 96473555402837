<template>
  <nut-row class="dividerContainer">
    <nut-col :span="1">
      <div class="leftDot"></div>
    </nut-col>
    <nut-col :span="22">
      <nut-divider dashed class="divider"/>
    </nut-col>
    <nut-col :span="1" style="display: flex;justify-content: flex-end">
      <div class="rightDot"></div>
    </nut-col>
  </nut-row>
</template>

<script>
export default {
  name: "ShopDivider"
}
</script>

<style lang="scss" scoped>
.dividerContainer {
  display: flex;
  align-items: center;

  .divider {
    margin: 0;
    width: 100%;
  }

  .leftDot {
    width: 8px;
    height: 16px;
    line-height: 20px;
    border-radius: 0 8px 8px 0;
    background-color: rgba(244, 244, 244, 1);
    text-align: center;
  }

  .rightDot {
    width: 8px;
    height: 16px;
    line-height: 20px;
    border-radius: 8px 0 0 8px;
    background-color: rgba(244, 244, 244, 1);
    text-align: center;
  }
}

</style>