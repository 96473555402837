<template>
  <div class="ReportTypeListViewContainer">
<!--    <img src="https://cdn.waityou.online/adb08b16-b68a-bddf-0c6c-f8b59114d044.png" @click="$router.push('/report/form/1')"/>-->
    <img src="https://cdn.waityou.online/16327acd-03f2-156e-2055-c3bba6eba76c.png" @click="$router.push('/report/position')"/>
    <img src="https://cdn.waityou.online/f4e9c79e-dcff-7c25-4eb2-4d6f39cdeb35.png" @click="$router.push('/report/form/3')"/>
    <img src="https://cdn.waityou.online/6cf3bc96-3c8f-3155-1fd0-da50f20c639a.png" @click="$router.push('/report/form/4')"/>
    <el-row class="bothCenter">
    <img src="https://cdn.waityou.online/5d43c4d0-7a91-af2f-2f0a-b826469e6416.png"
         style="width: calc(100% - 32px);padding: 8px 0"
         @click="$router.push('/report/form/road')"/>
    </el-row>
    <img src="https://cdn.waityou.online/1b2c8e2d-020b-04c6-2ade-0f746c7d3b53.png" @click="$router.push('/report/form/other')"/>
  </div>
</template>

<script>
export default {
  name: "ReportTypeListView"
}
</script>

<style lang="scss" scoped>
.ReportTypeListViewContainer {
  padding-top: 20px;
  img{
    width: 100%;
  }
}
</style>