<template>
  <div class="shopDetailContainer">
    <div class="imageBackGround"
         :style="{width:'100vw',height:'75vw',backgroundImage:`url(${shop.shopImage})`}">
      <span>&nbsp;</span>
    </div>
    <div style="margin-top: -30px;padding-bottom: 100px">
      <div class="shopDetailInfo">
        <nut-row class="leftCenter">
          <span class="lighterText miniFont">主体名称</span>
        </nut-row>
        <nut-row class="leftCenter">
          <span class="baseFont boldFont">{{ shop.licenseName }}</span>
        </nut-row>
        <div class="divider"/>
        <nut-row class="leftCenter">
          <span class="lighterText miniFont">店招名</span>
        </nut-row>
        <nut-row class="leftCenter">
          <span class="baseFont boldFont">{{ shop.shopName }}</span>
        </nut-row>
        <div class="divider"/>
        <nut-row class="leftCenter">
          <span class="lighterText miniFont">类型</span>
        </nut-row>
        <nut-row class="leftCenter">
          <span class="baseFont boldFont">{{ shop.shopTypeName }}</span>
        </nut-row>
        <div class="divider"/>
        <nut-row class="leftCenter">
          <span class="lighterText miniFont">营业执照</span>
        </nut-row>
        <nut-row class="leftCenter">
          <div class="noPhoto bothCenter">
            <span v-if="!shop.licenseImage">暂无</span>
            <img v-else :src="shop.licenseImage" style="width: 30vw;height: 20vw;object-fit: cover">
          </div>
        </nut-row>
        <div class="divider"></div>
        <nut-row class="leftCenter">
          <span class="lighterText miniFont">详细地址</span>
        </nut-row>
        <nut-row class="leftCenter">
          <nut-col :span="20">
            <span class="baseFont boldFont">{{ shop.address }}</span>
          </nut-col>
          <nut-col :span="4" class="location rightCenter" @click="updateLocation">
            <img src="https://cdn.waityou.online/16f61840-a2b4-f421-3f3e-8d494c33a1e4.png"/>
            <span class="smallFont">定位</span>
          </nut-col>
        </nut-row>
        <div class="divider"/>
        <nut-row class="leftCenter">
          <span class="lighterText miniFont">门牌号</span>
        </nut-row>
        <nut-row class="leftCenter">
          <nut-col :span="12">
            <span class="baseFont boldFont">{{ shop.streetName }}</span>
          </nut-col>
          <nut-col :span="12">
            <span class="baseFont boldFont">/{{ shop.doorCode }}</span>
          </nut-col>
        </nut-row>
        <div class="divider"/>
        <nut-row class="leftCenter">
          <span class="lighterText miniFont">所属社区</span>
        </nut-row>
        <nut-row class="leftCenter">
          <span class="baseFont boldFont">{{ shop.communityName }}</span>
        </nut-row>
        <div class="divider"/>
        <nut-row class="leftCenter">
          <span class="lighterText miniFont">联系人</span>
        </nut-row>
        <nut-row class="leftCenter">
          <span class="baseFont boldFont">{{ shop.contactName }}</span>
        </nut-row>
        <div class="divider"/>
        <nut-row class="leftCenter">
          <span class="lighterText miniFont">手机号码</span>
        </nut-row>
        <nut-row class="leftCenter">
          <span class="baseFont boldFont">{{ shop.contactInfo }}</span>
        </nut-row>
        <div class="divider"/>
        <nut-row class="leftCenter">
          <span class="lighterText miniFont">社会信用代码</span>
        </nut-row>
        <nut-row class="leftCenter">
          <span class="baseFont boldFont">{{ shop.licenseCode }}</span>
        </nut-row>
        <div class="divider"/>
        <nut-row class="bothCenter"
                 @click="$router.push('/shop/clerk/'+shopId)"
                 style="padding: 4px 0">
          <nut-col :span="20" class="leftCenter">
            <span class="baseFont boldFont">店铺人员</span>
          </nut-col>
          <nut-col :span="4" class="rightCenter">
            <nut-icon name="rect-right" size="14"></nut-icon>
          </nut-col>
        </nut-row>
      </div>
    </div>
    <div class="submitBtnContainer bothCenter">
      <nut-col :span="12">
        <nut-button class="stopBtn" v-if="shop.state == 'RUNNING'" @click="markShopDown">搬迁或停业</nut-button>
        <nut-button class="stopBtn" v-else @click="markShopRunning">正常营业</nut-button>
      </nut-col>
      <nut-col :span="12">
        <nut-button class="submitBtn" @click="$router.push('/shop/info/edit/'+shopId)">修改</nut-button>
      </nut-col>
    </div>
  </div>
</template>

<script>

import api from "@/api";
import {Toast} from "@nutui/nutui";

export default {
  name: "ShopDetailView",
  components: {},
  data() {
    return {
      shopId: this.$route.params.shopId,
      shop: {}
    }
  },
  methods: {
    updateLocation() {
      Toast.loading('定位中请稍后...')
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
              Toast.hide()
              // 成功获取定位信息
              let latitude = position.coords.latitude; // 纬度
              let longitude = position.coords.longitude; // 经度
              let accuracy = position.coords.accuracy; // 定位精度
              api.updateShopLocation({
                'shopId': this.shopId,
                'longitude': longitude,
                'latitude': latitude,
                'accuracy': accuracy
              }).then(() => {
                Toast.success('商铺定位更新成功')
              })
            },
            (error) => {
              Toast.hide()
              // 获取定位信息失败
              switch (error.code) {
                case error.PERMISSION_DENIED:
                  Toast.fail('您拒绝了程序的定位要求')
                  break;
                default:
                  Toast.fail('定位时遇到未知错误，您的定位信息可能不准确')
                  break;
              }
            }
        );
      } else {
        Toast.hide()
        Toast.fail('您的浏览器不支持定位无法打卡')
      }
    },
    markShopDown() {
      api.setShopState(this.shopId, 2)
          .then(() => {
            Toast.success('已设置店铺状态：搬迁或停业')
            this.init();
          })
    },
    markShopRunning() {
      api.setShopState(this.shopId, 0)
          .then(() => {
            Toast.success('已设置店铺状态：正常营业')
            this.init();
          })
    },
    init() {
      api.getShopDetail(this.shopId)
          .then(ret => {
            this.shop = ret.shop;
          })
    }
  }
  ,
  mounted() {
    this.init();
  }
}
</script>

<style lang="scss" scoped>
.shopDetailContainer {
  width: 100%;
  min-height: 100vh;
  background-color: #F6F6F6;

  .location {
    img {
      width: 10px;
    }

    span {
      color: rgba(102, 102, 102, 1);
      font-weight: lighter;
      padding-left: 5px;
    }
  }

  .noPhoto {
    background-color: rgba(246, 246, 246, 1);
    color: rgba(16, 16, 16, 1);
    height: 100px;
    width: 100px;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: rgba(242, 242, 242, 1);
    margin: 5px 0;
  }

  .submitBtnContainer {
    background-color: white;
    position: fixed;
    width: 100%;
    bottom: 0;

    .submitBtn {
      background-color: rgba(22, 119, 254, 1);
      color: rgba(255, 255, 255, 1);
      border: none;
      width: 90%;
      margin: 10px;
    }

    .stopBtn {
      background-color: #FF7D01;
      color: rgba(255, 255, 255, 1);
      border: none;
      width: 90%;
      margin: 10px;
    }
  }


  .boxContainers {
    padding: 10px;

    .boxContainerL {
      padding: 4px 4px 4px 0;
    }

    .boxContainerR {
      padding: 4px 0 4px 4px;
    }
  }


  .lighterText {
    color: #666666;
    padding: 5px 0;
  }

  .checkHistory {
    color: #1677FE;
  }

  .shopDetailInfo {
    background-color: white;
    padding: 10px;
    margin: 0 10px;
    border-radius: 15px 15px 0 0;

    .ShopInfo {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>