<template>
  <div class="ReportedEventItemContainer wbg colFlex">
    <nut-row>
      <span class="tag">{{ data.eventTypeName }}{{data.subTypeName}}</span>
      <span class="title">{{ data.reporterUserName }}{{ data.eventTypeName }}上报</span>
    </nut-row>
    <span class="content">{{ data.content }}</span>
    <span class="time">{{ data.createdTime }}</span>
    <div class="divider"></div>
  </div>
</template>

<script>
export default {
  name: "ReportedEventItem",
  props: {
    data: {
      type: Object,
      default: ()=>{
        return {

        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ReportedEventItemContainer {
  padding: 8px;
  .divider{
    background-color: #E8E8E8;
    height: 1px;
    width: 100%;
    margin-top: 5px;
  }
  .time{
    margin-top: 5px;
    font-size: $miniFontSize;
    color: #999999;
  }
  .content{
    margin-top: 5px;
    color: rgba(102, 102, 102, 1);
    font-size: $miniFontSize;
  }
  .title{
    font-size: $baseFontSize;
    padding: 0 5px;
  }
  .tag{
    padding: 2px 5px;
    font-size: $miniFontSize;
    color:white;
    width: 55px;
    height: 20px;
    border-radius: 2px;
    background-color: rgba(30, 66, 122, 1);
  }
}
</style>