<template>
  <div class="ShopClerkViewContainer">
    <span class="smallFont mb8">当前店员</span>
    <div class="colFlex" style="border: #eae9e9 1px solid">
      <nut-row class="bothCenter miniFont"
               style="background: #ececec;box-sizing: border-box">
        <nut-col :span="4" class="leftCenter rightBoard pd64">
          <span class="ml4">姓名</span>
        </nut-col>
        <nut-col :span="10" class="leftCenter rightBoard pd64">
          <span class="ml4">身份证号</span>
        </nut-col>
        <nut-col :span="7" class="leftCenter rightBoard  pd64">
          <span class="ml4">手机号</span>
        </nut-col>
        <nut-col :span="5" class="leftCenter">
          <span class="ml4">操作</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter miniFont"
               v-for="u in nowUser"
               :key="`u_${u.id}`"
               style="box-sizing: border-box;border-top: #eae9e9 1px solid">
        <nut-col :span="4" class="leftCenter rightBoard pd64">
          <span class="ml4">{{ u.name }}</span>
        </nut-col>
        <nut-col :span="10" class="leftCenter rightBoard pd64">
          <span class="ml4">{{ u.idCard ? u.idCard : '-' }}</span>
        </nut-col>
        <nut-col :span="7" class="leftCenter rightBoard  pd64">
          <span class="ml4">{{ u.phone }}</span>
        </nut-col>
        <nut-col :span="5" class="bothCenter">
          <img src="https://cdn.waityou.online/bb598ac5-3d2a-3f70-33c6-64059e3cfe4f.svg" @click="toHistory(u.id)"
               class="sqr16">
          <img src="https://cdn.waityou.online/3247eb33-f170-d6c7-bb1a-e8760423f141.svg" @click="updateClerk(u)"
               class="sqr16 ml16">
        </nut-col>
      </nut-row>
    </div>
    <nut-button shape="rect" style="border-radius: 4px"
                v-if="addBtnVisible"
                class="mt16"
                @click="addBtnVisible=false">新增店员
    </nut-button>
    <nut-row class="colFlex mt8 pd12 smallFont"
             v-if="!addBtnVisible"
             style="border: #005FF5 1px dashed;box-sizing: border-box;border-radius: 4px">
      <nut-row>
        <nut-col :span="6">
          <span>姓名</span>
          <span style="color:red">&nbsp;*</span>
        </nut-col>
        <nut-col :span="18">
          <nut-input
              v-model="form.name"
              style="padding: 0" placeholder="请输入姓名"/>
        </nut-col>
      </nut-row>
      <nut-row class="mt16">
        <nut-col :span="6">
          <span>身份证号</span>
        </nut-col>
        <nut-col :span="18">
          <nut-input
              v-model="form.idCard"
              style="padding: 0" placeholder="请输入身份证号"/>
        </nut-col>
      </nut-row>
      <nut-row class="mt16">
        <nut-col :span="6">
          <span>手机号</span>
          <span style="color:red">&nbsp;*</span>
        </nut-col>
        <nut-col :span="18">
          <nut-input v-model="form.phone"
                     style="padding: 0" placeholder="请输入姓名"/>
        </nut-col>
      </nut-row>
      <nut-row class="mt16 colFlex">
        <nut-button shape="rect" type="primary" class="wd100" @click="addClerk">新增</nut-button>
        <nut-button shape="rect" class="wd100 mt8" @click="addBtnVisible=true">取消</nut-button>
      </nut-row>
    </nut-row>

    <nut-row class="colFlex mt8 pd12 smallFont"
             v-if="editVisible"
             style="border: #005FF5 1px dashed;box-sizing: border-box;border-radius: 4px">
      <nut-row>
        <nut-col :span="6">
          <span>姓名</span>
          <span style="color:red">&nbsp;*</span>
        </nut-col>
        <nut-col :span="18">
          <nut-input
              v-model="edit.name"
              style="padding: 0" placeholder="请输入姓名"/>
        </nut-col>
      </nut-row>
      <nut-row class="mt16">
        <nut-col :span="6">
          <span>身份证号</span>
        </nut-col>
        <nut-col :span="18">
          <nut-input
              v-model="edit.idCard"
              style="padding: 0" placeholder="请输入身份证号"/>
        </nut-col>
      </nut-row>
      <nut-row class="mt16">
        <nut-col :span="6">
          <span>手机号</span>
          <span style="color:red">&nbsp;*</span>
        </nut-col>
        <nut-col :span="18">
          <nut-input v-model="edit.phone"
                     style="padding: 0" placeholder="请输入姓名"/>
        </nut-col>
      </nut-row>
      <nut-row class="mt16 colFlex">
        <nut-button shape="rect" type="primary" class="wd100" @click="confirmUpdateClerk">确认更新</nut-button>
        <nut-button shape="rect" class="wd100 mt8" @click="editVisible=false">取消</nut-button>
      </nut-row>
    </nut-row>

    <span class="smallFont mb8 mt16">历史店员</span>
    <div class="colFlex" style="border: #eae9e9 1px solid">
      <nut-row class="bothCenter miniFont"
               style="background: #ececec;box-sizing: border-box">
        <nut-col :span="4" class="leftCenter rightBoard pd64">
          <span class="ml4">姓名</span>
        </nut-col>
        <nut-col :span="10" class="leftCenter rightBoard pd64">
          <span class="ml4">身份证号</span>
        </nut-col>
        <nut-col :span="7" class="leftCenter pd64 rightBoard">
          <span class="ml4">手机号</span>
        </nut-col>
        <nut-col :span="5" class="leftCenter">
          <span class="ml4">操作</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter miniFont"
               v-for="u in historyUser"
               :key="`u_${u.id}`"
               style="box-sizing: border-box;border-top: #eae9e9 1px solid">
        <nut-col :span="4" class="leftCenter rightBoard pd64">
          <span class="ml4">{{ u.name }}</span>
        </nut-col>
        <nut-col :span="10" class="leftCenter rightBoard pd64">
          <span class="ml4">{{ u.idCard ? u.idCard : '-' }}</span>
        </nut-col>
        <nut-col :span="7" class="leftCenter rightBoard  pd64">
          <span class="ml4">{{ u.phone }}</span>
        </nut-col>
        <nut-col :span="5" class="bothCenter">
          <img src="https://cdn.waityou.online/bb598ac5-3d2a-3f70-33c6-64059e3cfe4f.svg" @click="toLive(u.id)"
               class="sqr16">
          <img src="https://cdn.waityou.online/3247eb33-f170-d6c7-bb1a-e8760423f141.svg" @click="updateClerk(u)"
               class="sqr16 ml16">
        </nut-col>
      </nut-row>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "ShopClerkView",
  data() {
    return {
      editVisible: false,
      addBtnVisible: true,
      nowUser: [],
      historyUser: [],
      form: {},
      edit: {},
      shopId: this.$route.params.shopId,
      columns: [
        {
          title: '姓名',
          key: 'name'
        },
        {
          title: '身份证号',
          key: 'idCard'
        },
        {
          title: '手机号',
          key: 'phone'
        },
        {
          title: '操作',
          key: 'operate'
        },
      ]
    }
  },
  methods: {
    confirmUpdateClerk() {
      api.updateShopClerk(this.edit)
          .then(() => {
            this.editVisible = false;
            this.init();
          })
    },
    updateClerk(u) {
      this.edit = u;
      this.editVisible = true;
    },
    toHistory(uid) {
      api.toHistoryShopClerk(uid)
          .then(() => {
            this.init()
          })
    },
    toLive(uid) {
      api.toHistoryShopClerk(uid)
          .then(() => {
            this.init()
          })
    },
    addClerk() {
      this.form.shopId = this.shopId;
      api.addShopClerk(this.form)
          .then(() => {
            this.form = {};
            this.init()
          })
    },
    init() {
      this.addBtnVisible = true;
      api.getShopClerk(this.shopId)
          .then(ret => {
            this.nowUser = ret.NOW;
            this.historyUser = ret.HISTORY;
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>
<style>
input::placeholder {
  color: #B2B2B2 !important;
}
</style>
<style lang="scss" scoped>

.ShopClerkViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0 12px;

  .ml4 {
    margin-left: 4px;
  }

  .rightBoard {
    border-right: #d2d0d0 1px solid;
  }

  .pd64 {
    padding: 6px 4px;
  }

  .sqr16 {
    width: 16px;
  }
}
</style>