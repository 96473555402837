<template>
  <div class="ReportToDutyCheckViewContainer">
    <nut-tabs v-model="tab" background="white">
      <nut-tabpane title="点验">
        <PeopleGroupCheckView/>
      </nut-tabpane>
      <nut-tabpane title="点验记录" style="background-color: #F5F5F5;">
        <PeopleCheckHistoryView/>
      </nut-tabpane>
    </nut-tabs>
  </div>
</template>

<script>
import PeopleCheckHistoryView from "@/views/check/PeopleCheckHistoryView";
import PeopleGroupCheckView from "@/views/check/PeopleGroupCheckView";
export default {
  name: "ReportToDutyCheckView",
  components: {PeopleGroupCheckView, PeopleCheckHistoryView},
  data() {
    return {
      tab: 0
    }
  },
  methods: {
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>
<style>
.nut-tabs__titles{
  border-bottom: rgba(221, 221, 221, 0.3) 1px solid;
}
</style>
<style lang="scss" scoped>
.ReportToDutyCheckViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
}
</style>