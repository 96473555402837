<template>
  <div class="ImageMessageContainer">
    <LeftMessage v-if="myId != data.creatorId" :data="data">
      <photo-provider>
      <photo-consumer :src="data.imageUrl" style="text-align: left">
        <img style="border-radius:5px;margin:5px 0;max-width: 80%;max-height:120px;object-fit: cover"
             :src="`${data.imageUrl}?x-oss-process=image/resize,p_10`"/>
      </photo-consumer>
      </photo-provider>
    </LeftMessage>
    <RightMessage v-if="myId == data.creatorId" :data="data">
      <photo-provider>
      <photo-consumer :src="data.imageUrl" style="text-align: right">
      <img style="border-radius:5px;margin:5px 0;max-width: 80%;max-height:120px;object-fit: cover"
           :src="`${data.imageUrl}?x-oss-process=image/resize,p_10`"/>
      </photo-consumer>
      </photo-provider>
    </RightMessage>
  </div>
</template>

<script>
import LeftMessage from "@/components/im/LeftMessage";
import RightMessage from "@/components/im/RightMessage";

export default {
  name: "ImageMessage",
  components: {RightMessage, LeftMessage},
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    myId: {
      type: Number,
      default: -1,
    }
  },
}
</script>

<style lang="scss" scoped>
.ImageMessageContainer {
  display: flex;
  flex-direction: column;
}
</style>