<template>
  <nut-row class="wbg">
    <nut-col :span="8" class="searchSelector">
      <span class="searchSelectorSpan" @click="activeNames=='1'?activeNames='0':activeNames=1">类型{{checkedShopTypeCnt>0?`(${checkedShopTypeCnt})`:''}}</span>
      <svg-icon icon-class="downb2"/>
    </nut-col>
    <nut-col :span="8" class="searchSelector">
      <span class="searchSelectorSpan" @click="activeNames=='2'?activeNames='0':activeNames=2">社区{{checkedCommunityCnt>0?`(${checkedCommunityCnt})`:''}}</span>
      <svg-icon icon-class="downb2"/>
    </nut-col>
    <nut-col :span="8" class="searchSelector" v-if="!withCheckHistory">
      <input type="checkbox" v-model="noCheckThisYear" @change="yearCheckChange"/>
      <span class="searchSelectorSpan2" @click="activeNames='0'">本年度未检查</span>
    </nut-col>
    <nut-col :span="8" class="searchSelector" v-else>
      <div style="height: 30px;">&nbsp;</div>
    </nut-col>
  </nut-row>
  <nut-collapse v-model:active="activeNames" :accordion="true">
    <nut-collapse-item :name="1">
      <ShopSelectorButtons title-name="类型"
                           @confirm="filterByShopTypes"
                           :list="shopType" name-key="typeName"/>
    </nut-collapse-item>
    <nut-collapse-item :name="2">
      <ShopSelectorButtons title-name="社区"
                           @confirm="filterByCommunities"
                           :list="communities" name-key="communityName"/>
    </nut-collapse-item>
  </nut-collapse>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";
import ShopSelectorButtons from "@/components/shop/ShopSelectorButtons";
import api from "@/api";

export default {
  name: "ShopCheckSelector",
  props: {
    withCheckHistory: {
      type: Boolean,
      default: false
    },
  },
  components: {
    ShopSelectorButtons,
    SvgIcon
  },
  data() {
    return {
      activeNames: '0',
      shopType: [],
      communities: [],
      noCheckThisYear:null,
      checkedShopTypeCnt:0,
      checkedCommunityCnt:0,
    }
  },
  methods: {
    yearCheckChange(){
      this.$emit('yearCheckChange',this.noCheckThisYear)
    },
    filterByShopTypes(ids) {
      this.$emit('filterByShopTypes',ids);
      this.checkedShopTypeCnt = ids?ids.length:0
      this.activeNames='0';
    },
    filterByCommunities(ids) {
      this.$emit('filterByCommunities',ids);
      this.activeNames='0';
      this.checkedCommunityCnt = ids?ids.length:0
    }
  },
  mounted() {
    const collapseItems = document.querySelectorAll('.collapse-item')
    collapseItems.forEach(item => {
      item.style.padding = '0'
    })
    api.getShopTypes()
        .then(ret => {
          this.shopType = ret;
        });
    api.getCommunities()
        .then(ret => {
          this.communities = ret;
        });
  }
}
</script>
<style>
.collapse-item {
  height: 0;
  padding: 0;
}
</style>
<style lang="scss" scoped>

.searchSelector {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;

  .searchSelectorSpan {
    padding: 5px;
    font-size: $smallerFontSize;
    color: #B2B2B2;
  }

  .searchSelectorSpan2 {
    padding: 5px;
    font-size: $smallerFontSize;
    color: black;
    font-weight: lighter;
  }
}
</style>