const base = {
    baseUrl: "",
    userInfo: "/api/user/info",
    login: "/login/mobileAccountLogin",
    logout: "/api/user/logout?channel=1&env={0}",
    getOssKeySecret: "/api/user/upload/getOssKeySecret",
    getWechatRedirectUrl: "/login/weChatLogin?url={0}",
    wechatCodeLogin: "/login/getUserInfoByCode?code={0}",
    getAuthInfo: "/api/user/auth/getAuthInfo",
    submitAuthInfo: "/api/user/auth/submitAuthInfo",
    getBanners: "/api/user/banner/list",
    createPost: '/api/user/post/createPost',
    getPlatformMessage: '/api/user/message/list',
    getPostList: '/api/user/index/postList',
    markRead: '/api/user/message/markRead?messageId={0}',
    readAllMessage: '/api/user/message/readAll',
    getPostDetail: '/api/user/post/detail?pid={0}',
    getCommentCreatorInfo: '/api/user/comment/creatorInfo?commentId={0}',
    getGetComments: '/api/user/comment/list',
    postComment: '/api/user/comment/post',
    getHotList: '/api/user/hot/hotList',
    thumbPost: '/api/user/thumb/thumb2post?postId={0}',
    thumbComment: '/api/user/thumb/thumb2comment?commentId={0}',
    searchPostList: '/api/user/post/search',
    updateInfo: '/api/user/update',
    feedback: '/api/user/feedback',
    listChatMessage: '/api/user/conversation/list',
    postChatMessage: '/api/user/conversation/send',
    getShopTypes: '/api/user/setting/shopTypes',
    getCommunities: '/api/user/setting/communities',
    queryShopList: '/api/user/shop/shopList',
    getStreetList: '/api/user/setting/streets',
    getShopDetail: '/api/user/shop/detail?id={0}',
    getShopCheckMatters: '/api/user/matter/shopCheckMatters',
    postEvent: '/api/user/event/postEvent',
    queryPoliceEventList: '/api/user/event/list',
    updateShop: '/api/user/shop/updateShop',
    report: '/api/user/report/report',
    queryReportExist: '/api/user/report/queryReportExist',
    updateShopImage: '/api/user/shop/updateShopImage',
    getShopCheckers: '/api/user/shop/getShopChecker?shopId={0}',
    addShopChecker: '/api/user/shop/addShopChecker',
    getShopMatterDetail: '/api/user/matter/detail',
    updateShopMatterCheck: '/api/user/matter/update',
    submitCheckReport: '/api/user/matter/submitReport',
    getShopCheckReport: '/api/user/matter/getShopCheckReport?reportId={0}',
    removeChecker: '/api/user/shop/removeShopChecker',
    signature2image: '/api/user/signature2image',
    getOpenApiToken: '/login/getOpenApiToken?code={0}',
    getOpenShopCheckReport: '/api/open/shop/getShopCheckReport?reportId={0}',
    submitReceiveReport: '/api/open/shop/signReceiveReport',
    updateMySign: '/api/user/updateMySign?url={0}',
    deleteMySign: '/api/user/deleteMySign',
    getClockInfo: '/api/user/clock/clock',
    updateClockInAddress: '/api/user/clock/updateClockInAddress',
    getDataSummary: '/api/user/data/query/summary',
    getEarlierMessage: '/api/user/conversation/earlierList',
    getEventDetail: '/api/user/event/detail?eventId={0}',
    closePoliceEvent: '/api/user/event/close?eventId={0}',
    getActiveEvents: '/api/user/event/getActiveEvents',
    updatePass: '/api/user/updatePass',
    wxBindToken: '/api/user/wxBindToken?token={0}',
    unBindWechat: '/api/user/unbindWx',
    getUnReadMessageCount: '/api/user/message/countUnRead',
    getMyStatisticData: '/api/user/statistic/myData',
    getStatisticRank: '/api/user/statistic/rank',
    getMonthCalendar: '/api/user/statistic/getMonthCalendar',
    queryShopStatisticDataByDay: '/api/user/statistic/queryShopStatisticDataByDay',
    updateShopCheckReport: '/api/user/matter/updateShopCheckReport?reportId={0}',
    getReportDetail: '/api/user/report/detail?id={0}',
    getWeather: '/api/user/setting/weather',
    getSearchToken: '/api/user/shop/search',
    getSearchHistory: '/api/user/shop/searchHistory',
    cleanShopSearchHistory: '/api/user/shop/cleanShopSearchHistory',
    getSearch: '/api/user/shop/getSearch?id={0}',
    setShopState: '/api/user/shop/setShopState?shopId={0}&state={1}',
    updateShopLocation: '/api/user/shop/updateShopLocation',
    queryShopStatisticDataByYear: '/api/user/statistic/queryShopStatisticDataByYear',
    queryShopStatisticDataByMonth: '/api/user/statistic/queryShopStatisticDataByMonth',
    queryShopStatisticDataByDateRange: '/api/user/statistic/queryShopStatisticDataByDateRange',
    uploadErrorMessage: '/api/user/setting/uploadErrorMessage',
    covertGPS: '/api/user/setting/amap/convertGps',
    getChatList: '/api/user/conversation/getChatList',
    getReadPriorities: '/api/user/getReadPriorities',
    getTodayCheck: '/api/user/duty/check/getTodayCheck',
    submitDutyCheckResult: '/api/user/duty/check/submitDutyCheckResult',
    getDutyCheckHistory: '/api/user/duty/check/getDutyCheckHistory',
    getChatInfo: '/api/user/conversation/getChatInfo',
    addShopClerk: '/api/user/shop/clerk/add',
    getShopClerk: '/api/user/shop/clerk/list?shopId={0}',
    toHistoryShopClerk: '/api/user/shop/clerk/toHistory?id={0}',
    getPoliceEventHandleRank: '/api/user/data/query/getPoliceEventHandleRank',
    rollbackMessage: '/api/user/conversation/rollbackMessage?id={0}',
    updateShopClerk: '/api/user/shop/clerk/update',

    infiniteLoadings: {
        1: '/api/user/shop/shopList',
        2: '/api/user/index/news/lastest',
        3: '/api/user/matter/getShopCheckHistory',
        4: '/api/user/clock/history',
        5: '/api/user/conversation/eventMessages',
        6: '/api/user/message/list',
        7: '/api/user/event/list',
        8: '/api/user/report/list',
        9: '/api/user/conversation/getChatList',
        10: '/api/user/duty/check/historyList',
        11: '/api/user/conversation/search',
    }
}

export default base;