<template>
  <div class="ShopCheckDetailViewContainer">
    <ShopCheckDetailItem v-for="(item,i) in items"
                         :data="item"
                         :index="i"
                         :ref="el =>setRef(el, i)"
                         :key="i"/>
    <div class="hiddenDangerContainer" v-if="hiddenDangers.length > 0">
      <div class="hiddenDanger smallFont">
        <span class="title baseFont boldFont">隐患</span>
        <p v-for="(h,i) in hiddenDangers" :key="i" style="margin:0">
          {{ i + 1 }}、{{ h }}
        </p>
      </div>
    </div>
  </div>

  <div class="submitBtnContainer bothCenter" v-if="!updateMode">
    <nut-row class="bothCenter">
      <nut-col :span="8" class="leftCenter">
        <nut-button class="warnBtn" @click="addVisible=true">添加隐患</nut-button>
      </nut-col>
      <nut-col :span="16" class="rightCenter">
        <nut-button class="submitBtn" @click="saveAll">保存</nut-button>
      </nut-col>
    </nut-row>
  </div>
  <div class="submitBtnContainer bothCenter" v-if="updateMode && !submited">
    <nut-button class="submitBtn" @click="saveAll">保存</nut-button>
  </div>
  <nut-dialog
      title="添加隐患"
      @ok="onAddHiddenDanger"
      @cancel="hiddenDanger = null"
      v-model:visible="addVisible"
  >
    <div class="colFlex bothCenter">
      <span>请把添加的隐患填写在下方</span>
      <nut-textarea class="textarea" placeholder="请填写隐患" v-model="hiddenDanger"></nut-textarea>
    </div>
  </nut-dialog>
</template>

<script>
import ShopCheckDetailItem from "@/components/shop/ShopCheckDetailItem";
import api from "@/api"

export default {
  name: "ShopCheckDetailView",
  components: {ShopCheckDetailItem},
  props: {
    updateMode: {
      type: Boolean,
      default: false,
    },
    setMatterId: {
      type: Number,
      default: 0
    },
    setShopId: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      submited: false,
      addVisible: false,
      shopId: this.$route.params.shopId,
      matterId: this.$route.params.matterId,
      items: [],
      hiddenDangers: [],
      mRefs: [],
      hiddenDanger: null,
    }
  },
  methods: {
    onAddHiddenDanger() {
      if (this.hiddenDanger && this.hiddenDanger.length > 0) {
        this.hiddenDangers.push(this.hiddenDanger);
      }
      this.hiddenDanger = null;
      this.addVisible = false;
    },
    setRef(r) {
      this.mRefs.push(r);
    },
    saveAll() {
      let matterData = [];
      for (let ref of this.mRefs) {
        matterData.push(ref.getCheckData())
      }
      let d = {
        shopId: this.shopId,
        matterId: this.matterId,
        hiddenDangers: this.hiddenDangers,
        data: matterData,
        improveMode: this.updateMode,
      };
      api.updateShopMatterCheck(d).then(() => {
        this.submited = true;
        if (this.updateMode) {
          this.$emit('improved')
        } else {
          this.$router.back();
        }
      })
    },
    init() {
      api.getShopMatterDetail({
        shopId: this.shopId,
        matterId: this.matterId
      }).then(ret => {
        this.hiddenDangers = ret.hiddenDangers;
        this.items = ret.items;
      })
    }
  },
  mounted() {
    if (!this.shopId) {
      this.shopId = this.setShopId;
    }
    if (!this.matterId) {
      this.matterId = this.setMatterId;
    }
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.textarea {
  margin-top: 10px;
  background-color: rgba(245, 245, 245, 1);
  font-size: $smallerFontSize;
}

.submitBtnContainer {
  background-color: white;
  position: fixed;
  width: 100%;
  bottom: 0;

  .warnBtn {
    background-color: rgba(255, 125, 1, 1);
    color: rgba(255, 255, 255, 1);
    border: none;
    width: 92%;
    margin: 10px;
  }

  .submitBtn {
    background-color: rgba(22, 119, 254, 1);
    color: rgba(255, 255, 255, 1);
    border: none;
    width: 92%;
    margin: 10px;
  }
}

.ShopCheckDetailViewContainer {
  background-color: #F0F1F4;
  min-height: 100vh;
  padding-bottom: 100px;

  .hiddenDangerContainer {
    padding: 10px;

    .hiddenDanger {
      display: flex;
      padding: 10px;

      flex-direction: column;
      background-color: white;
      border-radius: 5px;

      p {
        color: rgba(51, 51, 51, 1);
      }

      .title {
        color: #101010;
      }
    }
  }
}
</style>