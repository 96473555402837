<template>
  <div class="PeopleGroupCheckViewContainer">
    <template v-if="!selectedGroup">
      <nut-row class="bothCenter colFlex" style="height: 80vh">
        <span class="mb16">选择要点验的组织</span>
        <nut-button v-for="g in groups"
                    shape="rect"
                    @click="selectedGroup=g"
                    style="width: 200px;margin-top: 12px;border-radius: 4px;
                    background-color: #1388ff;color: white"
                    :key="g.orgId">{{g.name}}</nut-button>
      </nut-row>
    </template>
    <PeopleCheckView v-if="selectedGroup" :data="selectedGroup"/>
  </div>
</template>

<script>
import api from "@/api";
import PeopleCheckView from "@/views/check/PeopleCheckView";

export default {
  name: "PeopleGroupCheckView",
  components: {PeopleCheckView},
  data() {
    return {
      selectedGroup:null,
      groups: [],
      activeNames: [],
    }
  },
  methods: {
    init() {
      api.getTodayCheck()
          .then(ret => {
            this.groups = ret;
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>
<style>
.nut-tabpane {
  padding: 0 !important;
}

.nut-tabpane .collapse-item {
  height: 50px !important;
}
</style>
<style lang="scss" scoped>

.PeopleGroupCheckViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;


}
</style>