<template>
  <div class="PoliceHandleRankViewContainer">
    <nut-tabs v-model="activeTab">
      <template v-for="(rank,rankIndex) in ranks" :key="rankIndex">
        <nut-tabpane :title="rank.name">
          <OrganizationRank :rank="rank.orgRankList"/>
          <div class="bothCenter pd16">
            <div class="header bothCenter">
              <nut-col :span="12" class="colFlex bothCenter">
                <span class="number blue">{{ rank.myCnt }}</span>
                <span class="miniFont lightText">我的处置数</span>
              </nut-col>
              <nut-col :span="12" class="colFlex bothCenter">
                <span class="number">{{ rank.myRankIndex }}</span>
                <span class="miniFont lightText">我的排名</span>
              </nut-col>
            </div>
          </div>
          <PersonalRank :rank="rank.rankList"/>
        </nut-tabpane>
      </template>
    </nut-tabs>
  </div>
</template>

<script>
import PersonalRank from "@/components/rank/PersonalRank";
import OrganizationRank from "@/components/rank/OrganizationRank";
import api from "@/api";

export default {
  name: "PoliceHandleRankView",
  components: {OrganizationRank, PersonalRank},
  data() {
    return {
      activeTab: 0,
      ranks: [],
    }
  },
  methods: {
    init() {
      api.getPoliceEventHandleRank()
          .then(ret => {
            this.ranks = ret;
          })
    }
  },
  mounted() {
    this.init();
  }
}
</script>

<style lang="scss" scoped>
.PoliceHandleRankViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;

  .header {
    background-image: url("https://cdn.waityou.online/bdd2dda4-d20a-d2ac-6c6a-748e6b763749.png");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 90px;
    width: 100%;

    .historyBtn {
      height: 32px;
      border-radius: 22px;
      background-color: rgba(22, 119, 254, 1);
      color: rgba(255, 255, 255, 1);
      border: none;
    }

    .number {
      font-size: 24px;
    }

    .blue {
      color: #1677FE;
    }

    .lightText {
      color: #999999;
    }
  }
}
</style>