<template>
  <div class="CalendarContainer">
    <nut-row class="bothCenter vmg16">
      <nut-col :span="8" class="rightCenter">
        <nut-icon name="left" size="10" color="#1677FE" @click="monthMinus"></nut-icon>
      </nut-col>
      <nut-col :span="8" class="bothCenter">
        <span class="biggerFont boldFont">{{ year }}年{{ month }}月</span>
      </nut-col>
      <nut-col :span="8" class="leftCenter">
        <nut-icon name="right" size="10" color="#1677FE" @click="monthAdd"></nut-icon>
      </nut-col>
    </nut-row>
    <div class="calendarRow">
      <div class="calendarItem">
        <span>日</span>
      </div>
      <div class="calendarItem">
        <span>一</span>
      </div>
      <div class="calendarItem">
        <span>二</span>
      </div>
      <div class="calendarItem">
        <span>三</span>
      </div>
      <div class="calendarItem">
        <span>四</span>
      </div>
      <div class="calendarItem">
        <span>五</span>
      </div>
      <div class="calendarItem">
        <span>六</span>
      </div>
    </div>
    <div class="divider vmg8"></div>
    <div class="calendarRow vmg8 smallFont" v-for="(week,wi) in date" :key="wi">
      <div v-for="day in week" :key="day" class="calendarItem bothCenter" @click="onChangeDay(day)">
        <span :class="day==selectedDay?'today':'weekday'">{{ day.substring(6) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "IceCalendar",
  methods: {
    monthMinus() {
      this.month--;
      if (this.month < 1) {
        this.month = 12;
        this.year -= 1;
      }
      this.init()
    },
    monthAdd() {
      this.month++;
      if (this.month > 12) {
        this.month = 1;
        this.year += 1;
      }
      this.init()
    },
    onChangeDay(d) {
      this.selectedDay = d;
      this.$emit('changeDay', this.today, d)
    },
    init() {
      api.getMonthCalendar({
        year: this.year,
        month: this.month,
      }).then(ret => {
        this.date = ret;
      })
    },
    todayDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}${month}${day}`;
    }
  },
  data() {
    return {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      date: [],
      today: this.todayDate(),
      selectedDay: this.todayDate(),
    }
  },
  mounted() {
    this.init()
    this.$emit('changeDay', this.today, this.today)
  }
}
</script>

<style lang="scss" scoped>
.CalendarContainer {
  display: flex;
  flex-direction: column;

  .calendarRow {
    width: 100%;
    display: flex;

    .calendarItem {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 14.28%;

      .today {
        background-color: #1388ff;
        color: white;
        padding: 2px 5px;
        border-radius: 20px;
      }
    }
  }
}
</style>