<template>
  <nut-tabbar v-model:visible="active"
              unactive-color="#7d7e80" active-color="#1989fa"
              :bottom="true"
              size='20px'
              :safeAreaInsetBottom="true">
    <nut-tabbar-item tab-title="首页"
                     img="https://cdn.waityou.online/05cd96ca-e111-34fe-d29b-58fa0f82fae6.png"
                     activeImg="https://cdn.waityou.online/0c3dd3f7-5672-492b-dfe1-dda56dc888fa.png"
                     to="/"></nut-tabbar-item>
    <nut-tabbar-item tab-title="我的"
                     img="https://cdn.waityou.online/1ad5d0da-9588-4312-557a-2064275cd28d.png"
                     activeImg="https://cdn.waityou.online/7ab90177-bfe1-62dc-ebb9-3b50ac4eb7a5.png"
                     to="/my"></nut-tabbar-item>
  </nut-tabbar>
</template>

<script>
export default {
  name: "TabBar",
  data() {
    return {
      active: 0
    }
  },
  props: {
    tab: {
      type: Number,
      default: 0
    },
  },
  mounted() {
    this.active = this.tab;
  },
  watch: {
    tab(n) {
      this.active = n;
    }
  }
}
</script>

<style lang="scss">
.nut-tabbar-item_icon-box_nav-word{
  font-size: $minFontSize !important;
}
</style>