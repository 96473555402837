<template>
  <div :class="actived?'streetContainer active':'streetContainer'">
    <span>{{ data.streetName }}</span>
    <div class="bothCenter">
      <nut-icon name="shop3" size="12px" class="icon"></nut-icon>
      <span class="miniFont">{{ data.shopCounts }}家</span>
    </div>
  </div>
</template>

<script>

export default {
  name: "StreetItem",
  components: {},
  props: {
    data:{
      type:Object,
      default:()=>{
        return {

        }
      }
    },
    actived: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style lang="scss" scoped>
.streetContainer {
  display: flex;
  flex:0 0 auto;
  width: 92px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: $smallerFontSize;
  background-color: white;
  color:#333333;
  padding: 4px 5px;
  margin: 5px 0 5px 10px;
  border-radius: 5px;

  .icon{
    margin-right: 5px;
  }
}
.active{
  background-color: #3894FF;
  color:white;
}
</style>