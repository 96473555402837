<template>
  <div class="shopItemContainer">
    <nut-row style="display: flex;">
      <div style="width: 60px">
        <img class="shopImage" :src="data.shopImage?data.shopImage+'?x-oss-process=image/resize,p_10':defaults.noImage"/>
      </div>
      <div class="shopItemInfo">
        <nut-row class="shopName leftCenter">
          <ShopTypeTag :name="data.shopTypeName"/>
          <span class="boldFont">{{ data.shopName }}</span>
        </nut-row>
        <nut-row class="shopItemAddress leftCenter">
          <nut-col :span="16">
            <span>{{ data.address }}</span>
          </nut-col>
          <nut-col :span="8" class="rightCenter">
            <img src="https://cdn.waityou.online/1f523e90-191d-2ba0-fed5-eba75d0bed27.png"
                 style="width: 8px;height: 10px"/>
            <span>&nbsp;{{ distance }}</span>
          </nut-col>
        </nut-row>
        <nut-row class="shopCheckInfo leftCenter" v-if="data.report == null">
          <span class="miniFont">本年已检</span>
          <span class="checkedNumber miniFont">{{ data.yearCheckCnt }}</span>
          <nut-tag class="warnTag" v-if="data.lastCheckTime == null||now - data.lastCheckTime>3*30*86400000">
            近3个月未检
          </nut-tag>
        </nut-row>
        <nut-row class="shopCheckInfo leftCenter" v-else>
          <span class="miniFont"
                style="color:rgba(228, 141, 31, 1);">{{ howManyDays(data.report.updated) }}天未整改</span>&nbsp;&nbsp;
          <nut-button class="improveBtn" size="mini" @click="$router.push('/shop/improve/'+data.report.id)">去整改
          </nut-button>
        </nut-row>
      </div>
    </nut-row>
  </div>
</template>

<script>
import ShopTypeTag from "@/components/ShopTypeTag";

export default {
  name: "ShopListItem",
  components: {ShopTypeTag},
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    myNavi: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  watch: {
    myNavi() {
      this.calDistance()
    }
  },
  data() {
    return {
      distance: '-',
      defaults: {
        noImage: 'https://cdn.waityou.online/1c9a5ee0-7746-1288-516d-cd115d2b0e24.png',
        now: new Date().getTime()
      }
    }
  },
  methods: {
    howManyDays(updated) {
      return parseInt((new Date().getTime() - updated) / 86400000)
    },
    haversine(lat1, lon1, lat2, lon2) {
      var earthRadius = 6371e3; // Earth's radius in meters
      var phi1 = lat1 * Math.PI / 180; // φ, λ in radians
      var phi2 = lat2 * Math.PI / 180;
      var deltaPhi = (lat2 - lat1) * Math.PI / 180;
      var deltaLambda = (lon2 - lon1) * Math.PI / 180;

      var a = Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
          Math.cos(phi1) * Math.cos(phi2) *
          Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      return parseInt(earthRadius * c); // Final distance in meters
    },
    calDistance() {
      if (this.myNavi.longitude && this.myNavi.latitude
          && this.data.longitude && this.data.latitude) {
        this.distance = this.haversine(this.myNavi.latitude,this.myNavi.longitude,
            this.data.latitude,this.data.longitude)+'m';
      }
    }
  },
  mounted() {
    this.calDistance()
  }
}
</script>

<style lang="scss" scoped>
.shopItemContainer {
  background-color: white;
  padding: 12px;
  border-radius: 5px;
  margin: 5px 0;

  .shopCheckInfo {
    font-size: $smallerFontSize;
    color: #999999;

    .checkedNumber {
      color: #7fbafa;
      padding: 0 5px;
    }

    .improveBtn {
      border-radius: 18px;
      background-color: rgba(255, 255, 255, 1);
      color: rgba(0, 118, 253, 1);
      border: 1px solid rgba(0, 118, 253, 1);
    }

    .warnTag {
      padding: 0 5px;
      border-radius: 18px;
      background-color: transparent;
      border: 1px solid rgba(228, 141, 31, 1);
      color: rgba(228, 141, 31, 1);
    }
  }

  .shopItemAddress {
    font-size: $miniFontSize;
    color: #666666;
    padding: 0;
  }

  .shopName {
    .shopTag {
      height: 18px;
    }

    span {
      font-size: $baseFontSize;
      margin-left: 5px;
    }
  }


  .shopItemInfo {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width: 100%;
  }

  .shopImage {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
}

</style>