<template>
  <div class="ShopCheckHistoryViewContainer">
    <InfiniteLoadingList :query-id="3" :init-query="{'shopId':shopId}">
      <template v-slot="data">
        <ShopCheckHistoryLine :data="data.data" :has-next="!data.last"/>
      </template>
    </InfiniteLoadingList>
  </div>
</template>

<script>
import ShopCheckHistoryLine from "@/components/shop/ShopCheckHistoryLine";
import InfiniteLoadingList from "@/components/InfiniteLoadingList";
export default {
  name: "ShopCheckHistoryView",
  components: {ShopCheckHistoryLine,InfiniteLoadingList},
  data() {
    return {
      shopId: this.$route.params.shopId,
      history:[]
    }
  },
  methods: {

  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
.ShopCheckHistoryViewContainer {
}
</style>