<template>
  <div class="BatteryPoliceEventListViewContainer">
    <nut-row>
      <nut-searchbar background="#4a71fd" placeholder="请输入关键字" v-model="search"
                     @search="updateQuery" @clear="updateQuery">
        <template v-slot:leftin>
          <nut-icon size="14" name="search2"></nut-icon>
        </template>
        <template v-slot:rightout>
          <span class="ft12" style="color:white" @click="updateQuery">搜索</span>
        </template>
      </nut-searchbar>
    </nut-row>
    <img src="https://cdn.waityou.online/83d08c72-7f30-3ae2-6340-557447c5d492.png" class="wd100"/>
    <div class="tabs" v-if="tabState==0">
      <img class="tab" src="https://cdn.waityou.online/3e4868c4-a0bf-5415-27bb-0c35ea2a4f06.png"/>
      <nut-row class="tabText bothCenter">
        <nut-col :span="6" class="bothCenter" @click="onTabChange(0)">
          <span class="active">110联动</span>
        </nut-col>
        <nut-col :span="12" class="bothCenter" @click="onTabChange(1)">
          <span>家暴警情</span>
        </nut-col>
        <nut-col :span="6" class="bothCenter" @click="onTabChange(2)">
          <span>反诈联动</span>
        </nut-col>
      </nut-row>
    </div>
    <div class="tabs" v-if="tabState==1">
      <img class="tab" src="https://cdn.waityou.online/57552a38-30b6-2ab9-1954-0796c6434eec.png"/>
      <nut-row class="tabText bothCenter">
        <nut-col :span="6" class="bothCenter" @click="onTabChange(0)">
          <span>110联动</span>
        </nut-col>
        <nut-col :span="12" class="bothCenter" @click="onTabChange(1)">
          <span class="active">家暴警情</span>
        </nut-col>
        <nut-col :span="6" class="bothCenter" @click="onTabChange(2)">
          <span>反诈联动</span>
        </nut-col>
      </nut-row>
    </div>
    <div class="tabs" v-if="tabState==2">
      <img class="tab" src="https://cdn.waityou.online/f884b8e0-a0fe-7798-5b73-c1e6882734ab.png"/>

      <nut-row class="tabText bothCenter">
        <nut-col :span="6" class="bothCenter" @click="onTabChange(0)">
          <span>110联动</span>
        </nut-col>
        <nut-col :span="12" class="bothCenter" @click="onTabChange(1)">
          <span>家暴警情</span>
        </nut-col>
        <nut-col :span="6" class="bothCenter" @click="onTabChange(2)">
          <span class="active">反诈联动</span>
        </nut-col>
      </nut-row>
    </div>
    <InfiniteLoadingList :query-id="7" :init-query="listQuery">
      <template v-slot="data">
        <TextPoliceEventItem :data="data.data" @click="$router.push('/police/event/detail/'+data.data.id)"/>
      </template>
    </InfiniteLoadingList>
    <div class="calendar" @click="calendarVisible=true">
      <span>{{ month }}</span>
    </div>
    <nut-popup position="right" :style="{ width: '30%', height: '100%' }" v-model:visible="calendarVisible">
      <IceDatePicker @month="chooseMonth" :year="year" :month="month"/>
    </nut-popup>
  </div>
</template>

<script>
import InfiniteLoadingList from "@/components/InfiniteLoadingList.vue";
import TextPoliceEventItem from "@/components/police/TextPoliceEventItem.vue";
import IceDatePicker from "@/components/police/IceDatePicker.vue";

export default {
  name: "BatteryPoliceEventListView",
  components: {IceDatePicker, TextPoliceEventItem, InfiniteLoadingList},
  data() {
    return {
      calendarVisible: false,
      tabState: this.$route.query.tab ? this.$route.query.tab : 0,
      list: [],
      month: 0,
      year: 0,
      search:null,
      listQuery: {},
    }
  },
  methods: {
    chooseMonth(month) {
      this.year = month.year;
      this.month = month.month;
      this.updateQuery()
    },
    onTabChange(tabId) {
      this.tabState = tabId;
      this.updateQuery()
    },
    updateQuery() {
      this.listQuery = {
        'typeId': parseInt(this.tabState) + 1,
        'year': this.year,
        'month': this.month,
        'search':this.search,
      }
    }
  },
  mounted() {
    this.month = new Date().getMonth() + 1;
    this.year = new Date().getFullYear();
    this.updateQuery()
  },
  beforeUnmount() {
  }
}
</script>

<style lang="scss" scoped>
.BatteryPoliceEventListViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;

  .calendar {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 180px;
    z-index: 99;
    width: 28px;
    height: 28px;
    background-image: url("https://cdn.waityou.online/b86e8840-e465-db1f-fb3b-b5b689c6da25.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    span {
      font-size: $miniFontSize;
      color: #1677FE;
      padding-bottom: 2px;
    }
  }
  .tabs {
    margin-top: -40px;
    .active {
      z-index: 99;
      color: #333333;
    }

    .tabText {
      margin-top: 12px;
    }

    span {
      z-index: 999;
      color: #999999;
      font-style: italic;
    }

    img {
      height: 44px;
      width: auto;
    }

    .tab {
      position: absolute;
      width: 100%;
    }

  }
}
</style>