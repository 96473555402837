<template>
  <nut-row class="PeopleCheckItemContainer leftCenter">
    <nut-col :span="10" class="leftCenter">
      <img :src="data.avatar"
           style="width: 48px;height: 48px;border-radius: 4px">
      <span class="baseFont ml12">{{ data.nickName ? data.nickName : data.userName }}</span>
    </nut-col>
    <nut-col :span="14" class="rightCenter">
      <div :class="code == 0?'active bothCenter smallFont':'unActive bothCenter smallFont'" @click="onNormal">
        <span>正常</span>
      </div>
      <div :class="code != 0?'active bothCenter smallFont':'unActive bothCenter smallFont'"
           style="margin-left: 12px;" @click="onMiss">
        <span>{{ missReason }}</span>
      </div>
    </nut-col>
  </nut-row>
  <nut-picker
      v-model:visible="show"
      :columns="states"
      title="缺勤原因选择"
      @confirm="confirm"
  >
  </nut-picker>
</template>

<script>
export default {
  name: "PeopleCheckItem",
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    states: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data() {
    return {
      code: 0,
      show: false,
      missReason: '-',
    }
  },
  methods: {
    confirm(e) {
      this.code = e.selectedValue[0];
      this.missReason = e.selectedOptions[0].text;
      this.$emit('check', e.selectedValue[0])
    },
    onNormal() {
      if (this.readOnly) {
        return
      }
      this.code = 0;
      this.$emit('check', '0')
    },
    onMiss() {
      console.log('fuck1')
      if (this.readOnly) {
        return
      }
      console.log('fuck2')
      this.show = true;
      // this.code = 1;
      // this.$emit('check', '1')
    },
    init() {
      this.missReason = this.states[0].text ? this.states[0].text : this.states[0].name;
      if (this.data.normal != null && !this.data.normal) {
        this.code = 1;
        this.missReason = this.states.find(s => s.value == this.data.state).name;
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.PeopleCheckItemContainer {
  display: flex;
  background-color: white;
  padding-bottom: 12px;

  .active {
    background: rgba(3, 102, 241, 0.10);
    border: 1px solid rgba(3, 102, 241, 1);
    border-radius: 16px;
    color: #0366F1;
    width: 78px;
    height: 28px;
  }

  .unActive {
    border: 1px solid #999999;
    border-radius: 16px;
    color: #999999;
    width: 78px;
    height: 28px;
  }
}
</style>