<template>
  <div class="CalendarContainer">
    <nut-row class="bothCenter vmg16">
      <nut-col :span="8" class="rightCenter">
        <nut-icon name="left" size="10" color="#1677FE" @click="yearMinus"></nut-icon>
      </nut-col>
      <nut-col :span="8" class="bothCenter">
        <span class="biggerFont boldFont">{{ year }}年</span>
      </nut-col>
      <nut-col :span="8" class="leftCenter">
        <nut-icon name="right" size="10" color="#1677FE" @click="yearAdd"></nut-icon>
      </nut-col>
    </nut-row>
  </div>
</template>

<script>
export default {
  name: "IceYearCalendar",
  methods: {
    yearMinus() {
      this.year -= 1
      this.$emit('changeYear', this.year)
    },
    yearAdd() {
      this.year = Math.min(this.maxYear, this.year + 1)
      this.$emit('changeYear', this.year)
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
      maxYear: new Date().getFullYear(),
    }
  },
  mounted() {
    this.$emit('changeYear', new Date().getFullYear())
  }
}
</script>

<style lang="scss" scoped>
.CalendarContainer {
  display: flex;
  flex-direction: column;

  .calendarRow {
    width: 100%;
    display: flex;

    .calendarItem {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 14.28%;

      .today {
        background-color: #1388ff;
        color: white;
        padding: 2px 5px;
        border-radius: 20px;
      }
    }
  }
}
</style>