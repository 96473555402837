<template>
  <div class="VideoMessageContainer">
    <LeftMessage v-if="myId != data.creatorId" :data="data">
      <img style="border-radius:5px;margin:5px 0;max-width: 80%;max-height:120px;object-fit: cover"
           @click="playVideo(data.imageUrl)"
           :src="`${data.imageUrl}?x-oss-process=video/snapshot,t_0`"/>
    </LeftMessage>
    <RightMessage v-if="myId == data.creatorId" :data="data">
      <img style="border-radius:5px;margin:5px 0;max-width: 80%;max-height:120px;object-fit: cover"
           @click="playVideo(data.imageUrl)"
           :src="`${data.imageUrl}?x-oss-process=video/snapshot,t_0`"/>
    </RightMessage>
  </div>
  <nut-overlay
      class="bothCenter"
      @close="playerVisible=false"
      :close-on-click-overlay="true"
      v-model:visible="playerVisible">
    <div class="colFlex bothCenter">
      <video v-if="playerVisible" :src="videoUrl" autoplay controls style="width: 100%"/>
      <div class="whiteFont smallFont bothCenter vmg16 wft" @click="playerVisible=false">
        <nut-icon name="close" size="12"></nut-icon>
        <span>&nbsp;关闭视频</span>
      </div>
    </div>
  </nut-overlay>
</template>

<script>
import LeftMessage from "@/components/im/LeftMessage";
import RightMessage from "@/components/im/RightMessage";

export default {
  name: "VideoMessage",
  components: {RightMessage, LeftMessage},
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    myId: {
      type: Number,
      default: -1,
    }
  },
  data() {
    return {
      playerVisible: false,
      videoUrl: null,
    }
  },
  methods: {
    playVideo(url) {
      this.videoUrl = url;
      this.playerVisible = true;
    },
  },
}
</script>

<style lang="scss" scoped>
.VideoMessageContainer {
  display: flex;
  flex-direction: column;
}
</style>