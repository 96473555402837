<template>
  <div class="CheckerSignViewContainer">
    <span v-if="mode==0">请检查人员在下方签名</span>
    <span v-else>请在下方签名</span>
    <span v-if="mode==0 && userInfo.signImage" class="link" @click="useMySign">使用我的签名</span>
    <span v-else>&nbsp;</span>
    <div style="width: 100%;height: 180px;position: relative">
      <VueSignaturePad
          v-if="show"
          ref="signaturePad"
          :options="options"
      ></VueSignaturePad>
      <img src="https://cdn.waityou.online/d52b2bf5-0fc6-2b3d-23c3-a6e29c5adc5b.png"
           @click="reset"
           style="position: absolute;width: 16px;right: 5px;bottom: 5px"/>
      <img src="https://cdn.waityou.online/6c16e78c-006d-db94-91ab-a779a7fcf25c.png"
           @click="fullScreen"
           style="position: absolute;width: 16px;right: 5px;top: 5px;z-index: 999"/>
    </div>
  </div>

</template>

<script>
import api from "@/api";

export default {
  name: "CheckerSignView",
  components: {},
  props: {
    mode: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      userInfo:{},
      show:false,
      fullSignVisible:false,
      options: {
        penColor: "#000",
        minWidth: 1,    //控制画笔最小宽度
        maxWidth: 1,    //控制画笔最大宽度
        backgroundColor: '#F5F5F5'
      },
    }
  },
  methods: {
    useMySign(){
      this.$emit("useMySign",this.userInfo.signImage)
    },
    reset() {
      console.log('reset!!!')
      this.$refs.signaturePad.clearSignature();
    },
    fullScreen() {
      this.$emit('full')
    },
    saveSignature() {
      const {data} = this.$refs.signaturePad.saveSignature();
      return data;
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.show=true;
    })
    if(this.mode==0){
      api.getUserInfo()
          .then(ret=>{
            this.userInfo = ret;
          })
    }
  }
}
</script>
<style>
.nut-signature-inner {
  background-color: #F5F5F5;
}
</style>
<style lang="scss" scoped>
.CheckerSignViewContainer {
  font-size: $smallerFontSize;
  display: flex;
  flex-direction: column;


  .link {
    margin: 10px 0;
    color: rgba(22, 119, 254, 1);
  }
}
</style>