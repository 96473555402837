<template>
  <div class="ShopImproveViewContainer">
    <div class="matterContainer">
      <div class="matterBoxItem" v-for="(m,mi) in matters" :key="mi">
        <ShopCheckBox :data="m" @click="matterId = m.id"/>
      </div>
    </div>
    <ShopCheckDetailView @improved="onImproved"
                         v-if="matterId"
                         :key="matterId"
                         :update-mode="true"
                         :set-matter-id="matterId"
                         :set-shop-id="shopId"/>
  </div>
</template>

<script>
import api from "@/api";
import ShopCheckBox from "@/components/shop/ShopCheckBox"
import ShopCheckDetailView from "@/views/shop/ShopCheckDetailView";

export default {
  name: "ShopImproveView",
  components: {ShopCheckDetailView, ShopCheckBox},
  data() {
    return {
      report: {},
      matters: [],
      shopId: null,
      reportId: this.$route.params.reportId,
      matterId: null,
    }
  },
  methods: {
    onImproved() {
      api.updateShopCheckReport(this.reportId)
          .then(() => {
            this.initMatters()
          })
    },
    init() {
      api.getShopCheckReport(this.reportId)
          .then(ret => {
            this.report = ret;
            this.shopId = ret.shopId;
            this.initMatters()
          })

    },
    initMatters() {
      api.getShopCheckMatters({
        shopId: this.shopId,
      }).then(ret => {
        this.matters = ret;
        if (!this.matterId) {
          this.matterId = ret[0].id;
        }
      });
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.ShopImproveViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F0F1F4;
  min-height: 100vh;

  .matterContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    padding: 12px 12px 0 12px;

    .matterBoxItem {
      border-radius: 8px;
      background-color: white;
      width: 45%;
      flex: 0 0 auto;
      margin-right: 8%;
    }
  }

  .matterContainer::-webkit-scrollbar {
    display: none; /* 隐藏滚动条 */
  }
}
</style>