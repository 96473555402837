<template>
  <div class="checkContainer">
    <nut-row class="leftCenter">
      <div class="dot"></div>
      <span class="baseFont boldFont">&nbsp;{{ data.itemName }}</span>
    </nut-row>
    <template v-for="(item,ii) in data.items" :key="ii">
    <nut-row>
      <nut-col :span="20" class="leftCenter vmg4">
        <span class="leftPadding8 lightBlack smallFont ">{{ item.itemName }}</span>
      </nut-col>
      <nut-col :span="4" class="tags">
        <span class="info miniFont" v-if="item.noProblem">无问题</span>
        <span class="danger miniFont" v-else>待整改</span>
      </nut-col>
    </nut-row>
    <nut-row class="leftCenter" v-if="item.problemImages.length > 0">
      <img class="leftPadding8"
           v-for="(img,imgIdx) in item.problemImages" :key="imgIdx"
           style="width: 80px;height: 80px;object-fit: cover"
           :src="img"/>
    </nut-row>
    </template>
    <nut-row class="leftCenter" v-for="(h,hi) in data.hiddenDangers" :key="hi">
      <nut-col :span="20" class="leftCenter">
        <span class="leftPadding8 smallFont lighterBlack">{{ h }}</span>
      </nut-col>
      <nut-col :span="4" class="tags">
        <span class="warning miniFont">隐患</span>
      </nut-col>
    </nut-row>
  </div>
</template>

<script>
export default {
  name: "ShopCheckItem",
  props: {
    data: {
      type: Object,
      default: ()=>{
        return {

        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.checkContainer {
  display: flex;
  flex-direction: column;
  padding: 15px;
  font-size: $baseFontSize;

  .tags {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 2px;
    font-size: $smallerFontSize;

    .info {
      background-color: rgba(22, 119, 254, 0.1);
      color: rgba(22, 119, 254, 1);
      padding: 0 5px;
    }
    .danger {
      background-color: rgba(245, 63, 63, 0.1);
      color: rgba(245, 63, 63, 1);
      padding: 0 5px;
    }

    .warning {
      background-color: rgba(255, 125, 1, 0.1);
      color: rgba(255, 125, 1, 1);
      padding: 0 5px;

    }

    .success {
      background-color: rgba(0, 180, 43, 0.1);
      color: rgba(0, 180, 43, 1);
      padding: 0 5px;

    }
  }


  .lightBlack {
    color: #333333;
  }
  .lighterBlack {
    color: #999999;
  }

  .padding15 {
    padding: 15px;
  }

  .leftPadding8 {
    padding: 2px 8px;
    text-align: left;
  }
}

.dot {
  width: 4px;
  height: 14px;
  line-height: 20px;
  border-radius: 2px;
  background-color: rgba(22, 119, 254, 1);
  text-align: center;
}
</style>