<template>
  <div class="RightMessageContainer">
    <nut-row>
      <nut-col :span="20" class="leftContent">
        <div class="rightCenter">
          <span class="minFont" style="color: black">{{ data.createdTime.substring(5, 19) }}&nbsp;</span>
          <span class="messageOwnerName">{{ data.creatorName }}</span>
        </div>
        <slot/>
        <nut-row v-if="data.refEventId" class="rightCenter">
          <span class="miniFont" style="color:#999999;">关联警情：{{ data.refEventNo }}</span>
        </nut-row>
      </nut-col>
      <nut-col :span="4" class="avatar">
        <img :src="data.creatorAvtar"/>
        <span class="messageOwnerName mt4"
              @click="rollbackMessage"
              v-if="canRollbackMessage">撤回</span>
      </nut-col>
    </nut-row>
  </div>
</template>

<script>
import {Dialog} from "@nutui/nutui";
import api from "@/api";

export default {
  name: "RightMessage",
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  computed: {
    canRollbackMessage() {
      return this.$store.state.imTime - this.data.created < 120 * 1000;
    }
  },
  methods: {
    rollbackMessage() {
      Dialog({
        title: '是否撤回该消息',
        content: '',
        cancelText: '取消',
        okText: '撤回',
        onOk: () => {
          api.rollbackMessage(this.data.id);
        }
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.RightMessageContainer {
  display: flex;
  flex-direction: column;

  .leftContent {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .messageOwnerName {
      color: #666666;
      font-size: $miniFontSize;
    }
  }

  .messageOwnerName {
    color: #666666;
    font-size: $miniFontSize;
  }

  .avatar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;


    img {
      width: 48px;
      height: 48px;
      border-radius: 5px;
    }
  }
}
</style>