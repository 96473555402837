<template>
  <div class="btnContainer">
    <nut-row class="selectorTitle">
      <span class="smallFont">选择{{ titleName }}</span>
    </nut-row>
    <nut-grid :column-num="3" :border="false">
      <nut-grid-item v-for="e in list" :key="e.id">
        <nut-button shape="rect"
                    style="padding: 0;margin-bottom: 8px"
                    @click="onBtnClick(e.id)"
                    :class="selected[e.id]?'selectorBtnActive':'selectorBtn'">{{ e[nameKey] }}
        </nut-button>
      </nut-grid-item>
    </nut-grid>
    <nut-row class="bothCenter">
      <nut-col :span="4" class="bothCenter">
        <div class="resetBtn bothCenter" @click="selected={}">
          <svg-icon icon-class="refresh" class-name="mini"/>
          <span>重置</span>
        </div>
      </nut-col>
      <nut-col :span="20" class="bothCenter">
        <nut-button class="confirmBtn" @click="submitFilter">确定</nut-button>
      </nut-col>
    </nut-row>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";

export default {
  name: "ShopSelectorButtons",
  components: {SvgIcon},
  props: {
    nameKey: {
      type: String,
      default: 'typeName'
    },
    titleName: {
      type: String,
      default: '社区'
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      selected: {}
    }
  },
  methods: {
    onBtnClick(id) {
      this.selected[id] = !this.selected[id];
    },
    submitFilter() {
      let s = []
      for (let e of this.list) {
        if (this.selected[e.id]) {
          s.push(e.id);
        }
      }
      this.$emit('confirm', s)
    },
  },
  mounted() {
    for (let e of this.list) {
      this.selected[e.id] = false;
    }
    const collapseItems = document.querySelectorAll('.nut-grid-item__content')
    collapseItems.forEach(item => {
      item.style.padding = '8px'
    })
  }
}
</script>

<style lang="scss" scoped>
.btnContainer {
  display: flex;
  flex-direction: column;

  .resetBtn {
    display: flex;
    flex-direction: column;
    font-size: $miniFontSize;
  }

  .confirmBtn {
    width: 90%;
    border: none;
    border-radius: 22px;
    background-color: rgba(26, 102, 255, 1);
    color: rgba(248, 248, 248, 1);
  }

  .selectorTitle {
    font-size: $baseFontSize;
  }

  .selectorBtn {
    border-radius: 2px;
    background-color: rgba(248, 248, 248, 1);
    color: rgba(102, 102, 102, 1);
    border: none;
    width: 100%;
    font-size: $miniFontSize;
  }

  .selectorBtnActive {
    border-radius: 2px;
    background-color: rgba(26, 102, 255, 0.1);
    color: rgba(22, 119, 254, 1);
    border: none;
    width: 100%;
    font-size: $miniFontSize;
  }

}
</style>