import {createStore} from 'vuex'

export default createStore({
    state: {
        user: {},
        env: 'h5',
        cachePages: ['ImView', 'BatteryPoliceEventListView','ShopListView','IndexView','ChatMessageSearchView'],
        banners: [],
        imTime: 0,
        imMessage: [],
        messageUUIds: [],
        imChatId: 0,
        imScrollHeight: 0,
        loadEarlierBlock: false,
    },
    getters: {},
    mutations: {
        clearCachePage(state, name) {
            let idx = state.cachePages.indexOf(name)
            if (idx >= 0) {
                state.cachePages.splice(idx, 1)
            }
        },
        addCachePage(state, name) {
            let idx = state.cachePages.indexOf(name)
            if (idx < 0) {
                state.cachePages.push(name)
            }
        },
        resetEarlierBlock(state, data) {
            console.log('resetEarlierBlock', data)
            state.loadEarlierBlock = data;
        },
        setImScrollHeight(state, h) {
            console.log('resetEarlierBlock', h)
            state.imScrollHeight = h
        },
        clearImMessage(state) {
            console.log('clearImMessage')
            state.imMessage = []
            state.messageUUIds = []
            state.imChatId = 0;
            state.imScrollHeight = 0;
            state.loadEarlierBlock = false;
        },
        updateImTime(state) {
            state.imTime = new Date().getTime();
        },
        pushMessage(state, data) {
            console.log('pushMessage')
            if (data.chatId != state.imChatId) {
                state.imChatId = data.chatId;
                state.messageUUIds = [];
                state.imMessage = [];
            }
            state.messageUUIds.push(data.messageUUID)
            state.imMessage.push(data)
        },
        rollback(state, uuid) {
            for (let om of state.imMessage) {
                if (om.messageUUID == uuid && om.messageType != 'ROLLBACK') {
                    console.log('消息已经被撤回:{}', om)
                    om.messageType = 'ROLLBACK';
                    break
                }
            }
        },
        unshiftMessage(state, m) {
            console.log('unshiftMessage')
            state.imMessage.unshift(m);
            state.messageUUIds.push(m.messageUUID);
        },
        addMessageUUID(state, uuid) {
            state.messageUUIds.push(uuid);
        },
        updateBanners(state, val) {
            state.banners = val
        },
        updateUser(state, val) {
            state.user = val;
        },
        updateEnv(state, e) {
            state.env = e;
        },
        setCachePage(state, page) {
            var index = state.cachePages.indexOf(page);
            if (index < 0) {
                state.cachePages.push(page);
            }
        },
        delCachePage(state, page) {
            var index = state.cachePages.indexOf(page);
            if (index > -1) {
                state.cachePages.splice(index, 1);
            }
        },
    },
    actions: {},
    modules: {}
})
