<template>
  <div class="CalendarContainer">
    <nut-row class="bothCenter vmg16">
      <nut-col :span="8" class="rightCenter">
        <nut-icon name="left" size="10" color="#1677FE" @click="yearMinus"></nut-icon>
      </nut-col>
      <nut-col :span="8" class="bothCenter">
        <span class="biggerFont boldFont">{{ year }}年</span>
      </nut-col>
      <nut-col :span="8" class="leftCenter">
        <nut-icon name="right" size="10" color="#1677FE" @click="yearAdd"></nut-icon>
      </nut-col>
    </nut-row>
    <div class="divider vmg8"></div>
    <div class="calendarRow vmg16 smallFont" v-for="(months,mi) in monthGroups" :key="mi">
      <div v-for="month in months" :key="month" class="calendarItem bothCenter" @click="onChangeMonth(month)">
        <span :class="month==selectedMonth?'today':'weekday'">{{ month }}月</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IceMonthCalendar",
  methods: {
    yearMinus() {
      this.year -= 1
      this.$emit('changeMonth', this.year * 100 + this.selectedMonth)
    },
    yearAdd() {
      this.year = Math.min(this.maxYear, this.year + 1)
      this.$emit('changeMonth', this.year * 100 + this.selectedMonth)
    },
    onChangeMonth(month) {
      this.selectedMonth = month;
      this.$emit('changeMonth', this.year * 100 + this.selectedMonth)
    },
  },
  data() {
    return {
      monthGroups: [[1, 2, 3, 4], [5, 6, 7, 8], [9, 10, 11, 12]],
      selectedMonth: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      maxYear: new Date().getFullYear(),
    }
  },
  mounted() {
    this.$emit('changeMonth', this.year * 100 + this.selectedMonth)
  }
}
</script>

<style lang="scss" scoped>
.CalendarContainer {
  display: flex;
  flex-direction: column;

  .calendarRow {
    width: 100%;
    display: flex;

    .calendarItem {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25%;

      .today {
        background-color: #1388ff;
        color: white;
        padding: 2px 5px;
        border-radius: 20px;
      }
    }
  }
}
</style>