<template>
  <div class="PeopleCheckViewContainer">
    <PeopleCheckItem class="checkItem" v-for="u in users"
                     read-only
                     :states="states"
                     :data="u" :key="u.id"/>
  </div>
</template>

<script>
import PeopleCheckItem from "@/components/check/PeopleCheckItem";
import api from "@/api";

export default {
  name: "PeopleCheckViewDetail",
  components: {PeopleCheckItem},
  data() {
    return {
      historyId: this.$route.params.historyId,
      users: [],
      states: [],
    }
  },
  methods: {
    init() {
      api.getDutyCheckHistory(this.historyId)
          .then(ret => {
            this.states = ret.state;
            this.users = ret.list;
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.PeopleCheckViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;

  .checkItem {
    margin-bottom: 22px;
  }
}
</style>