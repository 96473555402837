<template>
  <div class="CheckerItemContainer">
    <nut-row class="bothCenter">
      <nut-col :span="12" class="leftCenter">
        <img class="avatar" :src="data.avatar"/>
        <nut-row class="colFlex">
          <span class="userName">{{ data.nickName }}</span>
          <span class="orgName">{{ data.orgName }}</span>
        </nut-row>
      </nut-col>
      <nut-col :span="12" class="rightCenter">
        <span class="myself" v-if="mySelf">我</span>
        <span class="delete" @click="$emit('delete')">删除</span>
      </nut-col>
    </nut-row>
  </div>
</template>

<script>
export default {
  name: "CheckerItem",
  props: {
    mySelf: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: ()=>{
        return {

        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.CheckerItemContainer {
  display: flex;
  background-color: white;
  border-radius: 6px;
  padding: $miniFontSize;
  margin: 5px 0;

  .myself {
    font-size: $miniFontSize;
    border-radius: 2px;
    padding: 2px 20px;
    background-color: rgba(22, 119, 254, 0.1);
    color: rgba(22, 119, 254, 1);
  }

  .delete{
    font-size: $miniFontSize;
    border-radius: 2px;
    padding: 2px 16px;
    background-color: rgba(245, 63, 63, 0.1);
    color: rgba(245, 63, 63, 1);
    margin-left: 6px;
  }
  .userName {
    font-size: $baseFontSize;
  }

  .orgName {
    color: #333333;
    font-size: $miniFontSize;
    font-weight: lighter;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    margin-right: 10px;
  }
}
</style>