<template>
  <nut-row className="FullScreenSignContainer">
    <nut-col :span="3" class="leftCenter" style="transform: rotate(90deg);height: 30px;">
      <nut-button style="margin-left: 40vw;margin-top:2vw;width: 40vw" class="cancelBtn" @click="$emit('cancel')">取消</nut-button>
      <nut-button style="margin-left: 40vw;margin-top:2vw;width: 40vw" class="okBtn" @click="saveImage">完成
      </nut-button>
    </nut-col>
    <nut-col :span="21" class="rightCenter" style="position: relative">
      <VueSignaturePad
          v-if="show"
          ref="signaturePad"
          style="border:gray 1px dashed;z-index: 999"
          :options="options"
      ></VueSignaturePad>
      <img src="https://cdn.waityou.online/d52b2bf5-0fc6-2b3d-23c3-a6e29c5adc5b.png"
           @click="reset"
           style="position: absolute;width: 16px;left: 5px;bottom: 5px;"/>
    </nut-col>
  </nut-row>
  <nut-overlay v-model:visible="loading" class="bothCenter">
    <img src="https://cdn.waityou.online/3f021f5d-dc7f-0162-3bb6-0a39edd8ca76.png"
         style="width: 30px;" class="rotating"/>
  </nut-overlay>

</template>

<script>
import api from "@/api";

export default {
  name: "FullScreenSign",
  data() {
    return {
      w: window.innerWidth,
      h: window.innerHeight,
      show: false,
      loading: false,
      options: {
        penColor: "#000",
        minWidth: 1,    //控制画笔最小宽度
        maxWidth: 1,    //控制画笔最大宽度
        backgroundColor: '#fff'
      },
    }
  },
  methods: {
    saveImage() {
      this.loading=true;
      const {data} = this.$refs.signaturePad.saveSignature();
      api.signature2image({
        'data': data,
        'rotate': true
      }).then(url => {
        this.$emit('ok', url);
      }).finally(()=>{
        this.loading=false;
      })
    },
    reset() {
      this.$refs.signaturePad.clearSignature();
    },
  },
  mounted() {
    console.log(window.innerHeight)
    console.log(window.innerWidth)
    this.$nextTick(() => {
      this.show = true;
    })
  },
  computed: {},
}
</script>

<style lang="scss" scoped>
.rotating{
  animation: rotate 1s infinite linear;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.FullScreenSignContainer {
  background-color: #F5F5F5;
  display: flex;
  flex-direction: row;
  padding: 12px;
  height: calc(100vh - 24px);

  .okBtn {
    border-radius: 22px;
    border: none;
    background-color: rgba(22, 119, 254, 1);
    color: rgba(255, 255, 255, 1);
    font-size: $baseFontSize;
  }

  .cancelBtn {
    border-radius: 22px;
    border: none;
    font-size: $baseFontSize;
    color: rgba(51, 51, 51, 1);
    background-color: rgba(225, 225, 225, 1);
  }
}
</style>