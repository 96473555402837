<template>
  <div class="PeopleCheckViewContainer">
    <template v-if="state">
      <nut-row class="bothCenter colFlex" style="height: 60vh">
        <img src="https://cdn.waityou.online/1e20f367-5935-bde4-c8af-ae51a918519b.png"
             style="width: 100px;"/>
        <span class="smallFont mt16" style="color: #999999">今日已完成点验</span>
        <span @click="$router.push('/duty/check/history/'+data.id)"
        style="color: #1388ff;margin-top: 16px">查看点验结果</span>
      </nut-row>
    </template>
    <template v-else>
      <PeopleCheckItem v-for="u in users"
                       @check="r=>onChecked(u.id,r)"
                       :states="states"
                       :data="u" :key="u.id"/>
      <OssUploader :limit="9" @onUploadedFiles="onUploadedFiles"/>
      <nut-textarea v-model="remark" limit-show max-length="200" placeholder="可选输入备注"></nut-textarea>
      <nut-button type="primary"
                  class="mt16"
                  @click="confirm"
                  style="width: 100%" size="large">确认
      </nut-button>
    </template>
  </div>
</template>

<script>
import PeopleCheckItem from "@/components/check/PeopleCheckItem";
import api from "@/api";
import OssUploader from "@/components/OssUploader";

export default {
  name: "PeopleCheckView",
  components: {OssUploader, PeopleCheckItem},
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data() {
    return {
      state: true,
      users: [],
      check: {},
      states: [],
      images:[],
      remark:null,
    }
  },
  methods: {
    onUploadedFiles(list){
      this.images = list;
    },
    confirm() {
      let users = []
      for (let uid of Object.keys(this.check)) {
        users.push({
          userId: uid,
          state: this.check[uid]
        })
      }
      let data = {
        orgId: this.data.orgId,
        'users': users,
        'images':this.images,
        'remark':this.remark,
      }
      api.submitDutyCheckResult(data)
          .then(id => {
            this.$router.push('/duty/check/history/' + id);
          })
    },
    onChecked(uid, code) {
      this.check[uid] = code;
    },
    init() {
      this.states = this.data.states;
      if (this.data.state) {
        //已查验
        this.state = true;
      } else {
        this.state = false;
        this.users = this.data.list;
        for (let u of this.data.list) {
          this.check[u.id] = 0;
        }
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.PeopleCheckViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px 16px 60px 16px;
  height: 80vh;
  overflow-y: scroll;
}
</style>