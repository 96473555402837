<template>
  <div class="InfiniteLoadingListContainer">
    <nut-infiniteloading
        :has-more="hasMore"
        @load-more="loadMore"
        @refresh="refresh"
        load-icon="loading"
        pull-icon="loading"
        :load-more-txt="loadMoreText"
        :is-open-refresh="true"
    >
      <template v-for="(p,i) in list" :key="i">
        <slot :list="list" :data="p" :index="i" :last="!hasMore && i ==list.length-1"/>
      </template>
    </nut-infiniteloading>
    <nut-backtop :distance="200"></nut-backtop>
  </div>
</template>

<script>
import api from "@/api"

export default {
  name: "InfiniteLoadingList",
  props: {
    loadMoreText: {
      type: String,
      default: '没有更多数据了'
    },
    method: {
      type: String,
      default: 'GET'
    },
    queryId: {
      type: Number,
      default: 0,
    },
    initQuery: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  watch: {
    initQuery() {
      this.refresh()
    }
  },
  data() {
    return {
      hasMore: false,
      page: 0,
      pageSize: 10,
      list: [],
    }
  },
  methods: {
    refresh(done) {
      this.hasMore = true;
      this.page = 0;
      this.list = [];
      this.loadMore(done)
    },
    loadMore(done) {
      const cur_page = this.page;
      let query = {
        page: cur_page,
        pageSize: this.pageSize
      }
      this.page += 1;
      for (let k of Object.keys(this.initQuery)) {
        query[k] = this.initQuery[k];
      }
      api.getInfiniteLoadingList(this.queryId, this.method, query)
          .then(ret => {
            if(ret.page == 0){
              this.list = [];
            }
            if (ret.list) {
              for (let o of ret.list) {
                this.list.push(o)
              }
            }
            if (!ret || ret.list.length < this.pageSize) {
              this.hasMore = false;
            }
          })
          .finally(() => {
            if (done) {
              done();
            }
          })
    }
  },
  mounted() {
    this.refresh()
  }
}
</script>

<style lang="scss" scoped>
.InfiniteLoadingListContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
</style>