<template>
  <div class="checkBoxContainer">
    <nut-row class="leftCenter">
      <span class="title baseFont boldFont">{{ data.matterName }}</span>
    </nut-row>
    <nut-row class="leftCenter vmg2">
      <span class="lightText miniFont">上次检查时间：{{ data.history?data.history.lastCheckDate:'无' }}</span>
    </nut-row>
    <nut-row class="leftCenter vmg2">
      <span class="lightText miniFont">检查人员：{{ data.history?data.history.lastCheckerUserName:'无' }}</span>
    </nut-row>
    <nut-row class="leftCenter vmg2">
      <span class="lightText miniFont">组织：{{ data.history?data.history.lastCheckerOrgName:'-' }}</span>
    </nut-row>
    <nut-row class="leftCenter mt4">
      <nut-col :span="12">
        <div v-if="data?.history?.problemCnt>0" class="problemRed miniFont">问题项:{{ data?.history?.problemCnt }}</div>
        <div v-else class="problemBlue miniFont">问题项:-</div>
      </nut-col>
      <nut-col :span="12" class="rightCenter" v-if="data?.history?.problemCnt>0">
        <span class="yellow miniFont">修改排查</span>
      </nut-col>
      <nut-col :span="12" class="rightCenter" v-else>
        <span class="blue miniFont">开始排查</span>
      </nut-col>
    </nut-row>
    <img v-if="isTodayCheck"
         class="tagImg" src="https://cdn.waityou.online/7ca4cf38-977d-7b00-2e58-eeb9c6990c1f.png"/>
  </div>
</template>

<script>
export default {
  name: "ShopCheckBox",
  props: {
    data:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    info:{
      type:Object,
      default:()=>{
        return {
          time:'-',
          checkerName:'-',
          orgName:'-'
        }
      }
    }
  },
  methods: {
    todayDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  },
  computed: {
    isTodayCheck() {
      return this.data?.history?.updatedTime?.startsWith(this.todayDate());
    }
  },
}
</script>

<style lang="scss" scoped>
.checkBoxContainer {
  width: 100%;
  background-color: white;
  position: relative;
  padding: 8px;
  border-radius: 8px;

  .tagImg {
    position: absolute;
    right: 0;
    top: 0;
    width: 45px;
  }

  .blue {
    color: #1677FE;
  }

  .yellow {
    color: #FF7D01;
  }

  .title {
  }

  .lightText {
    color: #999999;
    font-weight: lighter;
  }

  .problemRed {
    width: 66px;
    text-align: center;
    background-color: rgba(245, 63, 63, 0.1);
    color: rgba(245, 63, 63, 1);
  }
  .problemBlue {
    width: 66px;
    text-align: center;
    background-color: rgba(22, 119, 254, 0.1);
    color: rgba(22, 119, 254, 1);
  }
}
</style>