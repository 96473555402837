<template>
  <div class="SmoothAreaChartContainer" :id="id">

  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: "BarChart",
  props: {
    id: {
      type: String,
      default: 'BarChart'
    },
    data: {
      type: Object,
      default: () => {
        return {
          x: [],
          y: [],
        }
      }
    }
  },
  watch: {
    data() {
      this.initChart()
    }
  },
  methods: {
    initChart() {
      if (!this.data.y || this.data.y.length < 1) {
        return
      }
      let container = document.getElementById(this.id);
      let exist = echarts.getInstanceByDom(container);
      if (exist) {
        console.log(this.id, '销毁已存在的');
        exist.dispose();
      }
      let chart = echarts.init(container);
      let option = {
        grid: {
          top: '10%',
          left: '0%',
          right: '0%',
          containLabel: true
        },
        xAxis: {
          show: true,
          data: this.data.x
        },
        yAxis: {
          show: true,
          type: 'value',
          minInterval:1,
          itemStyle: {}
        },
        series: [
          {
            data: this.data.y,
            type: 'bar',
            barMaxWidth: '10%',
            itemStyle: {
              color: '#1388ff',
            },
          }
        ]
      };
      chart.setOption(option);
      console.log('初始化完成')
    }
  },
  mounted() {
    this.initChart()
  }
}
</script>

<style lang="scss" scoped>
.SmoothAreaChartContainer {
  width: 100%;
  height: 100%;
}
</style>