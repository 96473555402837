<template>
  <div class="shopDetailContainer">
    <div class="imageBackGround"
         :style="{width:'100vw',height:'75vw',backgroundImage:`url(${shop.shopImage})`}">
      <CustomOssUploader :limit="1" @onUploadedFiles="onUploadedShopImage"
                         style="position:absolute;top: 10px;right: 10px;">
        <img style="width: 40px;height: 40px;"
             src="https://cdn.waityou.online/dfee82e2-d70f-ffd5-a3b4-e1377ac8ec4a.png"/>
      </CustomOssUploader>
    </div>
    <div style="margin-top: -30px;padding-bottom: 100px">
      <div class="shopDetailInfo">
        <nut-row class="leftCenter">
          <span class="lighterText">主体名称</span>
        </nut-row>
        <nut-row class="leftCenter">
          <nut-input class="shopInfoInput" placeholder="请输入" v-model="shop.licenseName"/>
        </nut-row>
        <nut-row class="leftCenter">
          <span class="lighterText">店招名</span>
        </nut-row>
        <nut-row class="leftCenter">
          <nut-input class="shopInfoInput" placeholder="请输入" v-model="shop.shopName"/>
        </nut-row>
        <nut-row class="leftCenter">
          <span class="lighterText">类型</span>
        </nut-row>
        <nut-row class="leftCenter">
          <span @click="shopTypePickerVisible=true">{{ shop.shopTypeName }}</span>
        </nut-row>
        <div class="divider"/>
        <nut-row class="leftCenter">
          <span class="lighterText">营业执照</span>
        </nut-row>
        <nut-row class="leftCenter">
          <CustomOssUploader :limit="1" @onUploadedFiles="onUploadedShopLicenseImage">
            <img v-if="shop.licenseImage" :src="shop.licenseImage"
                 style="width: 100px;height: 100px;object-fit: cover"/>
            <div v-else class="noPhoto bothCenter">
              <img style="width: 40px;height: 40px;"
                   src="https://cdn.waityou.online/dfee82e2-d70f-ffd5-a3b4-e1377ac8ec4a.png"/>
            </div>
          </CustomOssUploader>
        </nut-row>
        <div class="divider"></div>
        <nut-row class="leftCenter">
          <span class="lighterText">详细地址</span>
        </nut-row>
        <nut-row class="leftCenter">
          <nut-col :span="20">
            <nut-input class="shopInfoInput" placeholder="请输入" v-model="shop.address"/>
          </nut-col>
          <nut-col :span="4" class="location rightCenter">
            <img src="https://cdn.waityou.online/a2645b44-5160-838e-98cf-2fb7890552e0.png"/>
            <span class="smallFont">定位</span>
          </nut-col>
        </nut-row>
        <nut-row class="leftCenter">
          <span class="lighterText">门牌号</span>
        </nut-row>
        <nut-row class="leftCenter">
          <nut-col :span="12">
            <span @click="streetNamePickerVisible=true">{{ shop.streetName }}</span>
          </nut-col>
          <nut-col :span="1">
            <span>/</span>
          </nut-col>
          <nut-col :span="11">
            <nut-input class="shopInfoInput" placeholder="请输入" v-model="shop.doorCode"/>
          </nut-col>
        </nut-row>
        <nut-row class="leftCenter">
          <span class="lighterText">所属社区</span>
        </nut-row>
        <nut-row class="leftCenter" @click="communityPickerVisible=true">
          <span>{{ shop.communityName }}</span>
        </nut-row>
        <div class="divider"></div>
        <nut-row class="leftCenter">
          <span class="lighterText">联系人</span>
        </nut-row>
        <nut-row class="leftCenter">
          <nut-input class="shopInfoInput" placeholder="请输入" v-model="shop.contactName"/>
        </nut-row>
        <nut-row class="leftCenter">
          <span class="lighterText">手机号码</span>
        </nut-row>
        <nut-row class="leftCenter">
          <nut-input class="shopInfoInput" placeholder="请输入" v-model="shop.contactInfo"/>
        </nut-row>
        <nut-row class="leftCenter">
          <span class="lighterText">社会信用代码</span>
        </nut-row>
        <nut-row class="leftCenter">
          <nut-input class="shopInfoInput" placeholder="请输入" v-model="shop.licenseCode"/>
        </nut-row>
      </div>
      <div class="submitBtnContainer bothCenter">
        <nut-button class="submitBtn" @click="saveEdit">提交</nut-button>
      </div>
    </div>
  </div>

  <nut-picker
      v-model:visible="shopTypePickerVisible"
      :columns="shopTypes"
      title="店铺类型选择"
      @confirm="confirmShopType"
  >
  </nut-picker>
  <nut-picker
      v-model:visible="streetNamePickerVisible"
      :columns="streets"
      title="街道选择"
      @confirm="confirmStreet"
  >
  </nut-picker>
  <nut-picker
      v-model:visible="communityPickerVisible"
      :columns="communities"
      title="社区选择"
      @confirm="confirmCommunity"
  >
  </nut-picker>
</template>

<script>

import api from "@/api";
import CustomOssUploader from "@/components/CustomOssUploader"

export default {
  name: "ShopDetailInfoEditView",
  components: {CustomOssUploader},
  data() {
    return {
      communityPickerVisible: false,
      streetNamePickerVisible: false,
      shopTypePickerVisible: false,
      shopId: this.$route.params.shopId,
      shop: {},
      shopTypes: [],
      streets: [],
      communities: [],
    }
  },
  methods: {
    saveEdit() {
      api.updateShop(this.shop)
          .then(() => {
            this.$router.back();
          })
    },
    onUploadedShopImage(files) {
      this.shop.shopImage = files[0];
    },
    onUploadedShopLicenseImage(files) {
      this.shop.licenseImage = files[0];
    },
    confirmShopType(t) {
      let selected = t.selectedOptions[0]
      this.shop.shopTypeId = selected.value;
      this.shop.shopTypeName = selected.text;
    },
    confirmStreet(t) {
      let selected = t.selectedOptions[0]
      this.shop.streetId = selected.value;
      this.shop.streetName = selected.text;
    },
    confirmCommunity(t) {
      let selected = t.selectedOptions[0]
      this.shop.communityId = selected.value;
      this.shop.communityName = selected.text;
    },
    getShopDetail() {
      api.getShopDetail(this.shopId)
          .then(ret => {
            this.shop = ret.shop;
          })
    }
  },
  mounted() {
    this.getShopDetail();
    api.getShopTypes()
        .then(ret => {
          let types = []
          for (let r of ret) {
            types.push({
              text: r.typeName,
              value: r.id
            })
          }
          this.shopTypes = types;
        })
    api.getStreetList()
        .then(ret => {
          let streets = []
          for (let r of ret) {
            streets.push({
              text: r.streetName,
              value: r.id
            })
          }
          this.streets = streets;
        })
    api.getCommunities()
        .then(ret => {
          let communities = []
          for (let r of ret) {
            communities.push({
              text: r.communityName,
              value: r.id
            })
          }
          this.communities = communities;
        })
  }
}
</script>

<style lang="scss" scoped>
.shopDetailContainer {
  width: 100%;
  min-height: 100vh;
  background-color: #F6F6F6;

  .location {
    img {
      width: 10px;
      height: 13px;
    }

    span {
      color: rgba(102, 102, 102, 1);
      font-weight: lighter;
      padding-left: 5px;
    }
  }

  .noPhoto {
    background-color: rgba(246, 246, 246, 1);
    color: rgba(16, 16, 16, 1);
    height: 100px;
    width: 100px;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: rgba(242, 242, 242, 1);
    margin: 5px 0;
  }

  .submitBtnContainer {
    background-color: white;
    position: fixed;
    width: 100%;
    bottom: 0;

    .submitBtn {
      background-color: rgba(22, 119, 254, 1);
      color: rgba(255, 255, 255, 1);
      border: none;
      width: 90%;
      margin: 10px;
    }

    .stopBtn {
      background-color: #FF7D01;
      color: rgba(255, 255, 255, 1);
      border: none;
      width: 90%;
      margin: 10px;
    }
  }


  .boxContainers {
    padding: 10px;

    .boxContainerL {
      padding: 4px 4px 4px 0;
    }

    .boxContainerR {
      padding: 4px 0 4px 4px;
    }
  }


  .lighterText {
    font-size: $smallerFontSize;
    color: #666666;
    font-weight: lighter;
    padding: 5px 0;
  }

  .checkHistory {
    font-size: $smallerFontSize;
    color: #1677FE;
  }

  .shopDetailInfo {
    background-color: white;
    padding: 10px;
    margin: 0 10px;
    border-radius: 15px 15px 0 0;
    font-size: $baseFontSize;

    .shopInfoInput {
      padding: 5px 0;
    }

    .ShopInfo {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>