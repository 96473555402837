<template>
  <div class="ChatGroupContainer">
    <nut-row style="display: flex">
      <img :src="data.image"
           style="width: 48px;height: 48px;object-fit: cover;border-radius: 8px;margin-right: 8px"/>
      <nut-row class="colFlex" style="box-sizing: border-box;width: calc(100vw - 88px)">
        <nut-row style="width: 100%">
          <nut-col :span="16" class="leftCenter">
            <span class="baseFont boldFont">{{data.chatName}}</span>
          </nut-col>
          <nut-col :span="8" class="rightCenter">
            <span class="miniFont" style="color: #666666">
              {{data.lastMessage?data.lastMessage.createdTime.substring(5,16):data.createdTime.substring(5,16)}}
            </span>
          </nut-col>
        </nut-row>
        <span v-if="!data.lastMessage" class="miniFont mt8" style="color: #666666">暂无消息</span>
        <template v-else-if="data.lastMessage.messageType == 'TEXT'">
          <span class="miniFont mt8" style="color: #666666" v-if="data.lastMessage.textContent.length>30">
            {{data.lastMessage.textContent.substring(0,30)}}...
          </span>
          <span class="miniFont mt8" style="color: #666666" v-else>{{data.lastMessage.textContent}}</span>
        </template>

        <span class="miniFont mt8" style="color: #666666" v-else-if="data.lastMessage.messageType == 'IMAGE'">[图片消息]</span>
        <span class="miniFont mt8" style="color: #666666" v-else-if="data.lastMessage.messageType == 'VOICE'">[语音消息]</span>
        <span class="miniFont mt8" style="color: #666666" v-else-if="data.lastMessage.messageType == 'CARD'">[卡片消息]</span>
        <span class="miniFont mt8" style="color: #666666" v-else-if="data.lastMessage.messageType == 'NOTICE'">[系统提示]</span>
        <span class="miniFont mt8" style="color: #666666" v-else-if="data.lastMessage.messageType == 'ROLLBACK'">[消息被撤回]</span>
        <span class="miniFont mt8" style="color: #666666" v-else-if="data.lastMessage.messageType == 'ROLLBACK_UPDATE'">[消息被撤回]</span>
      </nut-row>
    </nut-row>
  </div>
</template>

<script>
export default {
  name: "ChatGroup",
  props: {
    data: {
      type: Object,
      default: ()=>{
        return {

        }
      }
    },
  },
  methods: {
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.ChatGroupContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 8px 16px ;
}
</style>