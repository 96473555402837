<template>
  <div class="ReportFormViewContainer">
    <div class="publishForm  colFlex">
      <div class="title leftCenter">
        <span class="red">*</span>
        <span>{{ subTypeName }}报道</span>
      </div>
      <div class="divider"></div>
      <div class="uploadForm bothCenter colFlex">
        <CustomOssUploader :limit="1" @onUploadedFiles="fs=>firstImage = fs[0]">
          <div class="colFlex bothCenter" v-if="firstImage">
            <img :src="firstImage" style="height: 128px"/>
          </div>
          <div class="colFlex bothCenter" v-else>
            <img src="https://cdn.waityou.online/9edb65a6-c925-bf0a-9e79-e33844cbaa7c.png" style="height: 100px"/>
            <span>上传照片</span>
          </div>
        </CustomOssUploader>
      </div>
    </div>
    <div class="publishForm  colFlex">
      <div class="title leftCenter">
        <span class="red">*</span>
        <span>护岗记录</span>
      </div>
      <div class="divider"></div>
      <nut-textarea placeholder="请输入事件描述"
                    style="padding: 10px"
                    v-model="content"
                    rows="5"></nut-textarea>
    </div>


    <div class="bottomBtn bothCenter">
      <nut-button class="submitBtn" @click="report">
        <span>{{ subTypeName }}</span>
        <span class="time">{{currentTime}}</span>
      </nut-button>
    </div>
  </div>

</template>

<script>
import {Toast} from "@nutui/nutui";
import api from "@/api";
import CustomOssUploader from "@/components/CustomOssUploader"
export default {
  name: "OnOutPositionView",
  components:{CustomOssUploader},
  data() {
    return {
      firstImage: null,
      content: null,
      subTypeName: "到岗",
    }
  },
  methods: {
    report() {
      if (!this.firstImage) {
        Toast.warn("请上传图片")
        return
      }
      if (!this.content || this.content.length < 1) {
        Toast.warn("请输入事件描述")
        return
      }
      api.report({
        eventTypeId: 2,
        content: this.content,
        firstImage: this.firstImage,
        subTypeName: this.subTypeName,
      }).then(() => {
        Toast.success('上报成功');
        this.$router.back()
      })
    },
    formatTimeUnit(unit) {
      if (unit < 10) {
        return "0" + unit;
      }
      return unit;
    }
  },
  mounted() {
    api.queryReportExist({
      eventTypeId: 2,
    }).then(exist => {
      if (exist) {
        this.subTypeName = '离岗'
      }
    })
  },
  computed: {
    currentTime() {
      var currentDate = new Date();
      var hours = currentDate.getHours();
      var minutes = currentDate.getMinutes();
      var seconds = currentDate.getSeconds();
      hours = this.formatTimeUnit(hours);
      minutes = this.formatTimeUnit(minutes);
      seconds = this.formatTimeUnit(seconds);
      return hours + ":" + minutes + ":" + seconds;
    }
  },
}
</script>

<style lang="scss" scoped>
.ReportFormViewContainer {
  background-color: #F0F1F4;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;

  .bottomBtn {
    display: flex;
    width: 100%;
    margin-top: 20px;

    .submitBtn {
      border: none;
      border-radius: 50px;
      background-color: #1677FE;
      color: white;
      width: 100px;
      height: 100px;
      font-size: $baseFontSize;
      box-shadow: 0px 2px 6px 0px rgba(22, 119, 254, 100);

      .time {
        font-size: $smallerFontSize;
      }
    }
  }


  .publishForm {
    width: 100%;
    display: flex;
    background-color: white;
    border: 1px dashed rgba(187, 187, 187, 1);
    border-radius: 4px;
    margin: 5px 0;

    .divider {
      width: 100%;
      height: 1px;
      background-color: #E8E8E8;
    }

    .title {
      display: flex;
      padding: 10px;

      .red {
        color: red;
      }

      span {
        font-size: $baseFontSize;
      }
    }

    .uploadForm {
      display: flex;
      width: 100%;
      height: 138px;

      img {
        height: 128px;
      }

      span {
        padding: 10px 0;
        font-size: $miniFontSize;
        color: #999999;
      }
    }
  }

}
</style>