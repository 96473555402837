import {createRouter, createWebHashHistory} from 'vue-router'
import LoginView from "@/views/LoginView";
import IndexView from "@/views/IndexView";
import MyView from "@/views/MyView";
import LogoutView from "@/views/LogoutView";
import PoliceEventDetailView from "@/views/police/PoliceEventDetailView";
import ShopEventDetailView from "@/views/shop/ShopEventDetailView";
import ShopListView from "@/views/shop/ShopListView";
import ShopSearchView from "@/views/shop/ShopSearchView";
import ShopDetailView from "@/views/shop/ShopDetailView";
import ShopDetailInfoView from "@/views/shop/ShopDetailInfoView";
import ShopDetailInfoEditView from "@/views/shop/ShopDetailInfoEditView";
import ShopCheckHistoryView from "@/views/shop/ShopCheckHistoryView";
import ShopCheckDetailView from "@/views/shop/ShopCheckDetailView";
import ShopCheckFinishView from "@/views/shop/ShopCheckFinishView";
import BatteryPoliceEventListView from "@/views/police/BatteryPoliceEventListView";
import PoliceEventPublishView from "@/views/police/PoliceEventPublishView";
import ReportTypeListView from "@/views/report/ReportTypeListView";
import ReportFormView from "@/views/report/ReportFormView";
import OnOutPositionView from "@/views/report/OnOutPositionView";
import DataQueryView from "@/views/query/DataQueryView";
import ReportedListView from "@/views/query/ReportedListView";
import ImView from "@/views/im/ImView";
import MySignView from "@/views/sign/MySignView";
import AddSignView from "@/views/sign/AddSignView";
import SettingView from "@/views/my/SettingView";
import RankView from "@/views/shop/RankView";
import CheckCalendarView from "@/views/shop/CheckCalendarView";
import MessageView from "@/views/setting/MessageView";
import AboutUsView from "@/components/AboutUsView";
import TestView from "@/views/TestView";
import ShopReportOpenReceiveView from "@/views/shop/ShopReportOpenReceiveView";
import NoLoginView from "@/views/noLoginView";
import ClockHistoryView from "@/views/my/ClockHistoryView";
import NameUpdateView from "@/views/my/NameUpdateView";
import PasswordSetting from "@/views/my/PasswordSetting";
import ShopImproveView from "@/views/shop/ShopImproveView";
import ChatListView from "@/views/im/ChatListView";
import ReportToDutyCheckView from "@/views/check/ReportToDutyCheckView";
import PeopleCheckViewDetail from "@/views/check/PeopleCheckViewDetail";
import OtherReportFormView from "@/views/report/OtherReportFormView";
import ReportEventDetailView from "@/views/event/ReportEventDetailView";
import RoadSurfaceInspectionView from "@/views/report/RoadSurfaceInspectionView";
import ShopClerkView from "@/views/shop/ShopClerkView";
import PoliceHandleRankView from "@/views/PoliceHandleRankView";
import ChatMessageSearchView from "@/views/im/ChatMessageSearchView.vue";


const routes = [{
    path: '/', name: 'index', component: IndexView, meta: {
        auth: false, title: '小河ZHI街'
    }
}, {
    path: '/logout', name: 'logout', component: LogoutView, meta: {
        auth: false, title: '已退出登录'
    }
}, {
    path: "/login", name: "login", component: LoginView, meta: {
        auth: false, title: '登录小河ZHI街'
    }
}, {
    path: "/nonLogin", name: "noLogin", component: NoLoginView, meta: {
        auth: false, title: '登录小河ZHI街'
    }
}, {
    path: '/my', name: 'my', component: MyView, meta: {
        auth: true, title: '我的'
    }
}, {
    path: '/police/event/detail/:eventId', name: 'PoliceEventDetailView', component: PoliceEventDetailView, meta: {
        auth: true, title: '警情看板'
    }
}, {
    path: '/shop/check/report/:reportId', name: 'ShopEventDetailView', component: ShopEventDetailView, meta: {
        auth: true, title: '商铺治理'
    }
}, {
    path: '/report/event/detail/:eventId', name: 'ReportEventDetailView', component: ReportEventDetailView, meta: {
        auth: true, title: '上报事件详情'
    }
}, {
    path: '/shop/list', name: 'ShopList', component: ShopListView, meta: {
        auth: true, title: '商铺'
    }
}, {
    path: '/shop/search', name: 'ShopSearch', component: ShopSearchView, meta: {
        auth: true, title: '搜索'
    }
}, {
    path: '/shop/detail/:shopId', name: 'ShopDetail', component: ShopDetailView, meta: {
        auth: true, title: '检查'
    }
}, {
    path: '/shop/info/:shopId', name: 'ShopDetailInfo', component: ShopDetailInfoView, meta: {
        auth: true, title: '商铺详情'
    }
}, {
    path: '/shop/clerk/:shopId', name: 'ShopClerkView', component: ShopClerkView, meta: {
        auth: true, title: '商铺人员'
    }
},{
    path: '/shop/info/edit/:shopId', name: 'ShopDetailInfoEdit', component: ShopDetailInfoEditView, meta: {
        auth: true, title: '编辑详情'
    }
},{
    path: '/shop/check/history/:shopId', name: 'ShopCheckHistory', component: ShopCheckHistoryView, meta: {
        auth: true, title: '检查历史'
    }
},{
    path: '/shop/check/detail/:matterId/:shopId', name: 'ShopCheckDetail', component: ShopCheckDetailView, meta: {
        auth: true, title: '检查小项'
    }
},{
    path: '/shop/check/result/:reportId', name: 'ShopCheckResult', component: ShopCheckFinishView, meta: {
        auth: true, title: '检查完成'
    }
},{
    path: '/police/event/list', name: 'BatteryPoliceEventListView', component: BatteryPoliceEventListView, meta: {
        auth: true, title: '警情列表'
    }
},{
    path: '/police/event/publish', name: 'PublishPoliceEvent', component: PoliceEventPublishView, meta: {
        auth: true, title: '发布警情'
    }
},{
    path: '/report/type/list', name: 'ReportTypeList', component: ReportTypeListView, meta: {
        auth: true, title: '事件上报'
    }
},{
    path: '/report/form/:reportType', name: 'ReportForm', component: ReportFormView, meta: {
        auth: true, title: '上报信息'
    }
},{
    path: '/report/form/other', name: 'OtherReportForm', component: OtherReportFormView, meta: {
        auth: true, title: '其他信息上报'
    }
},{
    path: '/report/form/road', name: 'RoadSurfaceInspectionView', component: RoadSurfaceInspectionView, meta: {
        auth: true, title: '路面巡查'
    }
},{
    path: '/report/position', name: 'ReportPosition', component: OnOutPositionView, meta: {
        auth: true, title: '到岗离岗报道'
    }
},{
    path: '/data/query', name: 'DataQuery', component: DataQueryView, meta: {
        auth: true, title: '数据查询'
    }
},{
    path: '/report/list', name: 'ReportList', component: ReportedListView, meta: {
        auth: true, title: '上报事件列表'
    }
},{
    path: '/im/:chatId', name: 'IMView', component: ImView, meta: {
        auth: true, title: '应急会商'
    }
},{
    path: '/im/message/search/:chatId', name: 'ChatMessageSearchView', component: ChatMessageSearchView, meta: {
        auth: true, title: '聊天记录搜索'
    }
},{
    path: '/my/sign', name: 'MySign', component: MySignView, meta: {
        auth: true, title: '我的签名'
    }
},{
    path: '/my/sign/add', name: 'AddMySign', component: AddSignView, meta: {
        auth: true, title: '添加我的签名'
    }
},{
    path: '/setting', name: 'Setting', component: SettingView, meta: {
        auth: true, title: '我的'
    }
},{
    path: '/setting/name', name: 'SettingName', component: NameUpdateView, meta: {
        auth: true, title: '更新名字'
    }
},{
    path: '/setting/pass', name: 'SettingPassword', component: PasswordSetting, meta: {
        auth: true, title: '更新密码'
    }
},{
    path: '/rank', name: 'Rank', component: RankView, meta: {
        auth: true, title: '巡检排名'
    }
},{
    path: '/check/calendar', name: 'CheckCalendar', component: CheckCalendarView, meta: {
        auth: true, title: '巡检记录'
    }
},{
    path: '/messages', name: 'MessageView', component: MessageView, meta: {
        auth: true, title: '消息'
    }
},{
    path: '/aboutus', name: 'AboutUs', component: AboutUsView, meta: {
        auth: true, title: '关于我们'
    }
},{
    path: '/test', name: 'Test', component: TestView, meta: {
        auth: true, title: 'Test'
    }
},{
    path: '/open/receive/:reportId', name: 'OpenReceive', component: ShopReportOpenReceiveView, meta: {
        auth: true, title: '签收检查'
    }
},{
    path: '/clock/history', name: 'ClockHistory', component: ClockHistoryView, meta: {
        auth: true, title: '打卡记录'
    }
},{
    path: '/shop/improve/:reportId', name: 'ShopImproveView', component: ShopImproveView, meta: {
        auth: true, title: '店铺整改'
    }
},{
    path: '/chat/list', name: 'ChatList', component: ChatListView, meta: {
        auth: true, title: '警情联动',keepAlive:true
    }
},{
    path: '/duty/check', name: 'DutyCheck', component: ReportToDutyCheckView, meta: {
        auth: true, title: '人员点验',keepAlive:true
    }
},{
    path: '/duty/check/history/:historyId', name: 'PeopleCheckViewDetail', component: PeopleCheckViewDetail, meta: {
        auth: true, title: '人员点验详情',keepAlive:true
    }
},{
    path: '/police/event/rank', name: 'PoliceHandleRankView', component: PoliceHandleRankView, meta: {
        auth: true, title: '量化榜单',keepAlive:true
    }
},]

const router = createRouter({
    history: createWebHashHistory(), routes
})
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
export default router
