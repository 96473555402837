<template>
  <nut-divider v-if="prevSender && prevSender.id != user.id" style="padding: 0;margin: 5px 0;color: rgba(211,211,211,0.4)"/>
  <nut-row class="miniAvatar bothCenter" v-if="!prevSender || prevSender.id != user.id">
    <nut-col :span="12" class="leftCenter">
      <img style="width: 20px;height:20px;border-radius: 5px;" :src="data.creatorAvtar"/>
      <span class="smallFont" style="color:#333333;">&nbsp;{{user.nickName}}</span>
    </nut-col>
    <nut-col :span="12" class="rightCenter">
      <nut-tag class="miniTag">{{ user.orgName }}</nut-tag>
    </nut-col>
  </nut-row>
  <nut-row>
    <nut-col :span="18" v-if="data.messageType === 'TEXT'" class="textMessage smallFont">
      {{ data.textContent }}
    </nut-col>
    <nut-col :span="18" v-if="data.messageType === 'IMAGE'" class="imageMessage">
      <photo-provider>
        <photo-consumer :src="data.imageUrl" style="text-align: left">
          <img :src="`${data.imageUrl}?x-oss-process=image/resize,p_10`"/>
        </photo-consumer>
      </photo-provider>
<!--      <img :src="data.imageUrl"/>-->
    </nut-col>
    <nut-col :span="6" class="rightCenter messageTime miniFont">
      {{ data.createdTime.substring(10) }}
    </nut-col>
  </nut-row>
</template>

<script>
export default {
  name: "PoliceEventItem",
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          creatorAvtar:'https://cdn.waityou.online/1c89cdb8-080d-d70b-7760-7bc9f5d47547.jpeg',
          nickName:'',
          orgName:'',
        }
      }
    },
    prevSender: {
      type: Object,
      default: () => {
        return {
          creatorAvtar:'https://cdn.waityou.online/1c89cdb8-080d-d70b-7760-7bc9f5d47547.jpeg',
          nickName:'',
          orgName:'',
        }
      }
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.textMessage {
  padding: 5px 0;
}

.imageMessage {
  img {
    width: 100%;
    padding: 5px 0;
    border-radius: 5px;
  }
}

.messageTime {
  color: rgba(128, 128, 128, 0.5);
}

.miniTag {
  background-color: #005FF5;
  color: white;
  line-height: $biggerFontSize;
  border-radius: 8px;
}

.miniAvatar {
  font-size: $baseFontSize;
  color: gray;
  padding: 10px 0;

  img {
    width: 25px;
    height: 25px;
    border-radius: 4px;
  }
}
</style>