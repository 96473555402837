<template>
  <div class="TextMessageContainer bothCenter">
    <span class="smallFont" style="color: #999999">{{ data.textContent }}</span>
  </div>
</template>

<script>
export default {
  name: "SystemNoticeMessage",
  props: {
    data: {
      type: Object,
      default: ()=>{
        return {

        }
      }
    },
    myId:{
      type:Number,
      default:0,
    }
  },
}
</script>

<style lang="scss" scoped>
.TextMessageContainer {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}
</style>