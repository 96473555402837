<template>
  <div :class="active?'EventItemContainer active':'EventItemContainer'">
<!--    <nut-badge dot color="red">-->
      <span class="eventName smallFont">{{ eName }}:{{ eIndex < 10 ? '0' : '' }}{{ eIndex }}</span>
<!--    </nut-badge>-->
  </div>
</template>

<script>
export default {
  name: "EventItem",
  props: {
    eIndex: {
      type: Number,
      default: 1
    },
    eName: {
      type: String,
      default: '警情'
    },
    active: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="scss" scoped>
.EventItemContainer {
  display: flex;
  padding: 4px 20px;
  background-color: white;
  margin-right: 10px;
  border-radius: 19px;
  height: 20px;
  font-size: $smallerFontSize;

  .eventName {
    display: inline-block;
  }
}

.active {
  border: 1px solid rgba(76, 133, 237, 1);
}
</style>