<template>
  <div class="RankViewContainer">
    <div class="bothCenter pd16">
      <div class="header bothCenter">
        <nut-col :span="8" class="colFlex bothCenter">
          <span class="number blue">{{ shopChecked }}</span>
          <span class="miniFont lightText">我的检查数</span>
        </nut-col>
        <nut-col :span="8" class="colFlex bothCenter">
          <span class="number">{{myRank}}</span>
          <span class="miniFont lightText">我的排名</span>
        </nut-col>
        <nut-col :span="8" class="colFlex bothCenter">
          <nut-button class="historyBtn baseFont" @click="$router.push('/check/calendar')">巡检历史</nut-button>
        </nut-col>
      </div>
    </div>
    <nut-row className="bothCenter pd16 miniFont" style="background-color: rgba(102,102,102,0.05);color: #666666">
      <nut-col :span="3" class="leftCenter">
        <span>排名</span>
      </nut-col>
      <nut-col :span="7" class="leftCenter">
        <span>姓名</span>
      </nut-col>
      <nut-col :span="7" class="bothCenter">
        <span>检查商户数</span>
      </nut-col>
      <nut-col :span="7" class="rightCenter">
        <span>组织</span>
      </nut-col>
    </nut-row>
    <template v-for="rnk in rank" :key="rnk.rankIndex">
      <nut-row className="bothCenter pd16 smallFont bg1" v-if="rnk.rankIndex == 1">
        <nut-col :span="3" class="leftCenter">
          <img src="https://cdn.waityou.online/e6cd4ecf-4049-4540-10c7-79b82dd9a592.png" class="rank"/>
        </nut-col>
        <nut-col :span="7" class="leftCenter">
          <img :src="rnk.userInfo.avatar" class="avatar"/>
          <span style="margin-left: 5px">{{ rnk.userInfo.nickName }}</span>
        </nut-col>
        <nut-col :span="7" class="bothCenter">
          <span>{{ rnk.shopChecked }}</span>
        </nut-col>
        <nut-col :span="7" class="rightCenter">
          <span>{{ rnk.userInfo.orgName }}</span>
        </nut-col>
      </nut-row>
      <nut-row className="bothCenter pd16 smallFont bg2" v-if="rnk.rankIndex == 2">
        <nut-col :span="3" class="leftCenter">
          <img src="https://cdn.waityou.online/e1429cd3-3ef2-3892-999f-5d3826ed91ab.png" class="rank"/>
        </nut-col>
        <nut-col :span="7" class="leftCenter">
          <img :src="rnk.userInfo.avatar" class="avatar"/>
          <span style="margin-left: 5px">{{ rnk.userInfo.nickName }}</span>
        </nut-col>
        <nut-col :span="7" class="bothCenter">
          <span>{{ rnk.shopChecked }}</span>
        </nut-col>
        <nut-col :span="7" class="rightCenter">
          <span>{{ rnk.userInfo.orgName }}</span>
        </nut-col>
      </nut-row>
      <nut-row className="bothCenter pd16 smallFont bg1" v-if="rnk.rankIndex == 3">
        <nut-col :span="3" class="leftCenter">
          <img src="https://cdn.waityou.online/a6e4fafb-5bf9-94c0-a441-b9219ea0f27e.png" class="rank"/>
        </nut-col>
        <nut-col :span="7" class="leftCenter">
          <img :src="rnk.userInfo.avatar" class="avatar"/>
          <span style="margin-left: 5px">{{ rnk.userInfo.nickName }}</span>
        </nut-col>
        <nut-col :span="7" class="bothCenter">
          <span>{{ rnk.shopChecked }}</span>
        </nut-col>
        <nut-col :span="7" class="rightCenter">
          <span>{{ rnk.userInfo.orgName }}</span>
        </nut-col>
      </nut-row>
      <nut-row className="bothCenter pd16 smallFont " v-if="rnk.rankIndex > 3">
        <nut-col :span="3" class="leftCenter">
          <span class="baseFont" style="color: #B2B2B2;padding-left: 5px">{{ rnk.rankIndex }}</span>
        </nut-col>
        <nut-col :span="7" class="leftCenter">
          <img :src="rnk.userInfo.avatar" class="avatar"/>
          <span style="margin-left: 5px">{{ rnk.userInfo.nickName }}</span>
        </nut-col>
        <nut-col :span="7" class="bothCenter">
          <span>{{ rnk.shopChecked }}</span>
        </nut-col>
        <nut-col :span="7" class="rightCenter">
          <span>{{ rnk.userInfo.orgName }}</span>
        </nut-col>
      </nut-row>
    </template>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "RankView",
  data() {
    return {
      shopChecked: 0,
      myRank: 0,
      rank: [],
    }
  },
  methods: {
    init() {
      api.getMyStatisticData()
          .then(ret => {
            this.shopChecked = ret.checked;
          })
      api.getStatisticRank()
          .then(rank => {
            this.rank = rank;
            for(let r of rank){
              if(r.me){
                this.myRank = r.rankIndex;
              }
            }
          })
    }
  },
  mounted() {
    this.init();
  }
}
</script>

<style lang="scss" scoped>
.RankViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;

  .bg1 {
    background-color: rgba(254, 175, 91, 0.1);
  }

  .bg2 {
    background-color: rgba(143, 200, 253, 0.1);
  }

  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 5px;
  }

  .rank {
    width: 20px;
    height: 28px;
  }

  .header {
    background-image: url("https://cdn.waityou.online/bdd2dda4-d20a-d2ac-6c6a-748e6b763749.png");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 90px;
    width: 100%;

    .historyBtn {
      height: 32px;
      border-radius: 22px;
      background-color: rgba(22, 119, 254, 1);
      color: rgba(255, 255, 255, 1);
      border: none;
    }

    .number {
      font-size: 24px;
    }

    .blue {
      color: #1677FE;
    }

    .lightText {
      color: #999999;
    }
  }
}
</style>