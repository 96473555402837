<template>
  <div class="TextPoliceEventItemContainer bothCenter colFlex">
    <span class="time">{{ data.createdTime }}</span>
    <div class="eventContainer">
<!--      <p>【{{data.communityName}}】{{data.content}}</p>-->
      <p class="opd6">{{data.content}}</p>
      <img v-if="data.previewImage" class="imageEvent"
           :src="`${data.previewImage}?x-oss-process=image/resize,p_10`"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextPoliceEventItem",
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          eventType: 0,
        };
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.TextPoliceEventItemContainer {
  padding: 10px;

  .time {
    color: #999999;
    font-size: $smallerFontSize;
    padding: 10px 0;
  }

  .imageEvent {
    width: 100%;
    height: 130px;
    object-fit: cover;
    border-radius: 5px;
  }

  .eventContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    border-radius: 5px;

    p {
      font-size: $smallerFontSize;
    }
  }
}
</style>