<template>
  <div class="eventContainer">
    <nut-steps direction="vertical" progress-dot current="4">
      <nut-step>
        <template v-slot:title>
          <div class="stepHeader">
            <nut-col :span="6">
              <span>图1</span>
            </nut-col>
            <nut-col :span="18"  class="rightCenter">
              <span>{{ convertTimestamp(event.firstImageTime/1000) }}</span>
            </nut-col>
          </div>
        </template>
        <template v-slot:content>
          <img class="stepImage" :src="event.firstImage"/>
        </template>
      </nut-step>
      <nut-step v-if="event.secondImage">
        <template v-slot:title>
          <div class="stepHeader">
            <nut-col :span="6">
              <span>图2</span>
            </nut-col>
            <nut-col :span="18" class="rightCenter">
              <span>{{ convertTimestamp(event.secondImageTime/1000) }}</span>
            </nut-col>
          </div>
        </template>
        <template v-slot:content>
          <div class="wd100">
            <img class="stepImage" :src="event.secondImage"/>
          </div>
        </template>
      </nut-step>
      <nut-step v-if="event.thirdImage">
        <template v-slot:title>
          <div class="stepHeader">
            <nut-col :span="6">
              <span>图3</span>
            </nut-col>
            <nut-col :span="18" class="rightCenter">
              <span>{{ convertTimestamp(event.thirdImageTime/1000) }}</span>
            </nut-col>
          </div>
        </template>
        <template v-slot:content>
          <div class="wd100">
            <img class="stepImage" :src="event.thirdImage"/>
          </div>
        </template>
      </nut-step>
      <nut-step>
        <template v-slot:title>
          <div class="stepHeader">
            <span>事件描述</span>
          </div>
        </template>
        <template v-slot:content>
          <div class="stepDescription">
            <span>{{ event.content }}</span>
          </div>
        </template>
      </nut-step>
      <nut-step>
        <template v-slot:title>
          <div class="stepHeader">
            <span>记录人/记录时间</span>
          </div>
        </template>
        <template v-slot:content>
          <nut-row style="display: flex;flex-direction: row">
            <nut-col :span="6" class="eventCreatorAvatar">
              <img style="width: 100%" src="https://cdn.waityou.online/1c89cdb8-080d-d70b-7760-7bc9f5d47547.jpeg"/>
            </nut-col>
            <nut-col :span="18" class="eventCreator">
              <span>{{ event.reporterUserName }}&nbsp;&nbsp;{{ event.reporterOrgName }}</span>
              <span>{{ event.createdTime }}</span>
            </nut-col>
          </nut-row>
        </template>
      </nut-step>
    </nut-steps>
  </div>
</template>

<script>

export default {
  name: "OtherEventReportDetailView",
  components: {},
  props: {
    event: {
      type: Object,
      default: ()=>{
        return {

        }
      }
    },
  },
  data() {
    return {

    }
  },
  methods: {
    init() {
      document.title = this.event.subEventTypeName+'事件详情';
    },
    convertTimestamp(timestamp) {
      if(!timestamp){
        return '-'
      }
      let date = new Date(timestamp*1000); // 将时间戳转换为 JavaScript Date 对象
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, '0');
      let day = String(date.getDate()).padStart(2, '0');
      let hours = String(date.getHours()).padStart(2, '0');
      let minutes = String(date.getMinutes()).padStart(2, '0');
      let seconds = String(date.getSeconds()).padStart(2, '0');
      let dayOfWeek = ['日', '一', '二', '三', '四', '五', '六'][date.getDay()];

      return `${year}年${month}月${day}日 ${hours}:${minutes}:${seconds} 周${dayOfWeek}`;
    }

  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>

.eventContainer {
  position: relative;
  width: 96%;
  height: calc(100vh - 4%);
  padding: 2%;
  font-size: $baseFontSize;
  background-color: white;

  .eventCreatorAvatar {
    width: 50px;
    height: 50px;
  }

  .eventCreator {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    border-radius: 5px;
    color: rgba(16, 16, 16, 1);
    font-size: $smallerFontSize;
    line-height: 20px;
  }

  .stepDescription {
    width: 240px;
    height: 100px;
    line-height: 20px;
    padding: 10px;
    border-radius: 8px;
    background-color: rgba(246, 246, 250, 1);
    color: rgba(136, 136, 136, 1);
  }

  .stepImage {
    width: 260px;
    height: 160px;
    object-fit: cover;
    border-radius: 8px;
    margin-top: 10px;
  }

  .stepHeader {
    width: 85vw;
    color: #333333;
  }


  .tags {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 2px;
    padding: 5px 0;

    .danger {
      background-color: rgba(245, 63, 63, 0.1);
      color: rgba(245, 63, 63, 1);
      margin-right: 10px;
    }

    .warning {
      background-color: rgba(255, 125, 1, 0.1);
      color: rgba(255, 125, 1, 1);
      margin-right: 10px;
    }

    .success {
      background-color: rgba(0, 180, 43, 0.1);
      color: rgba(0, 180, 43, 1);
      margin-right: 10px;
    }

    .info {
      background-color: rgba(22, 119, 254, 0.1);
      color: rgba(22, 119, 254, 1);
      margin-right: 10px;
    }
  }

  .detailContainer {
    position: absolute;
    left: 6%;
    top: 68px;
    width: 88%;
    min-height: 60vh;
    max-height: 85vh;
    overflow-y: scroll;
    line-height: 20px;
    background-color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .headRect2 {
    position: absolute;
    left: 6%;
    top: 56px;
    width: 88%;
    height: 12px;
    line-height: 20px;
    background: linear-gradient(180deg, rgba(22, 119, 254, 0.5) 1%, rgba(255, 255, 255, 0.5) 100%);
    text-align: center;
  }

  .headRect1 {
    position: absolute;
    left: 5%;
    top: 50px;
    width: 90%;
    height: 12px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(7, 98, 225, 1);
    text-align: center;
  }

  .headBg {
    position: absolute;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 135px;
  }

  .miniLogo {
    font-size: $smallerFontSize;
    color: gray;
    padding: 5px 0;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .dot {
    background-color: #1388ff;
    color: #1388ff;
    font-size: $smallerFontSize;
  }

  .eventTitle {
    font-weight: normal;
    font-size: $baseFontSize;
    padding: 5px 0;
  }

  .darkRed {
    color: darkred;
  }

  .eventImage {
    display: flex;
    padding: 10px 0;

    img {
      width: 100%;
      border-radius: 5px;
    }
  }
}
</style>