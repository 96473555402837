<template>
  <div class="ReportFormViewContainer">
    <div class="publishForm  colFlex">
      <div class="title leftCenter">
        <span class="red">*</span>
        <span>照片1</span>
      </div>
      <div class="divider"></div>
      <div class="uploadForm bothCenter colFlex">
        <CustomOssUploader :limit="1" @onUploadedFiles="onFirstImageShot">
          <div class="colFlex bothCenter" v-if="firstImage">
            <img :src="firstImage" style="height: 128px"/>
          </div>
          <div class="colFlex bothCenter"  v-else>
            <img :src="defaults.uploadIcon[reportType]" style="height: 100px"/>
            <span>上传照片</span>
          </div>
        </CustomOssUploader>
      </div>
    </div>
    <div class="publishForm  colFlex">
      <div class="title leftCenter">
        <span class="red">*</span>
        <span>照片2</span>
      </div>
      <div class="divider"></div>
      <div class="uploadForm bothCenter colFlex">
        <CustomOssUploader :limit="1" @onUploadedFiles="onSecondImageShot">
          <div class="colFlex bothCenter" v-if="secondImage">
            <img :src="secondImage" style="height: 128px"/>
          </div>
          <div class="colFlex bothCenter"  v-else>
            <img :src="defaults.uploadIcon[reportType]" style="height: 100px"/>
            <span>上传照片</span>
          </div>
        </CustomOssUploader>
      </div>
    </div>
    <div class="publishForm  colFlex">
      <div class="title leftCenter">
        <span class="red">*</span>
        <span>巡查描述</span>
      </div>
      <div class="divider"></div>
      <nut-textarea placeholder="请输入巡查描述"
                    style="padding: 10px"
                    v-model="content"
                    rows="5"></nut-textarea>
    </div>
  </div>

  <div class="bottomBtn bothCenter">
    <nut-button class="submitBtn" @click="report">归档</nut-button>
  </div>
</template>

<script>
import api from "@/api";
import {Toast} from "@nutui/nutui";
import CustomOssUploader from "@/components/CustomOssUploader"

export default {
  name: "RoadSurfaceInspectionView",
  components: {CustomOssUploader},

  data() {
    return {
      reportType: 6,
      defaults: {
        uploadIcon: {
          1: 'https://cdn.waityou.online/abca14ba-d480-5f38-92a1-5a3c191ca3fa.png',
          3: 'https://cdn.waityou.online/dca42719-8ff3-4bd8-0866-38dbbfd413e8.png',
          4: 'https://cdn.waityou.online/dca42719-8ff3-4bd8-0866-38dbbfd413e8.png',
          5: 'https://cdn.waityou.online/dca42719-8ff3-4bd8-0866-38dbbfd413e8.png',
          6: 'https://cdn.waityou.online/dca42719-8ff3-4bd8-0866-38dbbfd413e8.png',
        }
      },
      content: null,
      firstImage: null,
      firstImageTime: null,
      secondImage: null,
      secondImageTime: null,
    }
  },
  methods: {
    onFirstImageShot(files){
      this.firstImage = files[0]
      this.firstImageTime = new Date().getTime();
    },
    onSecondImageShot(files){
      this.secondImage = files[0]
      this.secondImageTime = new Date().getTime();
    },
    report() {
      if (!this.firstImage) {
        Toast.warn("请上传图片")
        return
      }
      if (!this.content || this.content.length < 1) {
        Toast.warn("请输入事件描述")
        return
      }
      api.report({
        eventTypeId: this.reportType,
        content: this.content,
        firstImage: this.firstImage,
        firstImageTime: this.firstImageTime,
        secondImageTime: this.secondImageTime,
        secondImage: this.secondImage,
      }).then(() => {
        Toast.success('上报成功');
        this.$router.back()
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.bottomBtn {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px 0;
  background-color: white;

  .submitBtn {
    border: none;
    border-radius: 18px;
    background-color: #1677FE;
    color: white;
    width: 90%;
    font-size: $baseFontSize;
  }
}

.ReportFormViewContainer {
  background-color: #F0F1F4;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;

  .publishForm {
    width: 100%;
    display: flex;
    background-color: white;
    border: 1px dashed rgba(187, 187, 187, 1);
    border-radius: 4px;
    margin: 5px 0;

    .divider {
      width: 100%;
      height: 1px;
      background-color: #E8E8E8;
    }

    .title {
      display: flex;
      padding: 10px;

      .red {
        color: red;
      }

      span {
        font-size: $baseFontSize;
      }
    }

    .uploadForm {
      display: flex;
      width: 100%;
      height: 128px;

      img {
        height: 128px;
      }

      span {
        padding: 10px 0;
        font-size: $miniFontSize;
        color: #999999;
      }
    }
  }

}
</style>