<template>
  <div class="ShopTypeTagContainer">
    <span class="minFont">{{ name }}</span>
  </div>
</template>

<script>
export default {
  name: "ShopTypeTag",
  props: {
    name: {
      type: String,
      default: '-'
    },
  },
  methods: {
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.ShopTypeTagContainer {
  display: flex;
  flex-direction: column;
  color: white;
  padding: 3px 6px;
  border-radius: 4px;
  background: linear-gradient(90deg, #628DFC, #36C6FF);
}
</style>