<template>
  <div class="PeopleCheckHistoryItemContainer pd12"
       @click="$router.push('/duty/check/history/'+data.id)">
    <nut-row class="bothCenter">
      <nut-col :span="20" class="colFlex">
        <span class="baseFont" style="color:#0366F1;">正常{{
            data.normal
          }}人 请假{{ data.total - data.normal }}人</span>
        <span class="smallFont mt8" style="color:#999999;">点验时间：{{ data.createdTime }}</span>
      </nut-col>
      <nut-col :span="4" class="rightCenter">
        <nut-icon name="rect-right" size="10" color="#606266"></nut-icon>
      </nut-col>
    </nut-row>
  </div>
</template>

<script>
export default {
  name: "PeopleCheckHistoryItem",
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  methods: {
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.PeopleCheckHistoryItemContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
}
</style>